import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import StarRatings from 'react-star-ratings'

export default class Info extends Component {
    constructor(props) {
        super(props)
    }

    async componentDidMount() {
        const { seller: { uuid }, getOwnerStats } = this.props

        await getOwnerStats(uuid)
    }

    _renderFeedbacks(feedback, index) {
        const { from, message, score, created_at } = feedback

        moment.locale()

        return <div key={index} className="mt-2">
            <div className="row">
                <div className="col-12 text-center">
                    <StarRatings
                        rating={score}
                        numberOfStars={5}
                        name="score"
                        starRatedColor="#e29000"
                        starHoverColor="#ffc766"
                        starDimension="15px"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <i className="legend">{message}</i> - <span>{from.full_name}</span>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <p className="text-muted">{ moment(created_at).format('LLL') }</p>
                </div>
            </div>
        </div>
    }

    render() {
        const { seller, type, feedbacks, stats } = this.props

        return (
            <div className="seller-info text-center col-lg-5">
                <div className="session-block">
                    <h3 className="text-center">
                        {type === 'sell' ? 'Seller' : 'Buyer'} Info
                    </h3>

                    <div className="profile-picture m-auto">
                        <img
                            src={seller.profile_picture || '/no-user-image.jpg'}
                            alt={`${seller.full_name}`}
                            title={`${seller.full_name}`}
                            className="w-100"
                        />
                    </div>

                    <h3 className="user-name">{`${seller.full_name}`}</h3>
                    <p className="text-muted">
                        Member since{" "}
                        {moment(seller.created_at).format('MMMM D, YYYY')}
                    </p>

                    <div className="social col-lg-12 p-0">
                        <div className="row">
                            {seller.facebook_profile && (
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <a
                                        href={seller.facebook_profile}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src="/facebook.svg"
                                            alt="facebook"
                                        />
                                    </a>
                                </div>
                            )}
                            {seller.telegram_username && (
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <a
                                        href={seller.telegram_username}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            src="/telegram.svg"
                                            alt="telegram"
                                        />
                                    </a>
                                </div>
                            )}
                            {seller.twitter_profile && (
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <a
                                        href={seller.twitter_profile}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img src="/twitter.svg" alt="twitter" />
                                    </a>
                                </div>
                            )}
                            {seller.email && (
                                <div className="col-lg-12 col-md-6 col-sm-12 flex flex-col">
                                    <a href={`mailto:${seller.email}`} className="mr-4">
                                        <img src="/email.svg" alt="email" />
                                    </a>
                                    <span>{seller.email}</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="statistics col-lg-12">
                        <div className="row">
                            <div className="col-lg-6 col-sm-5 col-4 text-center">
                                <p className="number">{stats && stats.trust}</p>
                            </div>
                            <div className="col-lg-6 col-sm-7 col-8 text-left">
                                <p className="legend">Trust Level</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-5 col-4 text-center">
                                <p className="number">{stats && stats.completed}</p>
                            </div>
                            <div className="col-lg-6 col-sm-7 col-8 text-left">
                                <p className="legend">Deals Completed</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-5 col-4 text-center">
                                <p className="number">{stats && stats.buy}</p>
                            </div>
                            <div className="col-lg-6 col-sm-7 col-8 text-left">
                                <p className="legend">Buy Orders</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-5 col-4 text-center">
                                <p className="number">{stats && stats.sell}</p>
                            </div>
                            <div className="col-lg-6 col-sm-7 col-8 text-left">
                                <p className="legend">Sell Orders</p>
                            </div>
                        </div>
                    </div>

                    <div className="feedback col-lg-12">
                        {feedbacks && feedbacks.length > 0 ? (
                            feedbacks.map(this._renderFeedbacks)
                        ) : (
                            <div className="row">
                                <div className="col-12 text-center">
                                    <p className="legend">No Feedbacks yet....</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

Info.propTypes = {
    seller: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
}

Info.defaultProps = {}
