import React, { Component } from 'react'
import cx from 'classnames'

export default class Currency extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { currency, clickHandler } = this.props

        return (
            <div className="inner-block">
                <label>Currency accepted</label>
                <div className="col-12 radio-group">
                    <div className="radio w-25 float-left">
                        <input type="radio" name="currency" value="ETH"/> <span className={cx('radio-label', currency === 'ETH' && 'checked')} onClick={() => clickHandler('ETH')}>ETH</span>
                    </div>
                    <div className="radio w-75 float-left">
                        <input type="radio" name="currency" value="USDT"/> <span className={cx('radio-label', currency === 'USDT' && 'checked')} onClick={() => clickHandler('USDT')}>USDT</span>
                    </div>
                    <div className="radio w-25 float-left">
                        <input type="radio" name="currency" value="USDC"/> <span className={cx('radio-label', currency === 'USDC' && 'checked')} onClick={() => clickHandler('USDC')}>USDC</span>
                    </div>
                    <div className="radio w-75 float-left">
                        {/* <input type="radio" name="currency" value="USD"/> <span className={cx('radio-label', currency === 'USDT' && 'checked')} onClick={() => clickHandler('USDT')}>USDT</span> */}
                    </div>
                </div>
            </div>
        )
    }
}