import Home from '../../components/Home'
import { connect } from 'react-redux'
import { 
    login,
} from '../../actions/auth'
import { fetchBuyAds, fetchSellAds } from '../../actions/ads'
import { showFlashMessage } from '../../actions/app'
import { getTransactionsCount } from '../../actions/transactions'

const mapActionCreators = {
    fetchBuyAds,
    fetchSellAds,
    getTransactionsCount,
    login,
    showFlashMessage,
}

const mapStateToProps = state => ({
    buyAds: state.ads.buyAds,
    sellAds: state.ads.sellAds,
    auth: state.auth.myself,
    authBase: state.auth,
    totalOngoing: state.transactions.totalOngoing,
    totalCompleted: state.transactions.totalCompleted,
})

export default connect(mapStateToProps, mapActionCreators)(Home)