import { connect } from 'react-redux'
import MyAccount from '../../components/MyAccount'
import { getUser, getMyStats, getOwnerStats } from '../../actions/users'

const mapActionCreators = { getUser, getMyStats, getOwnerStats }

const mapStateToProps = (state) => ({
    myself: state.auth.myself,
    user: state.users.user,
    accountAssets: state.auth.accountAssets,
    stats: state.users.stats
})

export default connect(mapStateToProps, mapActionCreators)(MyAccount)
