import React, { Component } from 'react'

export default class Category extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { changeHandler, category } = this.props
        return (
            <div className="input-block">
                <div className="form-group">
                    <label>Category</label>
                    <select className="form-control" name="category" value={category} onChange={changeHandler}>
                        <option value="gaming">Gaming & Entertainment</option>
                        <option value="humor">Humor & Memes</option>
                        <option value="models">Models & Celebrities</option>
                        <option value="educational">Educational & QA</option>
                        <option value="movies">Movies, TV & Fanpages</option>
                        <option value="fitness">Fitness & Sports</option>
                        <option value="fashion">Fashion & Style</option>
                        <option value="thriller">Thriller & Horror</option>
                        <option value="arts">Arts & Music</option>
                    </select>
                </div>
            </div>
        )
    }
}