import React, { Component } from 'react'
import { isNil } from 'lodash'
import Web3 from 'web3'
import Loading from '../common/Loading'

export default class MyAccount extends Component {
    constructor(props) {
        super(props)

        this.mounted = false;
        this.state = {
            userId: null,
            isLoading: true,
            balance: '0'
        }
    }

    async componentDidMount() {
        this.mounted = true;
        const {
            myself,
            getMyStats,
            getOwnerStats,
            getUser,
            match: { params }
        } = this.props
        console.log(params.id)
        if (!isNil(params.id)) {
            try {
                const { payload: { user } } = await getUser(params.id)
                await getOwnerStats(user.id)

                const web3 = new Web3(window.ethereum)
                const balance = await web3.eth.getBalance(user.public_address)
                
                if (this.mounted === true) {
                    this.setState({ isLoading: false, balance })
                }
            } catch (error) {
                console.error(error)
                this.setState({ isLoading: false });
            }
        } else {
            if (myself.uuid && myself.public_address) {
                const web3 = new Web3(window.ethereum)
                const balance = await web3.eth.getBalance(myself.public_address)

                await getMyStats()
                if (this.mounted === true) {
                    this.setState({ isLoading: false, balance })
                }
            } else {
                this.setState({ isLoading: false });
            }
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    _renderSocialMedia = (link, linkedin = false) => {
        let username = link.substring(link.indexOf("com/") + 4)

        if (linkedin) {
            username = link.substring(link.indexOf("in/") + 3)
        }

        return (
            <a href={link} target="_blank" rel="noopener noreferrer">
                {username}
            </a>
        )
    }

    _renderWebsite = (link) => {
        let initial = ""
        let ending = ""

        if (link.length > 25) {
            initial = link.substring(0, 15)
            ending = link.substring(link.length, link.length - 10)

            return (
                <a href={link} target="_blank" title={link} rel="noopener noreferrer">
                    {initial} ... {ending}
                </a>
            )
        }

        return link
    }

    render() {
        const { isLoading, balance } = this.state
        const { match: { params }, myself, user, stats } = this.props

        const ethBalance = Web3.utils.fromWei(balance, 'ether')

        const currentUser = !params.id ? myself : user

        if (isLoading) {
            return <Loading />
        }

        return (
            <div id="my-account">
                {!params.id && (
                    <h3 className="title">My Account</h3>
                )}

                <div className="col-12 session-block about">
                    <div className="row p-0 d-flex align-items-center">
                        <div className="p-0 col-lg-8 col-md-6 col-sm-12 h-auto about-bio d-flex align-items-center justify-content-between flex-wrap">
                            <div className="col-12 col-lg-3 p-0">
                                <div className="about-image">
                                    {currentUser.profile_picture ? (
                                        <img
                                            src={currentUser.profile_picture}
                                            className="w-100"
                                            alt={currentUser.full_name}
                                        />
                                    ) : (
                                        <img src="/no-user-image.jpg" alt="user" className="w-100" />
                                    )}
                                </div>
                            </div>
                            <div className="col-12 col-lg-8 p-0 text-center text-lg-left">
                                <h3>
                                    {currentUser.first_name} {currentUser.last_name}
                                </h3>
                                <p>{currentUser.bio}</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 about-contact text-center text-lg-left">
                            <div>
                                <img src="/email.svg" alt="email" />{" "}
                                <span>E-mail Address</span>
                                <p>{currentUser.email}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="row p-0 mb-5">
                        <div className="col-lg-4 col-md-12 col-sm-12 session-block external-links">
                            <div className="row p-0 d-flex align-items-center">
                                <h3 className="title w-100">External Links</h3>
                                <div className="link w-100">
                                    <div className="image text-center float-left">
                                        <img
                                            src="/computer.svg"
                                            alt="computer"
                                        />
                                    </div>
                                    {(currentUser.website &&
                                        this._renderWebsite(
                                            currentUser.website
                                        )) || (
                                        <span className="small text-muted">
                                            No website
                                        </span>
                                    )}
                                </div>

                                <div className="link w-100">
                                    <div className="image text-center float-left">
                                        <img
                                            src="/facebook.svg"
                                            alt="facebook"
                                        />
                                    </div>
                                    {(currentUser.facebook_profile &&
                                        this._renderSocialMedia(
                                            currentUser.facebook_profile
                                        )) || (
                                        <span className="small text-muted">
                                            No Facebook
                                        </span>
                                    )}
                                </div>

                                <div className="link w-100">
                                    <div className="image text-center float-left">
                                        <img
                                            src="/twitter.svg"
                                            alt="twitter"
                                        />
                                    </div>
                                    {(currentUser.twitter_profile &&
                                        this._renderSocialMedia(
                                            currentUser.twitter_profile
                                        )) || (
                                        <span className="small text-muted">
                                            No Twitter
                                        </span>
                                    )}
                                </div>

                                <div className="link w-100">
                                    <div className="image text-center float-left">
                                        <img
                                            src="/linkedin.svg"
                                            alt="linkedin"
                                        />
                                    </div>
                                    {(currentUser.linkedin_profile &&
                                        this._renderSocialMedia(
                                            currentUser.linkedin_profile,
                                            true
                                        )) || (
                                        <span className="small text-muted">
                                            No LinkedIn
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-12 col-sm-12 wallet-block pr-0">
                            {(!params.id && currentUser.wallet === 'metamask' &&
                                <div className="session-block wallet pb-3">
                                    <h3 className="title w-100">Linked Wallet</h3>
                                    <img src="/metamask.svg" alt="metamask icon" height="300" width="300" />
                                    <p className="text-muted">
                                        <strong>Wallet: </strong> MetaMask <br />
                                        <strong>Public Address:</strong> {currentUser.public_address} <br/>
                                        <strong>Balance:</strong> {ethBalance} ETH
                                    </p>
                                </div>
                            )}
                            {(!params.id && currentUser.wallet === 'walletconnect' &&
                                <div className="session-block wallet pb-3">
                                    <h3 className="title w-100">Linked Wallet</h3>
                                    <img src="/walletconnect.svg" alt="walletconnect icon" height="300" width="300" />
                                    <p className="text-muted">
                                        <strong>Wallet: </strong> WalletConnect <br />
                                        <strong>Public Address:</strong> {currentUser.public_address} <br/>
                                        <strong>Balance:</strong> {ethBalance} ETH
                                    </p>
                                </div>
                            )}
                            {(!params.id && currentUser.wallet === '' &&
                                <div className="session-block wallet pb-3 h-100">
                                    <h3 className="title w-100">Please connect a wallet</h3>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div>
                    <h3 className="title">Account Status</h3>
                    <div className="row p-0 account-status">
                        <div className="col-lg-4 col-sm-12">
                            <div className="session-block text-center">
                                <div className="image grad-orange">
                                    <img
                                        src="/trust-level.svg "
                                        alt="trust level"
                                    />
                                </div>
                                <h4>Trust Level</h4>
                                <span className="number">{ !isNil(stats) ? stats.trust : 0 }</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="session-block text-center">
                                <div className="image grad-purple">
                                    <img
                                        src="/deals-completed.svg "
                                        alt="deals-completed"
                                    />
                                </div>
                                <h4>Deals Completed</h4>
                                <span className="number">{!isNil(stats) ? stats.completed : 0}</span>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-12">
                            <div className="session-block text-center">
                                <div className="image grad-yellow">
                                    <img
                                        src="/on-going-deals.svg "
                                        alt="on-going"
                                    />
                                </div>
                                <h4>On Going Deals</h4>
                                <span className="number">{ !isNil(stats) ? stats.ongoing : 0 }</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
