import React, { Component } from 'react'
import ReactTable from 'react-table'
import ReactTooltip from 'react-tooltip'
import ReactPaginate from 'react-paginate'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Leaderboard extends Component {
    constructor(props) {
        super(props)
    }

    async componentDidMount() {
        const { getTrustScores } = this.props

        await getTrustScores()
    }

    _displayMedal = (rank) => {
        switch(rank) {
            case 1:
                return <img src="/leaderboard1st.png" height="40" width="40" />
            case 2:
                return <img src="/leaderboard2nd.png" height="40" width="30" />
            case 3:
                return <img src="/leaderboard3rd.png" height="40" width="30" />
            case 4:
                return <img src="/leaderboard4th.png" height="40" width="30" />
            case 5:
                return <img src="/leaderboard5th.png" height="40" width="30" />
            default:
                return <strong>{rank}</strong>
        }
    }

    _handlePagination = async(data) => {
        const { getTrustScores } = this.props

        const selected = data.selected + 1
        await getTrustScores(selected)
    }

    render() {
        const { rankings } = this.props

        const columns = [{
            id: 'rank',
            Header: 'Rank',
            accessor: a => <span className="table-row table-normal">{this._displayMedal(a.user_rank)}</span>,
            minWidth: 300
        }, {
            id: 'user',
            Header: 'User',
            accessor: a => <span className="table-row table-normal">
                <img
                    className="mr-3"
                    height="42"
                    width="42"
                    style={{ borderRadius: '50%' }}
                    src={a.profile_picture || '/no-user-image.jpg'}
                />
                {`${a.first_name ? a.first_name : 'User'} ${a.last_name ? a.last_name : a.id}`}
            </span>,
            minWidth: 300
        }, {
            id: 'trust',
            Header: 'Trust Score',
            accessor: a => <span className="table-row table-normal" data-tip="Trust means you got the most number of at least 3 star and above ratings.">
                <strong className="text-primary">{a.trust} PTS</strong>
                <ReactTooltip />
            </span>,
            minWidth: 300
        }]

        return rankings && (
            <div id="leaderboard" className="session-block">
                <h1 className="mb-3 text-center text-sm-left">User Trust Leaderboard</h1>
                <ReactTable
                    className="text-center mb-3 -highlight"
                    columns={columns}
                    data={rankings.data}
                    pageSize={10}
                    minRows={3}
                    manual
                    showPageJump={false}
                    showPageSizeOptions={false}
                    showPagination={false}
                />
                <ReactPaginate
                    previousLabel={<FontAwesomeIcon icon="chevron-left" color="#171C34" />}
                    nextLabel={<FontAwesomeIcon icon="chevron-right" color="#171C34" />}
                    pageCount={rankings.last_page}
                    onPageChange={this._handlePagination}
                    containerClassName="pagination justify-content-center"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="pr-2"
                    nextClassName="pl-2"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                />
            </div>
        )
    }
}

export default Leaderboard