import { RSAA } from 'redux-api-middleware'

export const LOGIN = 'auth:login'
export const LOGIN_SUCCESS = 'auth:login_success'
export const LOGIN_FAILURE = 'auth:login_failure'

export const REGISTER = 'auth:register'
export const REGISTER_SUCCESS = 'auth:register_success'
export const REGISTER_FAILURE = 'auth:register_failure'

export const ME = 'auth:me'
export const ME_SUCCESS = 'auth:me_success'
export const ME_FAILURE = 'auth:me_failure'

export const UPDATE_PROFILE = 'auth:update_profile'
export const UPDATE_PROFILE_SUCCESS = 'auth:update_profile_success'
export const UPDATE_PROFILE_FAILURE = 'auth:update_profile_failure'

export const ALL_USERS = 'auth:all_users'
export const ALL_USERS_SUCCESS = 'auth:all_users_success'
export const ALL_USERS_FAILURE = 'auth:all_users_failure'

export const LOGOUT = 'auth:logout'
export const LOGOUT_SUCCESS = 'auth:logout_failure'

// MetaMask and Wallet Connect
export const SIGNUP = 'auth:signup'
export const SIGNUP_SUCCESS = 'auth:signup_success'
export const SIGNUP_FAILURE = 'auth:signup_failure'

export const GET_USER_BY_ADDRESS = 'auth:get_user_by_address'
export const GET_USER_BY_ADDRESS_SUCCESS = 'auth:get_user_by_address_success'
export const GET_USER_BY_ADDRESS_FAILURE = 'auth:get_user_by_address_failure'

export const GET_ACCOUNT_ASSETS = 'auth:get_account_assets'
export const GET_ACCOUNT_ASSETS_SUCCESS = 'auth:get_account_assets_success'
export const GET_ACCOUNT_ASSETS_FAILURE = 'auth:get_account_assets_failure'

export const UPDATE_NONCE = 'auth:update_nonce'
export const UPDATE_NONCE_SUCCESS = 'auth:update_nonce_success'
export const UPDATE_NONCE_FAILURE = 'auth:update_nonce_failure'

export function login(data) {
    return {
        [RSAA]: {
            endpoint: `${process.env.REACT_APP_API_HOST}/login`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
            types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE]
        }
    }
}

export function register(data) {
    return {
        [RSAA]: {
            endpoint: `${process.env.REACT_APP_API_HOST}/register`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
            types: [REGISTER, REGISTER_SUCCESS, REGISTER_FAILURE]
        }
    }
}

export function me() {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        window.http.setToken(token);

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/me`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [ME, ME_SUCCESS, ME_FAILURE]
            }
        })
    }
}

export function update(data) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/me`,
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json; charset=UTF-8",
                },
                body: JSON.stringify(data),
                types: [UPDATE_PROFILE, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE]
            }
        })
    }
}

export function logout(miniApp = false) {
    return async (dispatch) => {
        await dispatch({
            type: LOGOUT,
        });
        await dispatch({
            type: LOGOUT_SUCCESS,
        });
        window.localStorage.removeItem('persist:root');
        window.localStorage.removeItem('walletConnect');

        if (miniApp) {
            window.location = '/mini-app'
            return;
        }
        
        window.location = '/';
    };
}

/**
 * Below are MetaMask new actions for authorization, the actions above might be obsolete in the near future
 */
export function handleSignup(data) {
    return {
        [RSAA]: {
            endpoint: `${process.env.REACT_APP_API_HOST}/user`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json; charset=UTF-8',
            },
            body: JSON.stringify(data),
            types: [SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE]
        }
    }
}

export function authorize(address) {
    return {
        [RSAA]: {
            endpoint: `${process.env.REACT_APP_API_HOST}/user/${address}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json; charset=UTF-8',
            },
            types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE] // same as login since the functionality is basically the same, did not remove existing code above just in case
        }
    }
}

export function getUserByPublicAddress(address) {
    return {
        [RSAA]: {
            endpoint: `${process.env.REACT_APP_API_HOST}/user?address=${address}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json; charset=UTF-8',
            },
            types: [GET_USER_BY_ADDRESS, GET_USER_BY_ADDRESS_SUCCESS, GET_USER_BY_ADDRESS_FAILURE]
        }
    }
}

export function getAccountAssets(address, chainId) {
    return {
        [RSAA]: {
            endpoint: `${process.env.REACT_APP_ETHEREUM_API_HOST}/account-assets?address=${address}&chainId=${chainId}`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json; charset=UTF-8',
            },
            types: [GET_ACCOUNT_ASSETS, GET_ACCOUNT_ASSETS_SUCCESS, GET_ACCOUNT_ASSETS_FAILURE]
        }
    }
}

export function updateNonce(address) {
    return {
        [RSAA]: {
            endpoint: `${process.env.REACT_APP_API_HOST}/user/${address}`,
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept': 'application/json; charset=UTF-8',
            },
            types: [UPDATE_NONCE, UPDATE_NONCE_SUCCESS, UPDATE_NONCE_FAILURE]
        }
    }
}

const ACTION_HANDLERS = {
    [LOGIN]: state => ({
        ...state,
        loggingIn: true,
        loginSuccess: false,
        loginError: false
    }),
    [LOGIN_SUCCESS]: (state, action) => ({
        ...state,
        isGuest: false,
        loggingIn: false,
        loginSuccess: true,
        loginError: false,
        token: action.payload.token,
        user: action.payload.user,
    }),
    [LOGIN_FAILURE]: state => ({
        ...state,
        loggingIn: false,
        loginSuccess: false,
        loginError: true,
    }),
    [REGISTER]: state => ({
        ...state,
        registering: true,
        registerSuccess: false,
        registerError: false
    }),
    [REGISTER_SUCCESS]: (state, action) => ({
        ...state,
        registering: false,
        registerSuccess: true,
        registerError: false,
        token: action.payload.token
    }),
    [REGISTER_FAILURE]: state => ({
        ...state,
        registering: false,
        registerSuccess: false,
        registerError: true
    }),
    [ME]: state => ({
        ...state,
        fetchingMe: true
    }),
    [ME_SUCCESS]: (state, action) => ({
        ...state,
        fetchingMe: false,
        myself: action.payload.user,
        isGuest: false,
    }),
    [ME_FAILURE]: state => ({
        ...state,
        fetchingMe: false
    }),
    [UPDATE_PROFILE]: state => ({ 
        ...state,
        updatingProfile: true,
        updatingProfileSuccess: false 
    }),
    [UPDATE_PROFILE_SUCCESS]: (state) => ({ 
        ...state, 
        updatingProfile: false, 
        updatingProfileSuccess: true, 
        updatingProfileError: false 
    }),
    [UPDATE_PROFILE_FAILURE]: state => ({ 
        ...state, 
        updatingProfile: false, 
        updatingProfileSuccess: false, 
        updatingProfileError: true 
    }),
    /**
     * MetaMask new login actions
     * WalletConnect new login actions
     */
    [SIGNUP]: state => ({
        ...state,
        signup: true,
        signupSuccess: false,
        signupFailure: false
    }),
    [SIGNUP_SUCCESS]: (state, action) => ({
        ...state,
        signup: false,
        signupSuccess: true,
        signupFailure: false,
        user: action.payload.user
    }),
    [SIGNUP_FAILURE]: state => ({
        ...state,
        signup: false,
        signupSuccess: false,
        signupFailure: true
    }),
    [GET_USER_BY_ADDRESS]: state => ({
        ...state,
        getUserByAddress: true,
        getUserByAddressSuccess: false,
        getUserByAddressFailure: false
    }),
    [GET_USER_BY_ADDRESS_SUCCESS]: (state, action) => ({
        ...state,
        getUserByAddress: false,
        getUserByAddressSuccess: true,
        getUserByAddressFailure: false,
        user: action.payload.user
    }),
    [GET_USER_BY_ADDRESS_FAILURE]: state => ({
        ...state,
        getUserByAddress: false,
        getUserByAddressSuccess: false,
        getUserByAddressFailure: true
    }),
    [GET_ACCOUNT_ASSETS]: state => ({
        ...state,
        getAccountAssets: true,
        getAccountAssetsSuccess: false,
        getAccountAssetsFailure: false
    }),
    [GET_ACCOUNT_ASSETS_SUCCESS]: (state, action) => ({
        ...state,
        getAccountAssets: false,
        getAccountAssetsSuccess: true,
        getAccountAssetsFailure: false,
        accountAssets: action.payload.result
    }),
    [GET_ACCOUNT_ASSETS_FAILURE]: state => ({
        ...state,
        getAccountAssets: false,
        getAccountAssetsSuccess: false,
        getAccountAssetsFailure: true
    }),
    [UPDATE_NONCE]: state => ({
        ...state,
        updateNonce: true,
        updateNonceSuccess: false,
        updateNonceFailure: false
    }),
    [UPDATE_NONCE_SUCCESS]: state => ({
        ...state,
        updateNonce: false,
        updateNonceSuccess: true,
        updateNonceFailure: false
    }),
    [UPDATE_NONCE_FAILURE]: state => ({
        ...state,
        updateNonce: false,
        updateNonceSuccess: false,
        updateNonceFailure: true
    })
}

const initialState = {
    myself: null,
    isGuest: true,
    loggingIn: false,
    loginSuccess: false,
    loginError: false,
    registering: false,
    registerSuccess: false,
    registerError: false,
    getUserByAddress: false,
    getUserByAddressSuccess: false,
    getUserByAddressFailure: false,
    getAccountAssets: false,
    getAccountAssetsSuccess: false,
    getAccountAssetsFailure: false,
    updateNonce: false,
    updateNonceSuccess: false,
    updateNonceFailure: false,
    token: null,
    user: null,
    accountAssets: null
}

export default function auth(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
