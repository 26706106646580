import Page from '../../components/Order/Page'
import { connect } from 'react-redux'
import {
    fetchComments,
    fetchIcoDetails,
    writeComments,
    getOrderComments,
    checkIsOwner,
    updateAd
} from '../../actions/ads'
import { fetchReservations, reserve } from '../../actions/reservations'
import { 
    createTransaction,
    getTransaction,
    getTransactions,
    getTransactionHistory,
    updateTransaction
} from '../../actions/transactions'
import { createReview } from '../../actions/reviews'
import { createTransactionComment, getTransactionComments } from '../../actions/comments'
import {
    me,
    handleSignup,
    login,
    logout,
    authorize,
    getUserByPublicAddress,
    getAccountAssets
} from '../../actions/auth'
import { showFlashMessage } from '../../actions/app'
import { getOwnerStats } from '../../actions/users'

const mapActionCreators = {
    fetchIcoDetails,
    reserve,
    fetchComments,
    writeComments,
    fetchReservations,
    me,
    showFlashMessage,
    createTransaction,
    handleSignup,
    authorize,
    login,
    logout,
    getTransaction,
    getTransactions,
    getTransactionHistory,
    createReview,
    getTransactionComments,
    getOrderComments,
    updateTransaction,
    checkIsOwner,
    createTransactionComment,
    updateAd,
    getUserByPublicAddress,
    getAccountAssets,
    getOwnerStats
}

const mapStateToProps = state => ({
    myself: state.auth.myself,
    icoDetails: state.ads.icoDetails,
    reservations: state.reservations.reservations,
    comments: state.ads.comments,
    comment: state.ads.comment,
    auth: state.auth,
    isGuest: state.auth.isGuest,
    user: state.auth.user,
    transaction: state.transactions.transaction,
    transactions: state.transactions.transactions,
    histories: state.transactions.histories,
    transactionComments: state.comments.transactionComments,
    orderComments: state.ads.orderComments,
    updateTransaction: state.transactions.updateTransaction,
    isOwner: state.ads.isOwner,
    createTransactionComment: state.comments.createTransactionComment,
    accountAssets: state.auth.accountAssets,
    stats: state.users.stats
})

export default connect(mapStateToProps, mapActionCreators)(Page)
