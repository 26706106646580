import Bids from '../../components/MiniApp/Bids';
import { connect } from 'react-redux';
import { me } from '../../actions/auth';
import { fetchBuyAds } from '../../actions/ads';
import { showFlashMessage } from '../../actions/app';

const mapActionCreators = {
  me,
  fetchBuyAds,
  showFlashMessage,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  myself: state.auth.myself,
  buyAds: state.ads.buyAds,
  isFetching: state.ads.fetchingBuyAds,
});

export default connect(mapStateToProps, mapActionCreators)(Bids);
