import React, { Component } from 'react'
import Modal from 'react-bootstrap4-modal'
import Textarea from '../../common/Textarea'
import Cleave from 'cleave.js/react'

export default class EditOrder extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
            className: '',
            order: {},
            notes: {
                isChanged: false,
                value: ''
            },
            supply: {
                isChanged: false,
                value: ''
            }
        }
    }

    open = (order) => {
        this.setState({
            show: true,
            order,
            notes: {
                value: order.notes
            },
            supply: {
                value: order.supply
            }
        })
    }

    close = () => {
        this.setState({ show: false })
    }

    renderHeader = () => {
        return (
            <h4 className="modal-title">Edit Order Details</h4>
        )
    }

    _toggleAction = async() => {
        const { handleEditOrder } = this.props
        const { order, notes, supply } = this.state

        let data = {}
        
        if (!notes.isChanged && !supply.isChanged) {
            window.alert.error('Nothing to edit.')
        } else {
            if (notes.isChanged) {
                Object.assign(data, {
                    'notes': notes.value
                })
            }

            if (supply.isChanged) {
                Object.assign(data, {
                    'supply': supply.value
                })
            }

            await handleEditOrder(order.uuid, data)
        }
    }

    onChange = (e) => {
        const name = e.target.name
        const value = name === 'supply' ? e.target.rawValue : e.target.value

        this.setState({
            [name]: {
                isChanged: true,
                value
            }
        })
    }

    renderBody = () => {
        const { notes, supply } = this.state
        return (
            <div className="mt-3">
                <div className="form-group">
                    <label htmlFor="edit-note">Note:</label>
                    <Textarea id="edit-note" name="notes"
                        value={notes.value} onChange={this.onChange}
                        placeholder="Write down any pertinent information about this allocation to ensure a smooth transaction"/>
                </div>
                <div className="form-group">
                    <label htmlFor="edit-supply">Supply:</label>
                    <Cleave id="edit-supply" name="supply"
                        className="form-control" options={{ numeral: true, numeralThousandsGroupStyle: "thousand"}} 
                        value={supply.value} onChange={this.onChange}/>
                </div>
            </div>
        )
    }

    renderFooter = () => {
        // const {order} = this.state;

        return (
            <div className="modal-footer pr-0">
                <button
                    type="button"
                    className="btn btn-white"
                    onClick={this.close}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className="btn btn-blue"
                    onClick={this._toggleAction}
                >
                    SUBMIT
                </button>
            </div>
        )
    }

    render() {
        const { show } = this.state;
        return (
            <Modal className="modal" visible={show}>
                <div className="modal-header col-lg-12 p-0">
                    <div className="modal-title col-lg-12 p-0">
                        {this.renderHeader()}
                    </div>
                </div>
                <div className="modal-body col-lg-12 p-0">
                    {this.renderBody()}
                </div>
                    {this.renderFooter()}
            </Modal>
        )
    }
}
