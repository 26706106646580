import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import TransactionViewer from './Details/TransactionViewer'
import TransactionOwner from './Details/TransactionOwner'
import Loading2 from '../common/Loading2'

export default class Details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            reservations: [],
            isActionBusy: false
        }

        this._transactSubmit = this._transactSubmit.bind(this)
        this._getTransactionData = this._getTransactionData.bind(this)
    }

    async componentDidMount() {
        const { isGuest } = this.props
        
        if (isGuest) {
            await this.setState({ isLoading: false })
        } else {
            await this._getTransactionData()
            await this.setState({ isLoading: false })
        }
    }

    async componentDidUpdate(prevProps) {
        const { myself } = this.props
        if (prevProps.myself !== myself) {
            await this._getTransactionData()
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    async _getTransactionData() {
        const { ico, myself, getTransaction, getTransactions } = this.props
        
        if (ico.user_id !== myself.id) {
            await getTransaction(ico.uuid, ico.user_id, myself.id)
        } else {
            await getTransactions(ico.uuid)
        }
    }

    async _transactSubmit(id, data) {
        const { createTransaction } = this.props
        this.setState({ isActionBusy: true })

        await createTransaction(id, data)
        await this._getTransactionData()

        this.setState({ isActionBusy: false })
    }

    _showDetails = () => {
        const { 
            transaction,
            transactions,
            isGuest,
            ico,
            myself
        } = this.props

        let isOwner = false
        
        const { isLoading, isActionBusy } = this.state

        if (isLoading) {
            return <div className="m-auto"><Loading2 /></div>
        }

        if (myself) {
            if (myself.id === ico.user_id) {
                isOwner = true
            }
        }

        if (isGuest || !isOwner) {
            return (
                <TransactionViewer
                    {...this.props}
                    transaction={transaction}
                    refreshTransactions={this._getTransactionData}
                    isActionBusy={isActionBusy}
                    transactSubmit={this._transactSubmit}
                />
            )
        } else {
            return <TransactionOwner {...this.props} refreshTransactions={this._getTransactionData} transactions={transactions} />
        }
    }

    _icoInfo = (ico) => {
        let maximumFractionDigits = 16
        switch (ico.currency) {
            case 'USDT':
                maximumFractionDigits = 6
                break
            case 'BTC':
                maximumFractionDigits = 8
                break
            case 'NEO':
                maximumFractionDigits = 0
                break
        }

        return (
            <div className="session-block">
                <div className="row p-0">
                    <div className="col-lg-8 col-md-12 col-sm-12">
                        <h3 className="pitch-black bold">Deal Info</h3>
                        <div className="info-session">
                            <p className="number">{ico.supply.toLocaleString()} {ico.symbol}</p>
                            <p>Number of tokenss</p>
                        </div>
                        <div className="info-session">
                            <p className="number">{parseFloat(ico.ico_price_token || ico.buying_price_token).toFixed(maximumFractionDigits)}</p>
                            <p>{ico.buying_price_token ? 'Buying Price per tokens' : 'Price per tokens'}</p>
                        </div>
                        <div className="info-session">
                            <p className="number">{ico.currency}</p>
                            <p>Payment accepted</p>
                        </div>
                        <div className="info-session">
                            <p className="number">{ico.type.toUpperCase()}</p>
                            <p>Type</p>
                        </div>
                        <div className="info-session notes mb-5">
                            <p className="title">Details</p>
                            <p className="body text-muted">
                                {ico.notes}
                            </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12 col-sm-12">
                        <small className="text-muted">Date posted</small>
                        <p>{moment(ico.created_at).format("MMMM D, YYYY")}</p>
                        <small className="text-muted">Token release date</small>
                        <p>{moment(ico.token_release_date).format("MMMM D, YYYY")}</p>
                    </div>
                </div>

                <div className="row p-0">
                    {this._showDetails()}
                </div>
            </div>
        )
    }

    render() {
        const { ico } = this.props

        return (
            <div className="deal-info col-lg-7">
                {this._icoInfo(ico)}
            </div>
        )
    }
}

Details.propTypes = {
    ico: PropTypes.object.isRequired,
    isGuest: PropTypes.bool.isRequired,
    myself: PropTypes.object
}

Details.defaultProps = {}
