import React, { Component } from 'react'
import User from '../Members/User'
import Loading2 from '../../common/Loading2'
import ConfirmDialog from '../../common/ConfirmDialog'
// import { NavLink } from "react-router-dom";
import { debounce } from 'lodash'
import DetailsModal from '../Members/DetailsModal'

export default class Members extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            search: '',
            filter: 'all',

            isDialogShow: false,
            dialogBehavior: null,
            uuid: null,
            isActionDisabled: false,
            dialogMessage: '',

            // User details
            isShowUserDetails: false,
            userDetailsUserId: null,
            behavior: null,
            
            isEscrower: false,
            isAppraiser: false
        }

        this._filterUser = this._filterUser.bind(this)
        this._disableOrEnableAccount = this._disableOrEnableAccount.bind(this)
        this._cancelAction = this._cancelAction.bind(this)
        this._confirmAction = this._confirmAction.bind(this)
        this._showUserDetails = this._showUserDetails.bind(this)
        this._closeUserDetails = this._closeUserDetails.bind(this)
        this._assignEscrowUser = this._assignEscrowUser.bind(this)
        this._assignAppraiseUser = this._assignAppraiseUser.bind(this)
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            await this._summaryCount()
            await this._filterUser()
        }
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    async _summaryCount() {
        // Get summary - count
        const { adminGetMembersSummary } = this.props
        await adminGetMembersSummary()
    }

    /**
     * Filter user
     * @param  {[type]} filter [description]
     */
    async _filterUser(filter) {
        this.setState({ filter })
        await this._fetchUsers(filter)
    }

    /**
     * Fetch users
     * @param  {String} [filter='all'] [description]
     * @return {[type]}                [description]
     */
    async _fetchUsers(filter = 'all', search = '') {
        const { adminGetUsers } = this.props

        await adminGetUsers(filter, search)
    }

    _renderUsers = () => {
        const { users } = this.props

        if (!users) {
            return <Loading2 />
        }

        if (users && users.length === 0)
            return (<div className="col-12 app-message"><h4 className="text-info">Nothing found.</h4></div>)
        return users.map(user => {
            return (
                <User
                    key={user.id}
                    user={user}
                    showUserDetails={this._showUserDetails}
                    disableOrEnableAccount={this._disableOrEnableAccount}
                    assignAsEscrower={this._assignEscrowUser}
                    assignAsAppraiser={this._assignAppraiseUser}
                />
            )
        })
    };

    _disableOrEnableAccount({ uuid }, isEnabled = false) {
        // Show confirm dialog
        const message = `Are you sure you want to ${isEnabled ? 'enable' : 'disable'} this account?`
        this.setState({
            isDialogShow: true,
            uuid,
            dialogMessage: message,
            isActionDisabled: !isEnabled,
            behavior: 'toggle'
        })
    }

    _assignEscrowUser({ uuid, full_name }, isEscrower = false) {
        const message = `Are you sure you want to ${isEscrower ? 'assign' : 'remove'} ${full_name} as escrower?`

        this.setState({
            isDialogShow: true,
            uuid,
            dialogMessage: message,
            isEscrower,
            behavior: 'assignEscrower'
        })
    }

    _assignAppraiseUser({ uuid, full_name }, isAppraiser = false) {
        const message = `Are you sure you want to ${isAppraiser ? 'assign' : 'remove'} ${full_name} as escrower?`

        this.setState({
            isDialogShow: true,
            uuid,
            dialogMessage: message,
            isAppraiser,
            behavior: 'assignAppraiser'
        })
    }

    _cancelAction() {
        this.setState({ isDialogShow: false, uuid: null })
    }

    async _confirmAction() {
        const { filter, behavior, uuid } = this.state
        const { adminUpdateUser } = this.props
        let data

        if (behavior === 'toggle') {
            const { isActionDisabled } = this.state
            data = {
                'is_banned': isActionDisabled
            }

        } else if (behavior === 'assignEscrower') {
            const { isEscrower } = this.state
            data = {
                'is_escrower': isEscrower
            }

        } else if (behavior === 'assignAppraiser') {
            const { isAppraiser } = this.state
            data = {
                'is_appraiser': isAppraiser
            }
        }

        await adminUpdateUser(uuid, data)

        this._cancelAction()
        this._summaryCount()
        this._filterUser(filter)
    }

    _searchUser = (e) => {
        e.persist()

        if (!this.debouncedFn) {
            this.debouncedFn = debounce(() => {
                const { value } = e.target

                this.setState({ isLoading: true, users: [], search: value })
                if (value.length > 2)
                    this._fetchUsers(this.state.filter, value)
                else
                    this._fetchUsers(this.state.filter)

            }, 1000)
        }
        this.debouncedFn()

    }

    _showUserDetails(uuid) {
        this.setState({ isShowUserDetails: true, userDetailsUserId: uuid })
    }

    _closeUserDetails() {
        this.setState({ isShowUserDetails: false, userDetailsUserId: null })
    }

    render() {
        let {
            filter,
            isDialogShow, dialogMessage,
            isShowUserDetails,  userDetailsUserId
        } = this.state;

        const { adminGetUser, user, summary: { all, recent, banned } } = this.props

        return (
            <div className="recent-members session-block row">
                <ConfirmDialog
                    cancel={this._cancelAction}
                    confirm={this._confirmAction}
                    isShow={isDialogShow}
                    message={dialogMessage}
                />
                <DetailsModal
                    uuid={userDetailsUserId}
                    isShow={isShowUserDetails}
                    adminGetUser={adminGetUser}
                    user={user}
                    close={this._closeUserDetails}
                />
                <div className="col-12 p-0">
                    <div className="row">
                        <div className="col-6 p-md-0 text-left">
                            <div className="img float-left">
                                <img src="/users.svg" alt=""/>
                            </div>
                            <h3 className="mb-5">Members</h3>
                        </div>
                    </div>
                </div>
                <div className="col-12 pl-0 pr-0">
                    <ul className="app-nav nav">
                        <li className="nav-item" onClick={() => this._filterUser('all')}>
                            <span className={`nav-link ${filter === 'all' ? 'active' : ''}`}>All Members ({all})</span>
                        </li>
                        <li className="nav-item" onClick={() => this._filterUser('recent')}>
                            <span className={`nav-link ${filter === 'recent' ? 'active' : ''}`}>Recent Members ({recent})</span>
                        </li>
                        <li className="nav-item" onClick={() => this._filterUser('banned')}>
                            <span className={`nav-link ${filter === 'banned' ? 'active' : ''}`}>Banned Members ({banned})</span>
                        </li>
                    </ul>

                </div>
                <div className="col-12 mt-4 p-0">
                    <div className="search">
                        <input type="text" name="search" className="form-control" placeholder="Search here..." onChange={this._searchUser}/>
                    </div>
                </div>
                <div className="col-12 p-0">
                    <div className="row">
                        {this._renderUsers()}
                    </div>
                </div>
            </div>
        )
    }
}
