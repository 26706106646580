import Header from '../../components/Header/Header'
import { connect } from 'react-redux'
import { 
    me,
    register,
    login,
    logout,
    handleSignup, 
    authorize,
    getUserByPublicAddress,
    getAccountAssets,
    updateNonce
} from '../../actions/auth'
import { connectWallet } from '../../actions/users'
import { showFlashMessage } from '../../actions/app'
import { getNotifications, markAsRead } from '../../actions/notification'

const mapActionCreators = {
    me,
    showFlashMessage,
    register,
    login,
    logout,
    getNotifications,
    markAsRead,
    handleSignup,
    authorize,
    getUserByPublicAddress,
    getAccountAssets,
    updateNonce,
    connectWallet
}

const mapStateToProps = state => ({
    accountAssets: state.auth.accountAssets,
    auth: state.auth,
    flashMessage: state.app.flashMessage,
    myself: state.auth.myself,
    notifications: state.notification,
    user: state.auth.user,
})

export default connect(mapStateToProps, mapActionCreators)(Header)
