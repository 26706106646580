import VerifyUser from "../../components/Auth/VerifyUser";
import { connect } from "react-redux";
import { showFlashMessage } from "../../actions/app";

const mapActionCreators = {
    showFlashMessage
};

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, mapActionCreators)(VerifyUser);
