import React from 'react'
import BaseModal from '../../common/BaseModal'
import Loading2 from '../../common/Loading2'
import { NavLink } from 'react-router-dom'
import Autosuggest from 'react-autosuggest'
import { debounce } from 'lodash'

const getSuggestionValue = (suggestion) => suggestion.name

const renderSuggestion = (suggestion) => (
    <NavLink to={`/${suggestion.user.full_name}/${suggestion.type}/${suggestion.name}/${suggestion.uuid}`}>
        <div className="row listed-token-modal__suggestion-item">
            <div className="col">
                {suggestion.name} ({suggestion.symbol})
            </div>
            <div className="col-auto">
                {suggestion.ico_price_token} {suggestion.currency}
            </div>
        </div>
    </NavLink>
)

export default class ListedTokenModal extends BaseModal {
    constructor(props) {
        super(props)

        this.state = {
            type: '',
            value: '',
            isLoading: false,
            toggleForm: false,
            transactionType: '',
            formData: {
                asset_name: '',
                asset_ticker: '',
                chain: '',
            }
        }
    }

    onChange = (event, { newValue }) => {
        this.onSuggestionsClearRequested()
        this.setState({
            value: newValue,
        })
    }

    onBlur = (event) => {
        this.onSuggestionsClearRequested()
    }

    handleChange = (e) => {
        this.setState({
            type: e.target.value,
        })
    }

    onSuggestionsFetchRequested = debounce(async ({ value }) => {
        const { searchAds } = this.props
        const { type } = this.state
        if (type) {
            this.setState({ isLoading: true })
            const { payload: { icos } } = await searchAds({ value, type })
            this.setState({ icos, isLoading: false })
        }
    }, 1000)

    onSuggestionsClearRequested = () => {
        this.setState({
            icos: [],
        })
    }

    toggleOpen = async () => {
        await this.setSettings({
            className: 'listed-token-modal',
            onClickBackdropClose: true,
            showModalClose: true,
        })
        this.open()
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const url = `/order/new/${this.state.type}/${this.state.formData.asset_name}/${this.state.formData.asset_ticker}/liquid`;
        this.props.history.push(url)
        console.log(this.props);
    }

    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            formData: {
                ...this.state.formData,
                [name]: value,
            }
        });
      };

    _close = () => {
        this.close()
    }

    renderHeader = () => {
        return (
            <div>
                {/* <h2 className="listed-token-modal__title">Listed Token</h2> */}
            </div>
        )
    }

    renderBody = () => {
        const { value, type, isLoading, formData } = this.state
        const { ads } = this.props

        const autosuggestProps = {
            placeholder: 'Type a token name',
            value,
            onChange: this.onChange,
            onBlur: this.onBlur,
        }
        
        return (
            <div className="listed-token-modal__content">
                {!type && (
                    <>
                        <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                            <div className="mb-4">
                                <img src="/reciept-long.png" />
                            </div>
                            <div className="d-flex flex-column align-items-center justify-content-center">
                                <h3 className="fw-bold">Select your transaction</h3>
                                <p>Are you buying or selling?</p>
                            </div>
                        </div><div className="form-group">
                            <input className="btn btn-blue text-white mb-2" type="button" name="buy" value="Buying" onClick={this.handleChange} />
                            <input className="btn btn-orange text-white" type="button" name="sell" value="Selling" onClick={this.handleChange} />
                        </div>
                    </>
                )}

                {type && (
                    <div>
                        <div className="d-flex flex-column align-items-start justify-content-start mb-4">
                            <h3 className="fw-bold">Listed Token</h3>
                            <p>Select token below</p>
                        </div>
                        <form className="d-flex flex-column" onSubmit={this.handleSubmit}>
                            <label htmlFor="asset_name">Enter Token</label>
                            <input 
                                onChange={this.handleInputChange}
                                type="text"
                                value={formData.asset_name}
                                name="asset_name"
                                id="asset_name"
                                className="mb-2"
                            />
                            <label className='listed-input__label text-black-50 fs-6' htmlFor="asset_ticker">Ticker</label>
                            <input
                                onChange={this.handleInputChange}
                                type="text" value={formData.asset_ticker}
                                name="asset_ticker"
                                id="asset_ticker"
                                className="mb-2"
                            />
                            <label className='listed-input__label text-black-50 fs-6' htmlFor="chain">Chain</label>
                            <input
                                onChange={this.handleInputChange}
                                type="text" value={formData.chain}
                                name="chain"
                                id="chain"
                                className="mb-5"
                            />
                            <input className="btn btn-blue text-white" type="submit" value={'Continue'} />
                        </form>
                    </div>
                )}

                {isLoading && <Loading2 />}
            </div>
        )
    }
}
