import React from 'react'
import Cleave from 'cleave.js/react'
import crypto from 'crypto-price'
import { debounce } from 'lodash'
import BaseModal from '../../../common/BaseModal'

export default class TransactionEditModal extends BaseModal {
    constructor(props) {
        super(props)

        this.state = {
            pricePerToken: '',
            fee: ''
        }
    }

    async componentDidMount() {        
        await this.setSettings({
            onClickBackdropClose: true
        })

        const { transaction } = this.props

        let pricePerToken = parseFloat(transaction.price_per_token).toFixed(16)
        
        switch(transaction.currency) {
            case 'BTC':
                pricePerToken = parseFloat(transaction.price_per_token).toFixed(8)
                break
            case 'USDT':
                pricePerToken = parseFloat(transaction.price_per_token).toFixed(6)
                break
            case 'NEO':
                pricePerToken = parseFloat(transaction.price_per_token).toFixed(0)
                break
        }

        this.setState({ pricePerToken: pricePerToken, fee: transaction.fee })
    }

    onChange = async(e) => {
        const name = e.target.name
        const value = e.target.rawValue

        await this.setState({
            [name]: value
        })

        await this.feeChange()
    }

    componentWillUnmount() {
        this.setState({ pricePerToken: '', fee: '' })
    }

    feeChange = debounce(async() => {
        const { transaction } = this.props
        const { pricePerToken } = this.state

        const parsedPrice = pricePerToken,
            parsedSupply = parseFloat(transaction.supply),
            total = parsedPrice * parsedSupply

        let totalPrice, fee

        if (transaction.currency !== 'USDT') {
            const cryptoPrice = await crypto.getCryptoPrice('usdt', transaction.currency)
            const { price } = cryptoPrice
            totalPrice = parseFloat(price) * total
        } else {
            totalPrice = total
        }
        
        if (totalPrice > 1000) {
            fee = 0.02 * totalPrice
        } else {
            if (!isNaN(totalPrice))
                fee = 21
        }

        this.setState({ fee })
    }, 500)

    renderHeader = () => {
        return <h4>
            Revise Transaction
        </h4>
    }

    renderBody = () => {
        const { pricePerToken, fee } = this.state
        const { transaction: { currency } } = this.props

        let numeralDecimalScale = 16
        switch(currency) {
            case 'USDT':
                numeralDecimalScale = 6
                break
            case 'BTC':
                numeralDecimalScale = 8
                break
            case 'NEO':
                numeralDecimalScale = 0
                break
        }

        return <div className="mt-5 container">
            <div className="form-group">
                <label>Price per Token</label>
                <Cleave
                    name="pricePerToken"
                    className="form-control"
                    options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', numeralDecimalScale }}
                    value={pricePerToken}
                    onChange={this.onChange}
                />
            </div>
            <div className="form-group">
                <label className="text-danger">Fee</label>
                <input type="text" className="form-control" value={fee ? parseFloat(fee).toFixed(6) : 0} readOnly/>
            </div>
        </div>
    }

    renderFooter = () => {
        const { transEditOnSubmit, transaction, isLoading } = this.props
        const { pricePerToken, fee } = this.state
        return (
            <div className="row">
                <div className="col-12 text-right buttons">
                    <button
                        className="btn btn-white"
                        onClick={() => this.close()}
                        disabled={isLoading}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-blue"
                        onClick={() => transEditOnSubmit(transaction, pricePerToken, fee)}
                        disabled={isLoading}
                    >
                        Submit
                    </button>
                </div>
            </div>
        );
    }
}