import React, { Component, createRef } from 'react'
import SearchForm from './Search/Form'
import SearchResult from './Search/Result'
import UserLogs from './UserLogs'
import CreateOrder from './CreateOrder'
import RecentMessages from './RecentMessages'
import FrozenModal from './FrozenModal'

export default class Dashboard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            searchResults: [],
            activeTab: 'activities',
            activeFilter: '',
            searchPayload: null,
            isShowLoading: false
        }

        this.frozenModalRef = createRef()
    }

    async componentDidMount() {
        const { getMessages, myself } = this.props

        const auth = JSON.parse(localStorage.getItem('persist:root'))
        const token = auth && JSON.parse(auth.auth)

        if (token && token.token) {
            await getMessages()
            await this.getDashboardData()
        }

        if (myself && myself.is_banned) {
            this.frozenModalRef.current.toggleOpen()
        }
    }

    async getDashboardData() {
        const { activeTab, activeFilter } = this.state
        const { getUserInteractions } = this.props
        await getUserInteractions(activeTab, activeFilter)
    }

    _updateTransaction = async (icoUUID, transactionUUID, data) => {
        const { updateTransaction } = this.props
        const { payload: { message } } = await updateTransaction(icoUUID, transactionUUID, data)
        window.alert.success(message)
        await this.getDashboardData()
    }

    _clearAllUsers = () => {
        this.setState({ all_users: null })
    }

    _handleTabChange = async(tab) => {
        if (tab === 'transactions') {
            await this.setState({ activeTab: tab, activeFilter: 'ongoing' })
        } else {
            await this.setState({ activeTab: tab, activeFilter: '' })
        }
        
        await this.getDashboardData()
    }

    _handleFilterChange = async(filter) => {
        await this.setState({ activeFilter: filter })
        await this.getDashboardData()
    }

    _getMyOrders = async (payload) => {
        this.setState({ isShowLoading: true })
        const { searchAds } = this.props

        await searchAds(payload)
        this.setState({ searchPayload: payload, isShowLoading: false })
    }

    render() {
        const { activeTab, searchPayload, isShowLoading } = this.state

        const {
            messages,
            showFlashMessage,
            dashboardData,
            ads,
            searchAds,
            logout,
        } = this.props

        if (dashboardData) {
            return (
                <div id="dashboard" className="user-dashboard-container dashboard-container">
                    <SearchForm search={this._getMyOrders} />
                    <SearchResult ads={ads} search={this._getMyOrders} searchPayload={searchPayload} isShowLoading={isShowLoading} />
                    <div className="row">
                        <div className="col-12 col-lg-8 col-sm-12 mb-5 mb-mb-0">
                            <UserLogs
                                data={dashboardData}
                                showFlashMessage={showFlashMessage}
                                activeTab={activeTab}
                                filterChange={this._handleFilterChange}
                                tabChange={this._handleTabChange}
                                updateTransaction={this._updateTransaction}
                            />
                        </div>
                        <div className="col-12 col-lg-4 col-sm-12">
                            <div className="row">
                                <div className="col-12">
                                    <CreateOrder ads={ads} searchAds={searchAds} />
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-12">
                                    <RecentMessages messages={messages} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <FrozenModal logout={logout} ref={this.frozenModalRef} />
                </div>
            )
        } else {
            return ''
        }
    }
}
