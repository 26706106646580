import React, { Component } from 'react'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import DatePicker from 'react-datepicker/es'
import 'react-datepicker/dist/react-datepicker.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Loading2 from '../../common/Loading2'
import 'chart.js/auto';

export default class Chart extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
            isLoading: true
        }

        this._handleDateToChange = this._handleDateToChange.bind(this)
        this._handleDateFromChange = this._handleDateFromChange.bind(this)
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true;
        if (this._isMounted)
            await this._fetchUsersByDate();
    }

    async _handleDateFromChange(date){
        await this.setState({ from: moment(date).format('YYYY-MM-DD') })
        await this._fetchUsersByDate()
    }

    async _handleDateToChange(date) {
        await this.setState({ to: moment(date).format('YYYY-MM-DD') })
        await this._fetchUsersByDate()
    };

    _fetchUsersByDate = async() => {
        const { adminGetWeeklyUsers } = this.props
        let { from, to } = this.state;

        this.setState({ isLoading: true })
        await adminGetWeeklyUsers(from, to)

        if (this._isMounted)
            this.setState({ isLoading: false })
    }

    render() {
        const { from, to, isLoading } = this.state
        const { weeklyUsers } = this.props
        let date = [], counts = []

        if (weeklyUsers) {
            for (let {label, count} of weeklyUsers) {
                date.push(label)
                counts.push(count)
            }
        }

        let elementWidth = 0
        if (document.getElementById('box-get-width')) {
            elementWidth = document.getElementById('box-get-width').clientWidth
        }

        const chartData = {
            labels: date,
            datasets: [
                {
                    fontSize: 16,
                    borderColor: '#f2994a',
                    backgroundColor: '#fef9ed',
                    pointRadius: 7,
                    pointHoverRadius: 10,
                    pointBackgroundColor: '#fff',
                    data: counts
                }
            ]
        }

        const chartOptions = {
            legend: false,
            elements: {
                line: {
                    tension: 0
                },
            },
            scales: {
                yAxes: [{
                    ticks: {
                        fontSize: 14,
                        padding: 20,
                        beginAtZero: true,
                        stepSize: 1
                    }
                }],
                xAxes: [{
                    ticks: {
                        fontSize: 14,
                        padding: 20
                    }
                }]
            },
            layout: {
                padding: {
                    left: 30,
                    right: 50,
                    top: 0,
                    bottom: 0
                }
            },
            maintainAspectRatio: false
        }

        return (
            <div className="weekly-users session-block row">
                <div className="col-3 dates">
                    <h3>Weekly Users</h3>
                </div>
                <div className="offset-lg-3 col-lg-3 col-sm-12 pr-0 ">
                    <div className="inner-block date-picker">
                        <div className="input-group mb-3">
                            <label>Date from</label>
                            <div className="datepicker-container">
                                <DatePicker
                                    name="from"
                                    selected={moment(from)}
                                    className="form-control w-100"
                                    onChange={this._handleDateFromChange}
                                    dropdownMode="select"
                                    dateFormat={"YYYY-MM-DD"}
                                    />
                                <div className="input-group-append">
                                    <span className="input-group-text bg-blue">
                                        <FontAwesomeIcon icon="calendar" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-sm-12 pr-0">
                    <div className="inner-block date-picker">
                        <div className="input-group mb-3">
                            <label>Date to</label>
                            <div className="datepicker-container">
                                <DatePicker
                                    name="to"
                                    selected={moment(to)}
                                    className="form-control w-100"
                                    onChange={this._handleDateToChange}
                                    dropdownMode="select"
                                    dateFormat={"YYYY-MM-DD"}
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text bg-blue">
                                        <FontAwesomeIcon icon="calendar" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12" id="box-get-width">
                    <div className="chart w-100" >
                        { isLoading ? <Loading2/> : <Line
                            data={chartData}
                            options={chartOptions}
                            height={170}
                            width={elementWidth}
                            />
                        }
                    </div>
                </div>

            </div>
        )
    }
}
