import React from 'react'
import axios from 'axios'
import BaseModal from '../../../common/BaseModal'
import Loading2 from '../../../common/Loading2'
import { Button } from '../../../common/Button'
import { timeFromNow } from '../../../../modules/Utilities'

export default class ChatModal extends BaseModal {

    constructor(props) {
        super(props)

        this.state = {
            message: '',
            isCommenting: false,
            comments: []
        }
    }

    async componentDidUpdate(prevProps){
        const { getTransactionComments, transactionId, ico } = this.props
        const { show } = this.state

        if(prevProps.transactee !== this.props.transactee) {
            await getTransactionComments(ico.uuid, transactionId)

            if (show) {
                this._scrollToBottom()
            }
        }
    }

    _scrollToBottom = () => {
        const element = document.getElementById('window')
        element.scrollTop = element.scrollHeight
    }

    _renderGrayBalloon = (user, { id, data: {message}, created_at }) => {
        return <div key={id} className="message row p-0">
            <div className="col-lg-2">
                <div className="image">
                    <img src={user.profile_picture || '/no-user-image.jpg'} className="w-100" alt="Avatar"/>
                </div>
            </div>
            <div className="col-lg-7 balloon receiving">
                {message}
            </div>
            <div className="font-italic float-left message-created message-owner">{timeFromNow(created_at)}</div>
        </div>
    }

    _renderBlueBalloon = ({id, data: {message}, created_at}) => {
        return <div key={id} className="message">
            <div className="col-lg-7 balloon sending">
                {message}
            </div>
            <div className="font-italic float-right message-created">{timeFromNow(created_at)}</div>
        </div>
    }

    _renderMessages = () => {
        const { myself, transactee, comments } = this.props

        if (!comments) {
            return <Loading2 />
        }

        if (comments && transactee) {
            return comments.map((comment) => {
                return comment.notifiable_id === myself.id
                ? this._renderGrayBalloon(transactee, comment)
                : this._renderBlueBalloon(comment)
            })
        }
    }


    _close = () => {
        this.setState({ message: "" })
        this.close()
    }

    onChange = (e) => {
        this.setState({
            message: e.target.value
        })
    }

    renderHeader = () => {
        return <h3 className="mb-0">Reply to {this.props.transactee && this.props.transactee.first_name}</h3>
    }

    renderBody = () => {

        return (
            <div className="chat">
                <div id="window" className="window">
                    <div className="messages">
                        {this._renderMessages()}
                    </div>
                </div>
            </div>
        )
    }

    /**
     * Submit message
     * @param  {[type]} e [description]
     * @return {[type]}   [description]
     */
    _onSubmit = async(e) => {
        const { message, isCommenting } = this.state
        const { transactionId, ico, showFlashMessage, getTransactionComments, getOrderComments } = this.props

        e.preventDefault();
        this.setState({ isCommenting:true })

        if (message.length > 0 && !isCommenting) {
            try {
                const { data } = await axios.post(`order/${ico.uuid}/${transactionId}/comments`, { message, transaction_id: transactionId })
                this.setState({ isCommenting:false, message: '' })
                window.alert.success(data.message)
                await getTransactionComments(ico.uuid, transactionId)
                await getOrderComments(ico.uuid)
            } catch (e) {
                this.setState({ isCommenting:false })
                window.alert.error(e)
            }
        } else {
            showFlashMessage('Please enter a message', 'error')
            this.setState({ isCommenting: false })
        }
    };

    renderFooter = () => {
        const { message, isCommenting } = this.state;
        // const { sendMessage } = this.props;

        return (
            <div className="chat col-12 text-right buttons p-0">
                <div className="bottom w-100">
                    <input
                        type="text"
                        name="message"
                        className="form-control"
                        value={message}
                        placeholder="Type something..."
                        onChange={this.onChange}
                        />
                </div>
                <div className="w-100 mt-4">
                    <button
                        className="btn btn-white"
                        onClick={() => this._close()}>Cancel</button>
                    <Button isLoading={isCommenting} type={'blue'} label={'send'} isCapitalize
                        onClick={this._onSubmit} />
                </div>
            </div>
        )
    }
}
