import { RSAA } from 'redux-api-middleware'

export const CREATE_TRANSACTION = 'auth:create_transaction'
export const CREATE_TRANSACTION_SUCCESS = 'auth:create_transaction_success'
export const CREATE_TRANSACTION_FAILURE = 'auth:create_transaction_failure'
export const CREATE_HISTORY = 'auth:create_history'
export const CREATE_HISTORY_SUCCESS = 'auth:create_history_success'
export const CREATE_HISTORY_FAILURE = 'auth:create_history_failure'
export const GET_TRANSACTION = 'auth:get_transaction'
export const GET_TRANSACTION_SUCCESS = 'auth:get_transaction_success'
export const GET_TRANSACTION_FAILURE = 'auth:get_transaction_failure'
export const GET_TRANSACTIONS = 'auth:get_transactions'
export const GET_TRANSACTIONS_SUCCESS = 'auth:get_transactions_success'
export const GET_TRANSACTIONS_FAILURE = 'auth:get_transactions_failure'
export const UPDATE_TRANSACTION = 'auth:update_transaction'
export const UPDATE_TRANSACTION_SUCCESS = 'auth:update_transaction_success'
export const UPDATE_TRANSACTION_FAILURE = 'auth:update_transaction_failure'
export const GET_HISTORY = 'auth:get_history'
export const GET_HISTORY_SUCCESS = 'auth:get_history_success'
export const GET_HISTORY_FAILURE = 'auth:get_history_failure'
export const COUNT_TRANSACTIONS = 'auth:count_transactions'
export const COUNT_TRANSACTIONS_SUCCESS = 'auth:count_transactions_success'
export const COUNT_TRANSACTIONS_FAILURE = 'auth:count_transactions_failure'

export function createTransaction(id, data) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/order/${id}/transaction`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(data),
                types: [CREATE_TRANSACTION, CREATE_TRANSACTION_SUCCESS, CREATE_TRANSACTION_FAILURE]
            }
        })
    }
}

export function getTransaction(id, ownerId, transacteeId) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/order/${id}/transaction?owner_id=${ownerId}&transactee_id=${transacteeId}`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [GET_TRANSACTION, GET_TRANSACTION_SUCCESS, GET_TRANSACTION_FAILURE]
            }
        })
    }
}

export function getTransactions(id) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/order/${id}/transactions`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [GET_TRANSACTIONS, GET_TRANSACTIONS_SUCCESS, GET_TRANSACTIONS_SUCCESS]
            }
        })
    }
}

export function updateTransaction(uuid, transactionId, data) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/order/${uuid}/transaction/${transactionId}`,
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(data),
                types: [UPDATE_TRANSACTION, UPDATE_TRANSACTION_SUCCESS, UPDATE_TRANSACTION_FAILURE]
            }
        })
    }
}

export function getTransactionHistory(id, transactionId) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/order/${id}/transaction/${transactionId}/history`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                types: [GET_HISTORY, GET_HISTORY_SUCCESS, GET_HISTORY_FAILURE]
            }
        })
    }
}

export function getTransactionsCount() {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/transactions/count`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                types: [COUNT_TRANSACTIONS, COUNT_TRANSACTIONS_SUCCESS, COUNT_TRANSACTIONS_FAILURE]
            }
        })
    }
}

const ACTION_HANDLERS = {
    [CREATE_TRANSACTION]: state => ({
        ...state,
        createTransaction: true,
        createTransactionSuccess: false,
        createTransactionFailure: false
    }),
    [CREATE_TRANSACTION_SUCCESS]: state => ({
        ...state,
        createTransaction: false,
        createTransactionSuccess: true,
        createTransactionFailure: false
    }),
    [CREATE_TRANSACTION_FAILURE]: state => ({
        ...state,
        createTransaction: false,
        createTransactionSuccess: false,
        createTransactionFailure: true 
    }),
    [CREATE_HISTORY]: state => ({
        ...state,
        createHistory: true,
        createHistorySuccess: false,
        createHistoryFailure: false
    }),
    [CREATE_HISTORY_SUCCESS]: state => ({
        ...state,
        createHistory: false,
        createHistorySuccess: true,
        createHistoryFailure: false
    }),
    [CREATE_HISTORY_FAILURE]: state => ({
        ...state,
        createHistory: false,
        createHistorySuccess: false,
        createHistoryFailure: true
    }),
    [GET_TRANSACTION]: state => ({
        ...state,
        getTransaction: true,
        getTransactionSuccess: false,
        getTransactionFailure: false
    }),
    [GET_TRANSACTION_SUCCESS]: (state, action) => ({
        ...state,
        getTransaction: false,
        getTransactionSuccess: true,
        getTransactionFailure: false,
        transaction: action.payload.transaction
    }),
    [GET_TRANSACTION_FAILURE]: state => ({
        ...state,
        getTransaction: false,
        getTransactionSuccess: false,
        getTransactionFailure: true
    }),
    [UPDATE_TRANSACTION]: state => ({
        ...state,
        updateTransaction: true,
        updateTransactionSuccess: false,
        updateTransactionFailure: false
    }),
    [UPDATE_TRANSACTION_SUCCESS]: state => ({
        ...state,
        updateTransaction: false,
        updateTransactionSuccess: true,
        updateTransactionFailure: false
    }),
    [UPDATE_TRANSACTION_FAILURE]: state => ({
        ...state,
        updateTransaction: false,
        updateTransactionSuccess: false,
        updateTransactionFailure: true
    }),
    [GET_HISTORY]: state => ({
        ...state,
        getHistory: true,
        getHistorySuccess: false,
        getHistoryFailure: false
    }),
    [GET_HISTORY_SUCCESS]: (state, action) => ({
        ...state,
        getHistory: false,
        getHistorySuccess: true,
        getHistoryFailure: false,
        histories: action.payload.histories
    }),
    [GET_HISTORY_FAILURE]: state => ({
        ...state,
        getHistory: false,
        getHistorySuccess: false,
        getHistoryFailure: true
    }),
    [GET_TRANSACTIONS]: state => ({
        ...state,
        getTransactions: true,
        getTransactionsSuccess: false,
        getTransactionsFailure: false
    }),
    [GET_TRANSACTIONS_SUCCESS]: (state, action) => ({
        ...state,
        getTransactions: false,
        getTransactionsSuccess: true,
        getTransactionsFailure: false,
        transactions: action.payload.transactions
    }),
    [GET_TRANSACTIONS_FAILURE]: state => ({
        ...state,
        getTransactions: false,
        getTransactionsSuccess: false,
        getTransactionsFailure: true
    }),
    [COUNT_TRANSACTIONS]: state => ({
        ...state,
        countTransactions: true,
        countTransactionsSuccess: false,
        countTransactionsFailure: false
    }),
    [COUNT_TRANSACTIONS_SUCCESS]: (state, action) => ({
        ...state,
        countTransactions: false,
        countTransactionsSuccess: true,
        countTransactionsFailure: false,
        totalOngoing: action.payload.total_ongoing,
        totalCompleted: action.payload.total_completed
    }),
    [COUNT_TRANSACTIONS_FAILURE]: state => ({
        ...state,
        countTransactions: false,
        countTransactionsSuccess: false,
        countTransactionsFailure: true
    })
}

const initialState = {
    createTransaction: false,
    createTransactionSuccess: false,
    createTransactionFailure: false,
    createHistory: false,
    createHistorySuccess: false,
    createHistoryFailure: false,
    getTransaction: false,
    getTransactionSuccess: false,
    getTransactionFailure: false,
    getTransactions: false,
    getTransactionsSuccess: false,
    getTransactionsFailure: false,
    updateTransaction: false,
    updateTransactionSuccess: false,
    updateTransactionFailure: false,
    transaction: null,
    transactions: null,
    getHistory: false,
    getHistorySuccess: false,
    getHistoryFailure: false,
    histories: null,
    transactionComments: null,
    countTransactions: false,
    countTransactionsSuccess: false,
    countTransactionsFailure: false,
    totalOngoing: null,
    totalCompleted: null
}

export default function transactions (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}