import React, { Component } from "react";
import Web3 from 'web3'
import thunkMiddleware from "redux-thunk";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import { apiMiddleware } from "redux-api-middleware";
import { persistCombineReducers, persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/es/integration/react";
import { library } from "@fortawesome/fontawesome-svg-core"
import reducers from "./actions/reducers";
import {
    faCheckCircle,
    faExclamationTriangle,
    faPlus,
    faSignInAlt,
    faSignOutAlt,
    faStar,
    faStopCircle,
    faTachometerAlt,
    faThumbsDown,
    faThumbsUp,
    faUser,
    faVideoSlash,
    fas
} from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
// Config
import { persistConfig } from "./config/app";

import Http from "./modules/Http"; // @TODO get rid of this module!
import './modules/Request' // Use this instead of `window.http`

import Notifier from "./modules/Notifier";
import Broadcaster from './modules/Broadcaster';
import 'react-table/react-table.css'
import "./App.css";
import AppContainer from "./containers/AppContainer";

library.add(faSignInAlt, fab, fas, faUser, faSignOutAlt, faExclamationTriangle, faCheckCircle, faStar, faStopCircle, faThumbsUp, faThumbsDown, faTachometerAlt, faPlus, faVideoSlash);

const middleware = [thunkMiddleware, apiMiddleware];

const reducer = persistCombineReducers(persistConfig, reducers);
const store = createStore(
    reducer,
    compose(applyMiddleware(...middleware))
);

const persistor = persistStore(store);

window.http = new Http();
window.alert = new Notifier();
window.broadcaster = new Broadcaster({
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER
});

if (window.location.host.startsWith('www')) {
    const hostArray = window.location.hostname.split('.');
    window.location = `${window.location.protocol}//${hostArray[1]}.${hostArray[2]}${window.location.pathname}`;
}

if (window.ethereum) {
    const web3 = new Web3(window.ethereum);
    const accounts = web3.eth.getAccounts();

    if (!accounts[0]) {
        window.ethereum.enable()
    }
}

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <AppContainer/>
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
