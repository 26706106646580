import React, { Component, createRef } from 'react'
import Cleave from 'cleave.js/react'
import moment from 'moment'
import ReactTooltip from 'react-tooltip'
import { isEmpty, debounce } from 'lodash'
import crypto from 'crypto-price'
import Comment from '../Comment/Comment'
import Textarea from '../../common/Textarea'
import { Button } from '../../common/Button'
import { Denied, Completed } from './Viewer/Status'
import ConfirmDialog from '../../common/ConfirmDialog'
import ReviewFormModal from './Viewer/ReviewFormModal'
import ConnectWalletModal from '../../Header/ConnectWalletModal'
import TransactionHistoryModal from './TransactionHistoryModal'
import DetailsModal from './DetailsModal'

import LoginModal from '../../Home/LoginModal';
import ForgotPasswordModal from '../../Home/ForgotPasswordModal';

export default class TransactionViewer extends Component {
    constructor(props) {
        super(props)

        this.state = {
            transactionType: '',
            isReservation: false,
            notes: '',
            fee: '',
            supply: '',
            confirmModalOpen: false,
            hasConnectedtoMetamask: false,
            hasConnectedtoWalletConnect: false,
            publicAddress: null,
            isActionBusy: false,
            totalPrice: '',
        }

        this._openConfirmModal = this._openConfirmModal.bind(this)
        this._handleConfirmClick = this._handleConfirmClick.bind(this)
        this._handleCancelClick = this._handleCancelClick.bind(this)
        this._openConnectWalletModal = this._openConnectWalletModal.bind(this)
        this._openReviewFormModal = this._openReviewFormModal.bind(this)

        this.walletConnectModal = createRef()
        this.historyModal = createRef()
        this.detailsModal = createRef()
        this.reviewModal = createRef()

        this.forgotPasswordModalRef = createRef();
        this.loginModalRef = createRef();
        this.registerModalRef = createRef();

    }

    componentDidMount() {
        const { ico, transaction } = this.props
        const { type } = ico

        const { state } = this.props.history.location

        if (isEmpty(transaction)) {
            if (type === 'sell') {
                const now = moment().format('YYYY-MM-DD')
                const tokenReleaseDateFuture = moment(ico.token_release_date).isAfter(now)

                if (tokenReleaseDateFuture) {
                    this.setState({ isReservation: true })
                }
            }
        } else {
            if (state && state.openDetails) {
                this.detailsModal.current.open()
            }
        }
    }

    onChange = async(e) => {
        const name = e.target.name
        const { ico } = this.props
        let value
        
        if (name === 'supply') {
            value = parseInt(e.target.rawValue)
            const totalSupply = parseFloat(ico.supply)
            if (Number.isNaN(value)) {
                window.alert.error('Supply should not be empty')
            } else if (totalSupply >= value) {
                await this.setState({
                    supply: value
                })
                
                await this.feeChange()
            } else {
                window.alert.error('Tokens to buy/sell should not be more than the available.')
                this.setState({
                    supply: '',
                    fee: ''
                })
            }
        } else {
            value = e.target.value
            await this.setState({
                [name]: value
            })
        }
    }

    _openConfirmModal = () => {
        this.setState({ isActionBusy: true, confirmModalOpen: true })
    }

    _handleConfirmClick = async() => {
        const { isReservation, notes, fee, supply, totalPrice } = this.state
        const { ico, auth: { myself }, transactSubmit } = this.props

        let data = {
            owner_id: ico.user_id,
            transactee_id: myself.id,
            ico_id: ico.id,
            type: ico.type,
            supply,
            price_per_token: ico.ico_price_token,
            currency: ico.currency,
            fee,
            total_price: totalPrice,
            notes
        }
        if (isReservation) {
            data.status = 'reserved'
            await transactSubmit(ico.uuid, data)
        } else {
            data.status = 'negotiated'
            await transactSubmit(ico.uuid, data)
        }

        this.setState({ confirmModalOpen: false, isActionBusy: false })
    }

    _handleCancelClick() {
        this.setState({ confirmModalOpen: false })
    }

    _openReviewFormModal() {
        this.reviewModal.current.open()
    }

    feeChange = debounce(async() => {
        const { ico } = this.props
        const { supply } = this.state

        if (supply) {
            const parsedPrice = parseFloat(ico.ico_price_token),
            parsedSupply = parseFloat(supply),
            total = parsedPrice * parsedSupply

            let totalPrice, fee, price

            if (ico.currency !== 'USDT') {
                const response = await fetch('https://api.coingecko.com/api/v3/simple/price?' + new URLSearchParams({
                    'ids': 'ethereum,bitcoin',
                    'vs_currencies': 'usd',
                    'x_cg_demo_api_key': process.env.REACT_APP_COINGECKO_DEMO_API_KEY
                }),
                {
                    headers: { "Content-Type": "application/json" }
                });
                const cryptoPrices = await response.json();
                switch (ico.currency) {
                    case 'BTC':
                        price = cryptoPrices.bitcoin.usd;
                        break;

                    case 'ETH':
                        price = cryptoPrices.ethereum.usd;
                        break;
                }

                totalPrice = price * total;
            } else {
                totalPrice = total
            }
            
            console.log(totalPrice)
            if (totalPrice > 1000) {
                fee = 0.02 * totalPrice
            } else {
                fee = 21
                if (!isNaN(totalPrice)) {
                    fee = 21
                }
            }

            this.setState({ fee: fee.toFixed(6), totalPrice })
        }
    }, 1000)

    _renderButtons = () => {
        const { notes, isReservation, supply, fee } = this.state
        const { ico, transaction, isActionBusy, isGuest } = this.props
    
        let buttonHtml

        if (transaction) { // there is a pending or previous transaction that has happened
            const { status } = transaction
            switch(status) {
                case 'completed':
                    return (
                        <div>
                            <a onClick={this._openHistoryModal} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                SHOW TRANSACTION HISTORY
                            </a>
                            <br />
                            <a onClick={this._openDetailsModal} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                SHOW TRANSACTION DETAILS
                            </a>
                            <Completed
                                transaction={transaction}
                                writeReviewClick={this._openReviewFormModal}
                            />
                            <Comment {...this.props} />
                        </div>
                    )
                case 'failed':
                    return <div>
                        <a onClick={this._openHistoryModal} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                            SHOW TRANSACTION HISTORY
                        </a>
                        <br />
                        <a onClick={this._openDetailsModal} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                            SHOW TRANSACTION DETAILS
                        </a>
                        <Denied transaction={transaction} />
                    </div>
                case 'reserved':
                case 'negotiated':
                    return <div>
                        <a onClick={this._openHistoryModal} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                            SHOW TRANSACTION HISTORY
                        </a>
                        <br />
                        <a onClick={this._openDetailsModal} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                            SHOW TRANSACTION DETAILS
                        </a>
                        <br />
                        <button className="btn btn-blue mb-4" disabled>
                            {status.toUpperCase()}
                        </button>
                        <Comment {...this.props} />
                    </div>
                case 'processed':
                    return (
                        <div>
                            <a onClick={this._openHistoryModal} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                SHOW TRANSACTION HISTORY
                            </a>
                            <br />
                            <a onClick={this._openDetailsModal} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                SHOW TRANSACTION DETAILS
                            </a>
                            <br />
                            <button className="btn btn-blue mb-4" disabled>
                                YOU GOT THIS
                            </button>
                            <Comment {...this.props} />
                        </div>
                    )
            }
        } else { // there is no pending or current transaction going on
            if (isGuest) {
                buttonHtml = <button
                    className="btn btn-blue transact"
                    onClick={this._openLoginModal}
                >
                    RESERVE
                </button>
            } else {
                if (isReservation) {
                    buttonHtml = <Button
                        extraClass="transact"
                        isLoading={isActionBusy}
                        type={"blue"}
                        label={"reserve"}
                        isCapitalize
                        onClick={this._openConfirmModal}
                    />
                } else {
                    buttonHtml = <Button
                        extraClass="transact"
                        isLoading={isActionBusy}
                        type={"blue"}
                        label={ico.type === 'buy' ? 'sell' : 'buy'}
                        isCapitalize
                        onClick={this._openConfirmModal}
                    />
                }
            }

            return <>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label>Number of tokens to {ico.type === 'buy' ? 'sell' : 'buy'}</label>
                            <Cleave
                                name="supply"
                                className="form-control"
                                options={{ numeral: true, numeralThousandsGroupStyle: 'thousand'}}
                                value={supply}
                                onChange={this.onChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="text-danger">Fee</label>
                            <input type="text" className="form-control" value={fee ? fee : 0} readOnly/>
                            <span data-tip data-for="fee-tip">
                                USDT
                            </span>
                            <ReactTooltip id="fee-tip" place="right" effect="solid">
                                Escrow fees are charged but in return we make sure that the transaction goes smoothly.
                            </ReactTooltip>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Textarea
                            label="Notes"
                            name="notes"
                            cols={40}
                            value={notes}
                            full
                            onChange={this.onChange}
                            placeholder="Write down any pertinent information about this allocation to ensure a smooth transaction"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {buttonHtml}
                    </div>
                </div>
            </>
        }
    }

    _openConnectWalletModal() {
        this.walletConnectModal.current.open()
    }

    _openHistoryModal = async() => {
        const { getTransactionHistory, ico, transaction } = this.props

        await getTransactionHistory(ico.uuid, transaction.uuid)
        this.historyModal.current.toggleOpen()
    }

    _openDetailsModal = () => {
        this.detailsModal.current.open()
    }

    _openForgotPasswordModal = () => {
      this.forgotPasswordModalRef.current.open();
    };
  
    _openLoginModal = () => {
      this.loginModalRef.current.open();
    };

    render() {
        const {
            auth,
            transaction,
            ico,
            handleSignup,
            authorize,
            user,
            logout,
            histories,
            createReview,
            refreshTransactions,
            login,
            me,
            fetchIcoDetails,
            getUserByPublicAddress,
            getAccountAssets,
            accountAssets,
            showFlashMessage,
        } = this.props
        
        const {
            confirmModalOpen,
            hasConnectedtoMetamask
        } = this.state

        return (
            <div className="col-lg-12">
                {this._renderButtons()}
                <ConfirmDialog
                    cancel={this._handleCancelClick}
                    confirm={this._handleConfirmClick}
                    isShow={confirmModalOpen}
                    message={"Do you want to proceed with the transaction?"}
                />
                {transaction && transaction.status === "completed" && (
                    <ReviewFormModal
                        ref={this.reviewModal}
                        ico={ico}
                        transaction={transaction}
                        createReview={createReview}
                        refreshTransactions={refreshTransactions}
                        fetchIcoDetails={fetchIcoDetails}
                    />
                )}
                <ConnectWalletModal
                    ref={this.walletConnectModal}
                    handleSignup={handleSignup}
                    getUserByPublicAddress={getUserByPublicAddress}
                    getAccountAssets={getAccountAssets}
                    accountAssets={accountAssets}
                    authorize={authorize}
                    user={user}
                    me={me}
                    hasConnectedtoMetamask={hasConnectedtoMetamask}
                    logout={logout}
                />
                <LoginModal
                  ref={this.loginModalRef}
                  login={login}
                  auth={auth}
                  openRegisterModal={this._openRegisterModal}
                  openForgotPasswordModal={this._openForgotPasswordModal}
                />
                <ForgotPasswordModal
                  showFlashMessage={showFlashMessage}
                  ref={this.forgotPasswordModalRef}
                />
                {transaction &&
                    <div>
                        <TransactionHistoryModal
                            ref={this.historyModal}
                            histories={histories}
                            transaction={transaction}
                        />
                        <DetailsModal
                            transaction={transaction}
                            ico={ico}
                            ref={this.detailsModal}
                        />
                    </div>
                }
            </div>
        )
    }
}