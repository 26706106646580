import { RSAA } from 'redux-api-middleware'

export const CREATE_COMMENT = 'auth:create_comment'
export const CREATE_COMMENT_SUCCESS = 'auth:create_comment_success'
export const CREATE_COMMENT_FAILURE = 'auth:create_comment_failure'
export const GET_TRANSACTION_COMMENTS = 'auth:get_transaction_comments'
export const GET_TRANSACTION_COMMENTS_SUCCESS = 'auth:get_transaction_comments_success'
export const GET_TRANSACTION_COMMENTS_FAILURE = 'auth:get_transaction_comments_failure'

export function createTransactionComment(uuid, transaction_id, data){
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/order/${uuid}/transaction/${transaction_id}/comments`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(data),
                types: [CREATE_COMMENT, CREATE_COMMENT_SUCCESS, CREATE_COMMENT_FAILURE]
            }
        })
    }
}

export function getTransactionComments(id, transactionId) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/order/${id}/transaction/${transactionId}/comments`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                types: [GET_TRANSACTION_COMMENTS, GET_TRANSACTION_COMMENTS_SUCCESS, GET_TRANSACTION_COMMENTS_FAILURE]
            }
        })
    }
}

const ACTION_HANDLERS = {
    [CREATE_COMMENT]: state => ({ 
        ...state, 
        createTransactionComment: true, 
        createTransactionCommentSuccess: false, 
        createTransactionCommentFailure: false 
    }),
    [CREATE_COMMENT_SUCCESS]: state => ({ 
        ...state, 
        createTransactionComment: false, 
        createTransactionCommentSuccess: true, 
        createTransactionCommentFailure: false 
    }),
    [CREATE_COMMENT_FAILURE]: state => ({ 
        ...state, 
        createTransactionComment: false, 
        createTransactionCommentSuccess: false, 
        createTransactionCommentFailure: true 
    }),
    [GET_TRANSACTION_COMMENTS]: state => ({
        ...state,
        getTransactionComments: true,
        getTransactionCommentsSuccess: false,
        getTransactionCommentsFailure: false
    }),
    [GET_TRANSACTION_COMMENTS_SUCCESS]: (state, action) => ({
        ...state,
        getTransactionComments: false,
        getTransactionCommentsSuccess: true,
        getTransactionCommentsFailure: false,
        transactionComments: action.payload.comments
    }),
    [GET_TRANSACTION_COMMENTS_FAILURE]: state => ({
        ...state,
        getTransactionComments: false,
        getTransactionCommentsSuccess: false,
        getTransactionCommentsFailure: true
    })
}

const initialState = {
    createTransactionComment: false,
    createTransactionCommentSuccess: false,
    createTransactionCommentFailure: false,
    getTransactionComments: false,
    getTransactionCommentsSuccess: false,
    getTransactionCommentsFailure: false,
    transactionComments: null
}

export default function comments (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
