import React, { Component } from "react";
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types';

export default class Search extends Component {
    render() {

        return (
            <div className="text-center">
                <span className="search-input home">
                    <h1>Safe and free peer to peer digital assets marketplace</h1>
                    {/* <input className="text-center" placeholder="Search for a token..." onChange={this.props.search} />
                     */}
                    <div className="w-50 mx-auto my-5">
                        <NavLink
                            to="/search"
                            className="nav-link btn btn-blue text-center d-md-block ml-4 w-50 mx-auto"
                        >
                            SEARCH
                        </NavLink>
                    </div>
                </span>
            </div>
        )
    }
}

Search.propTypes = {
    search: PropTypes.func.isRequired,
};
