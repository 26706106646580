import React, { Component } from 'react'
import WalletForm from './WalletForm'
import ResetPasswordForm from './ResetPasswordForm'
import Tabs from '../common/Tabs'

class Settings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected_form: 'wallet',
        }
    }

    _handleClickFormChange = (selected_form) => {
        this.setState({ selected_form })
    }

    onSubmitChangePassword = async (data) => {
        const { changePassword, me, showFlashMessage } = this.props
        const { error: err, payload } = await changePassword(data)
        if (err) {
            const { response: { error } } = payload
            let errorMessage = []
            error.forEach(_err => {
                errorMessage.push(_err)
            })
            showFlashMessage(errorMessage.join('\n'), 'error')
        } else {
            const { message } = payload
            showFlashMessage(message)
            me()
        }
        const alert = document.querySelector('#alert-container')
        alert.scrollIntoView({ behavior: 'smooth' })
    }

    render() {
        const { selected_form } = this.state
        const { password, myself } = this.props
        const tabs = [
            { display_name: 'Crypto Wallet', name: 'wallet' }
        ]

        return (
            <div className="content-container settings-container">
                <div className="row">
                    <div className="col-12">
                        <h2 className="main-title">Settings</h2>
                    </div>
                </div>
                <div className="row justify-content-center justify-content-md-start">
                    <div className="col-11 col-md-9 col-lg-7 ml-0 ml-md-3 ml-lg-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="header-with-icon">
                                    <img
                                        className="icon"
                                        src="/login-lock.svg"
                                        alt="padlock"
                                    />
                                    <span className="title">Password</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="tabs-wrapper">
                                    <h4>Change Password</h4>
                                    <p>
                                        To change your password you must enter
                                        your current password below
                                    </p>
                                </div>
                                <div className="inner-block-body">
                                    <ResetPasswordForm
                                        onSubmit={this.onSubmitChangePassword}
                                        prop={password}
                                        myself={myself}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Settings
