/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
class Notification extends Component {

    _isListening = false

    constructor() {
        super()

        this._renderNotifications = this._renderNotifications.bind(this)
        this._onClickHandler = this._onClickHandler.bind(this)
    }

    _renderNotifications(notification, index) {
        const {
            category,
            id,
            ico,
            read_at,
            data,
            transaction_id
        } = notification

        let icon, message = ico.type === 'buy' ? `Buying ${ico.name}: ` : `Selling ${ico.name}: `

        if (category === 1) {
            icon = 'comment'
            message += 'You have new message.'
        } else if (category === 2) {
            icon = 'comments'
            message += 'You have one reply.'
        } else if (category === 3) {
            icon = 'thumbtack'
            message += data.message
        } else if (category === 4) {
            icon = 'thumbtack'
            message += data.message
        } else if (category === 5) {
            icon = 'check'
            message += data.message
        } else if (category === 6) {
            icon = 'handshake'
            message += data.message
        } else if (category === 7) {
            icon = 'times-circle'
            message += data.message
        } else if (category === 8) {
            icon = 'edit'
            message += data.message
        }

        if (index <= 4) {
            return (
                <a
                    className="dropdown-item"
                    key={index}
                    onClick={() => this._onClickHandler(
                        read_at,
                        id,
                        ico,
                        category,
                        transaction_id
                    )}
                    style={{background: !read_at ? 'grey': 'none'}}
                >
                    <FontAwesomeIcon icon={icon} className="text-primary mr-2" />
                    {message}
                </a>
            )
        } else {
            return ''
        }
    }

    _onClickHandler = (readAt, id, ico, category, transactionId) => {
        const { markAsRead } = this.props
        const { user: { full_name }, type, name, uuid } = ico
        if (!readAt) {
            markAsRead(id)
        }
        // window.location.replace(`/order/${uuid}`)
        if (category === 8) {
            this.props.history.push(`/${full_name}/${type}/${name}/${uuid}`, { openDetails: true })
        } else if (category === 1 || category === 2) {
            this.props.history.push(`/${full_name}/${type}/${name}/${uuid}`, { transactionId })
        } else {
            this.props.history.push(`/${full_name}/${type}/${name}/${uuid}`)
        }
    }

    _handleOpenNotifModal = () => {
        const { openNotificationModal } = this.props
        openNotificationModal()
    }

    render() {
        const { auth, notifications: { unread, all } } = this.props

        if (!auth.myself) {
            return false
        }

        const { myself } = auth
        if (myself) {
            if (myself.id !== undefined && !this._isListening) {
                this._isListening = true
                window.broadcaster.subscribeToUserNotifcation(myself.id)
            }
        }
        
        return (
            <div className="dropdown notification mr-3">
                <button
                    className="btn btn-default dropdown-toggle btn-width-auto"
                    type="button"
                    data-toggle="dropdown"
                >
                    <FontAwesomeIcon icon="bell" size="lg" />
                    {unread.length > 0 ? (
                        <span className="badge badge-pill badge-warning">
                            {unread.length}
                        </span>
                    ) : (
                        ""
                    )}
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                    {all.length === 0 ? (
                        <a className="dropdown-item">Nothing here.</a>
                    ) : (
                        all.map(this._renderNotifications)
                    )}
                    <div className="dropdown-divider"></div>
                    {all.length > 0 ? (
                        <a
                            className="dropdown-item text-center"
                            onClick={this._handleOpenNotifModal}
                        >
                            Show All
                        </a>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        )
    }
}

Notification.propTypes = {
    auth: PropTypes.object.isRequired,
}

export default withRouter(Notification)