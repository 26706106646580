import MiniApp from '../../components/MiniApp/MiniApp';
import { connect } from 'react-redux';

const mapActionCreators = {
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, mapActionCreators)(MiniApp);
