import { RSAA } from 'redux-api-middleware'

export const GET_PROFILE = 'auth:get_profile'
export const GET_PROFILE_SUCCESS = 'auth:get_profile_success'
export const GET_PROFILE_FAILURE = 'auth:get_profile_failure'
export const UPDATE_PROFILE = 'auth:update_profile'
export const UPDATE_PROFILE_SUCCESS = 'auth:update_profile_success'
export const UPDATE_PROFILE_FAILURE = 'auth:update_profile_failure'
export const CONNECT_WALLET = 'auth:connect_wallet'
export const CONNECT_WALLET_SUCCESS = 'auth:connect_wallet_success'
export const CONNECT_WALLET_FAILURE = 'auth:connect_wallet_failure'
export const UPLOAD_PHOTO = 'auth:upload_photo'
export const UPLOAD_PHOTO_SUCCESS = 'auth:upload_photo_success'
export const UPLOAD_PHOTO_FAILURE = 'auth:upload_photo_failure'
export const CHANGE_PASSWORD = 'auth:change_password'
export const CHANGE_PASSWORD_SUCCESS = 'auth:change_password_success'
export const CHANGE_PASSWORD_FAILURE = 'auth:change_password_failure'
export const RECENT_USERS = 'auth:recent_users'
export const RECENT_USERS_SUCCESS = 'auth:recent_users_success'
export const RECENT_USERS_FAILURE = 'auth:recent_users_failure'
export const ALL_USERS = 'auth:all_users'
export const ALL_USERS_SUCCESS = 'auth:all_users_success'
export const ALL_USERS_FAILURE = 'auth:all_users_failure'
export const BANNED_DISBANNED_USER = 'auth::banned_disbanned_user'
export const BANNED_DISBANNED_USER_SUCCESS = 'auth::banned_disbanned_user_success'
export const BANNED_DISBANNED_USER_FAILURE = 'auth::banned_disbanned_user_failure'
export const GET_USER_INTERACTIONS = 'auth:get_user_interactions'
export const GET_USER_INTERACTIONS_SUCCESS = 'auth:get_user_interactions_success'
export const GET_USER_INTERACTIONS_FAILURE = 'auth:get_user_interactions_failure'
export const GET_MY_STATS = 'auth:get_my_stats'
export const GET_MY_STATS_SUCCESS = 'auth:get_my_stats_success'
export const GET_MY_STATS_FAILURE = 'auth:get_my_stats_failure'
export const GET_OWNER_STATS = 'auth:get_owner_stats'
export const GET_OWNER_STATS_SUCCESS = 'auth:get_owner_stats_success'
export const GET_OWNER_STATS_FAILURE = 'auth:get_owner_stats_failure'
export const GET_TRUST_SCORES = 'auth:get_trust_scores'
export const GET_TRUST_SCORES_SUCCESS = 'auth:get_trust_scores_success'
export const GET_TRUST_SCORES_FAILURE = 'auth:get_trust_scores_failure'
export const LINK_TELEGRAM = 'auth:link_telegram'
export const LINK_TELEGRAM_SUCCESS = 'auth:link_telegram_success'
export const LINK_TELEGRAM_FAILURE = 'auth:link_telegram_failure'
export const UNLINK_TELEGRAM = 'auth:unlink_telegram'
export const UNLINK_TELEGRAM_SUCCESS = 'auth:unlink_telegram_success'
export const UNLINK_TELEGRAM_FAILURE = 'auth:unlink_telegram_failure'

export function getProfile(username) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/profile/${username}`,
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Accept": "application/json charset=UTF-8",
                },
                types: [GET_PROFILE, GET_PROFILE_SUCCESS, GET_PROFILE_FAILURE]
            }
        })
    }
}

export function getUser(id) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/user/${id}`,
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Accept": "application/json charset=UTF-8",
                },
                types: [GET_PROFILE, GET_PROFILE_SUCCESS, GET_PROFILE_FAILURE]
            }
        })
    }
}

export function update(data) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/me`,
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json charset=UTF-8",
                },
                body: JSON.stringify(data),
                types: [UPDATE_PROFILE, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE]
            }
        })
    }
}

export function connectWallet(data) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/me/wallet`,
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data),
                types: [CONNECT_WALLET, CONNECT_WALLET_SUCCESS, CONNECT_WALLET_FAILURE]
            }
        })
    }
}

export function uploadPhoto(photo) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/me/photo`,
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body: photo,
                types: [UPLOAD_PHOTO, UPLOAD_PHOTO_SUCCESS, UPLOAD_PHOTO_FAILURE]
            }
        })
    }
}

export function changePassword(data) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/me/change-password`,
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data),
                types: [CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE]
            }
        })
    }
}

export function getRecentUsers() {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/me/recent-users`,
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Accept": "application/json charset=UTF-8",
                },
                types: [RECENT_USERS, RECENT_USERS_SUCCESS, RECENT_USERS_FAILURE]
            }
        })
    }
}

export function getAllUsers() {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/me/all-users`,
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Accept": "application/json charset=UTF-8",
                },
                types: [ALL_USERS, ALL_USERS_SUCCESS, ALL_USERS_FAILURE]
            }
        })
    }
}

export function searchRecentUsers(name) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/me/recent-users/${name}`,
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Accept": "application/json charset=UTF-8",
                },
                types: [RECENT_USERS, RECENT_USERS_SUCCESS, RECENT_USERS_FAILURE]
            }
        })
    }
}

export function toggleUserBannedOrDisbanned(id) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/member/${id}/toggle/banned`,
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Accept": "application/json charset=UTF-8",
                    "Content-Type": "application/json charset=UTF-8",
                },
                types: [BANNED_DISBANNED_USER, BANNED_DISBANNED_USER_SUCCESS, BANNED_DISBANNED_USER_FAILURE]
            }
        })
    }
}

export function getUserInteractions(tab, filter) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        let endpoint = `${process.env.REACT_APP_API_HOST}/me/dashboard?tab=${tab}`

        if (filter) {
            endpoint += `&filter=${filter}`
        }

        return dispatch({
            [RSAA]: {
                endpoint,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json charset=UTF-8',
                },
                types: [GET_USER_INTERACTIONS, GET_USER_INTERACTIONS_SUCCESS, GET_USER_INTERACTIONS_FAILURE]
            }
        })
    }
}

export function getMyStats() {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/user/stats`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json charset=UTF-8',
                },
                types: [GET_MY_STATS, GET_MY_STATS_SUCCESS, GET_MY_STATS_FAILURE]
            }
        })
    }
}

export function getOwnerStats(uuid) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/user/stats?id=${uuid}`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json charset=UTF-8',
                },
                types: [GET_OWNER_STATS, GET_OWNER_STATS_SUCCESS, GET_OWNER_STATS_FAILURE]
            }
        })
    }
}

export function getTrustScores(page = '') {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        let endpoint

        if (page) {
            endpoint = `${process.env.REACT_APP_API_HOST}/user/trust/rank?page=${page}`
        } else {
            endpoint = `${process.env.REACT_APP_API_HOST}/user/trust/rank`
        }

        return dispatch({
            [RSAA]: {
                endpoint,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json charset=UTF-8',
                },
                types: [GET_TRUST_SCORES, GET_TRUST_SCORES_SUCCESS, GET_TRUST_SCORES_FAILURE]
            }
        })
    }
}

export function linkTelegram(data) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        window.http.setToken(token);
        
        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/auth/telegram/callback${data}`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [LINK_TELEGRAM, LINK_TELEGRAM_SUCCESS, LINK_TELEGRAM_FAILURE]
            }
        })
    }
}

export function unlinkTelegram() {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        window.http.setToken(token);
        
        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/me/unlink/telegram`,
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [UNLINK_TELEGRAM, UNLINK_TELEGRAM_SUCCESS, UNLINK_TELEGRAM_FAILURE]
            }
        })
    }
}

const ACTION_HANDLERS = {
    [GET_PROFILE]: state => ({
        ...state,
        gettingProfile: true,
        gettingProfileSuccess: false
    }),
    [GET_PROFILE_SUCCESS]: (state, action) => ({
        ...state,
        gettingProfile: false,
        gettingProfileSuccess: true,
        user: action.payload.user
    }),
    [GET_PROFILE_FAILURE]: state => ({
        ...state,
        gettingProfile: false,
        gettingProfileSuccess: false
    }),
    [UPDATE_PROFILE]: state => ({
        ...state,
        updatingProfile: true,
        updatingProfileSuccess: false
    }),
    [UPDATE_PROFILE_SUCCESS]: state => ({
        ...state,
        updatingProfile: false,
        updatingProfileSuccess: true,
        updatingProfileError: false
    }),
    [UPDATE_PROFILE_FAILURE]: state => ({
        ...state,
        updatingProfile: false,
        updatingProfileSuccess: false,
        updatingProfileError: true
    }),
    [CONNECT_WALLET]: state => ({
        ...state,
        connectingWallet: true,
        connectingWalletSuccess: false
    }),
    [CONNECT_WALLET_SUCCESS]: state => ({
        ...state,
        connectingWallet: false,
        connectingWalletSuccess: true,
        connectingWalletError: false
    }),
    [CONNECT_WALLET_FAILURE]: state => ({
        ...state,
        connectingWallet: false,
        connectingWalletSuccess: false,
        connectingWalletError: true
    }),
    [UPLOAD_PHOTO]: state => ({
        ...state,
        photo: { 
            processing: true,
            success: false,
            error: false
        }
    }),
    [UPLOAD_PHOTO_SUCCESS]: (state, action) => ({
        ...state,
        photo: {
            processing :false,
            success: true,
            error: false,
            uploadedPhoto: action.payload.photo
        }
    }),
    [UPLOAD_PHOTO_FAILURE]: state => ({
        ...state,
        photo: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [CHANGE_PASSWORD]: state => ({
        ...state,
        password: {
            processing: true,
            success: false,
            error: false
        }
    }),
    [CHANGE_PASSWORD_SUCCESS]: state => ({
        ...state,
        password: {
            processing: false,
            success: true,
            error: false
        }
    }),
    [CHANGE_PASSWORD_FAILURE]: state => ({
        ...state,
        password: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [RECENT_USERS]: state => ({
        ...state,
        recent_users: {
            processing: true,
            success: false,
            error: false
        }
    }),
    [RECENT_USERS_SUCCESS]: (state, action) => ({
        ...state,
        recent_users: {
            processing: false,
            success: true,
            error: false,
            users: action.payload.recent_users
        }
    }),
    [RECENT_USERS_FAILURE]: state => ({
        ...state,
        recent_users: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [ALL_USERS]: state => ({
        ...state,
        all_users: {
            processing: true,
            success: false,
            error: false
        }
    }),
    [ALL_USERS_SUCCESS]: (state, action) => ({
        ...state,
        all_users: {
            processing: false,
            success: true,
            error: false,
            users: action.payload.all_users
        }
    }),
    [ALL_USERS_FAILURE]: state => ({
        ...state,
        all_users: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [BANNED_DISBANNED_USER]: state => ({
        ...state,
        all_users: {
            processing: true,
            success: false,
            error: false
        }
    }),
    [BANNED_DISBANNED_USER_SUCCESS]: (state, action) => ({
        ...state,
        all_users: {
            processing: false,
            success: true,
            error: false,
            users: action.payload.all_users
        }
    }),
    [BANNED_DISBANNED_USER_FAILURE]: state => ({
        ...state,
        all_users: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [GET_USER_INTERACTIONS]: state => ({
        ...state,
        getInteractions: {
            processing: true,
            success: false,
            error: false
        }
    }),
    [GET_USER_INTERACTIONS_SUCCESS]: (state, action) => ({
        ...state,
        getInteractions: {
            processing: false,
            success: true,
            error: false
        },
        dashboardData: action.payload.results
    }),
    [GET_USER_INTERACTIONS_FAILURE]: state => ({
        ...state,
        getInteractions: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [GET_MY_STATS]: state => ({
        ...state,
        getMyStats: {
            processing: true,
            success: false,
            error: false
        }
    }),
    [GET_MY_STATS_SUCCESS]: (state, action) => ({
        ...state,
        getMyStats: {
            processing: false,
            success: true,
            error: false
        },
        stats: action.payload.stats
    }),
    [GET_MY_STATS_FAILURE]: state => ({
        ...state,
        getMyStats: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [GET_OWNER_STATS]: state => ({
        ...state,
        getOwnerStats: {
            processing: true,
            success: false,
            error: false
        }
    }),
    [GET_OWNER_STATS_SUCCESS]: (state, action) => ({
        ...state,
        getOwnerStats: {
            processing: false,
            success: true,
            error: false
        },
        stats: action.payload.stats
    }),
    [GET_OWNER_STATS_FAILURE]: state => ({
        ...state,
        getOwnerStats: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [GET_TRUST_SCORES]: state => ({
        ...state,
        getTrustScores: {
            processing: true,
            success: false,
            error: false
        }
    }),
    [GET_TRUST_SCORES_SUCCESS]: (state, action) => ({
        ...state,
        getTrustScores: {
            processing: false,
            success: true,
            error: false
        },
        rankings: action.payload.rankings
    }),
    [GET_TRUST_SCORES_FAILURE]: state => ({
        ...state,
        getTrustScores: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [LINK_TELEGRAM]: state => ({
        ...state,
        isLinkingTelegram: true,
        linkTelegramSuccess: false,
        linkTelegramError: false
    }),
    [LINK_TELEGRAM_SUCCESS]: (state, action) => ({
        ...state,
        isLinkingTelegram: false,
        linkTelegramSuccess: true,
        linkTelegramError: false,
    }),
    [LINK_TELEGRAM_FAILURE]: (state, action) => ({
        ...state,
        isLinkingTelegram: false,
        linkTelegramSuccess: false,
        linkTelegramError: true,
        linkTelegramErrorMessage: action.payload.response.error,
    }),
    [UNLINK_TELEGRAM]: state => ({
        ...state,
        isUnlinkingTelegram: true,
        unlinkTelegramSuccess: false,
        unlinkTelegramError: false
    }),
    [UNLINK_TELEGRAM_SUCCESS]: (state, action) => ({
        ...state,
        isUnlinkingTelegram: false,
        unlinkTelegramSuccess: true,
        unlinkTelegramError: false,
    }),
    [UNLINK_TELEGRAM_FAILURE]: (state, action) => ({
        ...state,
        isUnlinkingTelegram: false,
        unlinkTelegramSuccess: false,
        unlinkTelegramError: true,
        unlinkTelegramErrorMessage: action.payload.response.error,
    }),
}

const initialState = {
    gettingProfile: false,
    gettingProfileSuccess: false,
    user: null,

    updatingProfile: false,
    updatingProfileSuccess: false,
    updatingProfileError: false,

    connectingWallet: false,
    connectingWalletSuccess: false,
    connectingWalletError: false,

    dashboardData: null,
    stats: null,
    rankings: null,

    photo: {
        processing: false,
        success: false,
        error: false,
        uploadedPhoto: null,
    },

    password: {
        processing: false,
        success: false,
        error: false,
    },

    recent_users: {
        processing: false,
        success: false,
        error: false,
        users: null
    },

    all_users: {
        processing: false,
        success: false,
        error: false,
        users: null
    },

    getInteractions: {
        processing: false,
        success: false,
        error: false
    },

    getMyStats: {
        processing: false,
        success: false,
        error: false
    },

    getOwnerStats: {
        processing: false,
        success: false,
        error: false
    },

    getTrustScores: {
        processing: false,
        success: false,
        error: false
    },

    isLinkingTelegram: false,
    linkTelegramSuccess: false,
    linkTelegramError: false,
    linkTelegramErrorMessage: null,

    isUnlinkingTelegram: false,
    unlinkTelegramSuccess: false,
    unlinkTelegramError: false,
    unlinkTelegramErrorMessage: null,
}

export default function users (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}
