import React from 'react'
import moment from 'moment'
import ReactTable from 'react-table'
import PropTypes from 'prop-types'
import BaseModal from '../../common/BaseModal'

export default class TransactionHistoryModal extends BaseModal {

    constructor(props) {
        super(props)
    }
    toggleOpen = async () => {
        await this.setSettings({
            className: 'transaction-history-modal',
            dialogClassName: 'modal-dialog-centered',
            onClickBackdropClose: true,
            showModalClose: true,
        })
        this.open()
    }

    renderHeader = () => {
        return <h3 className="mb-0">My Transaction History</h3>
    }

    renderBody = () => {
        const { histories } = this.props
        const columns = [
            {
                id: 'user',
                Header: 'Initiated By',
                accessor: a => <span className="text-uppercase font-weight-bold">{ a.user.full_name }</span>
            },
            {
                id: 'date_created',
                Header: 'Date',
                accessor: a => <span className="text-uppercase font-weight-bold">{moment(a.created_at).format("MMMM D, YYYY")}</span>
            },
            {
                id: 'previous_status',
                Header: 'Previous Status',
                accessor: a => <span className="text-uppercase font-weight-bold">{ a.previous_status }</span>
            },
            {
                id: 'current_status',
                Header: 'Current Status',
                accessor: a => <span className="text-uppercase font-weight-bold">{ a.next_status }</span>
            },
        ]
        return <div className="chat">
                <div className="window">
                    <div className="box-list-data">
                        {
                            histories ? 
                                <ReactTable
                                    className="text-center"
                                    columns={columns}
                                    data={histories}
                                    pageSize={10}
                                    useRowSelect
                                    minRows={3}
                                    manual
                                    showPageJump={false}
                                    showPageSizeOptions={false}
                                    showPaginationBottom={false}
                                />
                            :
                                <p>No Transaction History Found!</p> 
                        }
                    </div>
                </div>
            </div>
    }
    
}
TransactionHistoryModal.defaultProps = {
    histories: [0],
    transaction: [0]
} 