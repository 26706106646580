import React, { Component } from 'react'
import Cleave from 'cleave.js/react'
import { Redirect } from 'react-router-dom'
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Input from '../common/Input'
import Textarea from '../common/Textarea'
import OrderType from '../common/OrderType'
import Currency from '../common/Currency'
import Category from '../common/Category'

export default class Instagram extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            type: 'sell',
            followers: '',
            price: '',
            notes: '',
            category: 'gaming',
            currency: 'ETH',
            posts: '',
            average_likes: '',
            engagement_rate: '',
            average_comments: '',
            isLoading: false,
            isSuccessful: false
        }

        this._handleTypeClick = this._handleTypeClick.bind(this)
        this._handleCurrencyClick = this._handleCurrencyClick.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    _handleTypeClick(type) {
        this.setState({ type })
    }

    _handleCurrencyClick(currency) {
        this.setState({ currency })
    }

    onChange(e) {
        const name = e.target.name
        const value = name === 'followers' || name === 'price' ? e.target.rawValue : e.target.value

        this.setState({
            [name]: value
        })
    }

    onSubmit() {

    }

    render() {
        const {
            username,
            type,
            followers,
            price,
            currency,
            notes,
            category,
            isLoading,
            posts,
            average_likes,
            engagement_rate,
            average_comments,
            isSuccessful
        } = this.state

        if (isSuccessful) return <Redirect push to="/"/>

        return (
            <div id="new-assets" className="row session-block">
                <div className="col-12 p-0">
                    <div className="row form-header">
                        <div className="logo p-0">
                            <img src="/new-ico-order.svg" width="75px" alt="ICO Order"/>
                        </div>
                        <div className="col-lg-9 p-0">
                            <h3>Post Instagram Account Order</h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 p-0">
                    <div className="col-lg-10 p-0">
                        <div className="form-block">
                            <OrderType clickHandler={this._handleTypeClick} type={type} />

                            {type === 'sell' &&
                                <div>
                                    <div className="inner-block">
                                        <Input
                                            label="Username"
                                            name="name"
                                            value={username}
                                            placeholder="Your IG Username"
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <h4 class="session-title">
                                        Get your statistics <a href="https://www.tanke.fr/en/instagram-engagement-rate-calculator-2/" target="_blank">here</a> to make your account attractive to potential buyers
                                        or you could leave them blank for now.
                                    </h4>
                                    <div className="inner-block">
                                        <Input
                                            type="number"
                                            label="Total Posts"
                                            name="posts"
                                            value={posts}
                                            onChange={this.onChange}
                                        />
                                    </div>

                                    <div className="inner-block">
                                        <Input
                                            type="number"
                                            label="Average Likes"
                                            name="average_likes"
                                            value={average_likes}
                                            onChange={this.onChange}
                                        />
                                    </div>

                                    <div className="inner-block">
                                        <div className="input-group">
                                            <label>Engagement Rate</label>
                                            <input
                                                name="engagement_rate"
                                                type="number"
                                                className="form-control"
                                                value={engagement_rate}
                                                onChange={this.onChange}
                                                max="99"
                                            />
                                            <div className="input-group-append">
                                                <div className="input-group-text">%</div>
                                            </div>
                                        </div>
                                        <FontAwesomeIcon icon="question-circle" data-tip="If the calculator returns NaN you can leave this field blank." />
                                        <ReactTooltip place="right" />
                                    </div>

                                    <div className="inner-block">
                                        <Input
                                            type="number"
                                            label="Average Comments"
                                            name="average_comments"
                                            value={average_comments}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 p-0 right-col">
                    <div className="col-lg-9 p-0">
                        <div className="form-block">
                            <Currency clickHandler={this._handleCurrencyClick} currency={currency} />
                            <div className="input-block">
                                <div className="form-group mb-3">
                                    <label>Followers</label>
                                    <Cleave
                                        name="followers"
                                        className="form-control"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'thousand'}}
                                        value={followers}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>

                            <div className="input-block">
                                <div className="input-group mb-3">
                                    <label>Price</label>
                                    <Cleave
                                        name="price"
                                        className="form-control"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'thousand'}}
                                        value={price}
                                        onChange={this.onChange}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">{currency}</span>
                                    </div>
                                </div>
                            </div>

                            <Category category={category} changeHandler={this.onChange} />
                            <div className="input-block">
                                <Textarea
                                    label="Notes" 
                                    name="notes"
                                    value={notes}
                                    onChange={this.onChange}
                                    placeholder={type === 'buy' ? 'Describe the kind of instagram account that you want to buy' : 'Describe the instagram account that you are planning to sell'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 footer">
                    <hr/>
                    <button className="btn btn-blue" disabled={isLoading} onClick={this.onSubmit}>{type.toUpperCase()} ACCOUNT</button>
                </div>
            </div>
        )
    }
}