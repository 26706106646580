import React from 'react'
import BaseModal from '../../common/BaseModal'
import { Redirect } from 'react-router-dom';
import { NavLink } from 'react-router-dom'

export default class CreateOrderModal extends BaseModal {
    constructor(props) {
        super(props)

        this.state = {
            assetSelected: '',
            redirectLink: '',
        };
    }

    toggleOpen = async () => {
        await this.setSettings({
            className: 'create-order-modal',
            dialogClassName: 'modal-dialog-centered',
            onClickBackdropClose: true,
            showModalClose: true,
        })
        this.open()
    }

    _close = () => {
        this.close()
    }

    _openListedTokenModal = async () => {
        await this._close()
        this.props.modalRef.current.toggleOpen()
    }

    renderHeader = () => {
        return (
            <div>
                <h2 className="create-order-modal__title">Create Order</h2>
                <p className="text-gray-secondary">
                    Select the type of token below
                </p>
            </div>
        )
    }

    assetSelection = (el) => {
        this.setState({assetSelected: el.target.value})
    }

    proceedStep = () => {
        console.log('hit', this.state.assetSelected);
        if (this.state.assetSelected === 'liquid_asset') {
            this._openListedTokenModal();
        }

        if (this.state.assetSelected === 'future_asset') {
            this.setState({redirectLink: '/order/new'});
        }
    }

    renderBody = () => {
        const { assetSelected, redirectLink } = this.state;
        if(redirectLink) {
            return <Redirect to={redirectLink} />;
        }
        return (
            <div className="row justify-content-around align-items-start mt-5 ">
                <div className="col-md-6 col-12 mb-md-0 mb-5">
                    <div className="create-order-modal__container">
                        <div className="create-order-modal__item-meta">
                            <input className="create-order-modal__item-radio" type="radio" value={'liquid_asset'} onChange={this.assetSelection} checked={assetSelected === 'liquid_asset'} />
                            <div className="create-order-modal__item-content">
                                <h3 className="create-order-modal__item-title">
                                    Liquid Asset
                                </h3>
                                <p className="create-order-modal__item-description text-gray-secondary">
                                    Tokens or coins that can be transferred
                                </p>
                            </div>
                            {/* <a
                                onClick={this._openListedTokenModal}
                                className="create-order-modal__item-btn btn btn-blue text-white"
                            >
                                SELECT
                            </a> */}
                            
                        </div>
                        <img
                            src="/listed.svg"
                            alt="Liquid Asset"
                            className="w-100"
                        />
                    </div>
                </div>
                <div className="col-md-6 col-12">
                    <div className="create-order-modal__container">
                        <div className="create-order-modal__item-meta">
                            <input className="create-order-modal__item-radio" type="radio" value={'future_asset'} onChange={this.assetSelection} checked={assetSelected === 'future_asset'} />
                            
                            <div className="create-order-modal__item-content">
                                <h3 className="create-order-modal__item-title">
                                    Future Asset
                                </h3>
                                <p className="create-order-modal__item-description text-gray-secondary">
                                    Tokens that are still to come via SAFT or upcoming TGE
                                </p>
                            </div>
                            {/* <NavLink
                                to="/order/new"
                                className="create-order-modal__item-btn btn btn-blue text-white"
                            >
                                SELECT
                            </NavLink> */}
                        </div>
                        <img src="/listing.svg" alt="ICO Token" className="w-100" />
                    </div>
                </div>
                <div className="d-flex col-12 align-items-start justify-content-end mt-5">
                    <button className="create-order-modal__item-btn btn btn-blue text-white" onClick={this.proceedStep}>CONTINUE</button>
                </div>
            </div>
        )
    }
}
