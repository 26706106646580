import React, { Component } from 'react'
import Modal from 'react-bootstrap4-modal'
import cx from 'classnames'

class BaseModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false,
            className: '',
            dialogClassName: '',
            onClickBackdropClose: false,
            showModalClose: false,
        }
    }

    open = () => {
        this.setState({ show: true })
    }

    close = () => {
        this.setState({ show: false })
    }

    setClass = (className) => {
        this.setSettings({ className })
    }

    setDialogClass = (dialogClassName) => {
        this.setSettings({ dialogClassName })
    }

    setSettings = (settings) => {
        const prevState = this.state
        this.setState({ ...prevState, ...settings })
    }

    onClickBackdropClose = (closeModal) => {
        closeModal && this.close()
    }

    renderHeader = () => {
    }

    renderBody = () => {
    }

    renderFooter = () => {
    }

    render() {
        const {
            show,
            className,
            dialogClassName,
            onClickBackdropClose,
            showModalClose,
        } = this.state
        
        return (
            <Modal
                className={cx(className)}
                dialogClassName={cx(dialogClassName)}
                visible={show}
                onClickBackdrop={() => this.onClickBackdropClose(onClickBackdropClose)}
            >
                {showModalClose && (
                    <a className="modal-close" onClick={this.close}>
                        <img src="/modal-close.svg" alt="Modal Close" />
                    </a>
                )}
                <div className="modal-header col-lg-12">
                    <div className="modal-title col-lg-12">{this.renderHeader()}</div>
                </div>
                <div className="modal-body col-lg-12">{this.renderBody()}</div>
                <div className="modal-footer col-lg-12">{this.renderFooter()}</div>
            </Modal>
        )
    }
}

export default BaseModal
