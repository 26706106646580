import EditProfile from '../../components/MyAccount/EditProfile'
import { connect } from 'react-redux'
import { update, uploadPhoto, changePassword, unlinkTelegram } from '../../actions/users'
import { me } from '../../actions/auth'

const mapActionCreators = {
    update,
    uploadPhoto,
    me,
    changePassword,
    unlinkTelegram
}

const mapStateToProps = (state) => ({
    photo: state.users.photo,
    users: state.users,
    myself: state.auth.myself,
    isUnlinkingTelegram: state.users.isUnlinkingTelegram,
    unlinkTelegramSuccess: state.users.unlinkTelegramSuccess,
    unlinkTelegramError: state.users.unlinkTelegramError,
    unlinkTelegramErrorMessage: state.users.unlinkTelegramErrorMessage,
})

export default connect(mapStateToProps, mapActionCreators)(EditProfile)
