import React, { Component } from 'react';
import { isNil } from 'lodash';
import Details from './Details';
import Loading from '../common/Loading';
import Manager from './Seller/Manager';
import Info from './Seller/Info';
import Closed from './Closed';

export default class Page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ico: null,
      isLoading: true,
    };

    this.checkThisUserIsTheOwner = this.checkThisUserIsTheOwner.bind(this);
  }

  async componentWillMount() {
    const {
      fetchIcoDetails,
      match: { params },
      me,
    } = this.props;
    me();
    if (params.id) {
      await fetchIcoDetails(params.id);
      await this.checkThisUserIsTheOwner();
      this.setState({ isLoading: false });
    }
  }

  async componentDidUpdate(prevProps) {
    const prevParams = prevProps.match.params;
    const {
      me,
      match: { params },
      fetchIcoDetails,
    } = this.props;

    if (prevParams.id !== params.id) {
      me();
      await fetchIcoDetails(params.id);
      await this.checkThisUserIsTheOwner();
    }
  }

  async checkThisUserIsTheOwner() {
    const {
      match: { params },
      checkIsOwner,
    } = this.props;
    await checkIsOwner(params.id);
  }

  componentWillUnmount() {
    this.setState({
      isLoading: true,
    });
  }

  _renderDisabledOrder(hideExtraDetails = false) {
    return (
      <div id="details" className="col-12">
        <div className="row">
          <div className="col-lg-12">
            <div className="jumbotron bg-gradient-warning">
              <h1 className="display-4">
                {`This order has been disabled${
                  hideExtraDetails ? '' : 'by the owner'
                }.`}
              </h1>
              <p className="lead"></p>
              <hr className="my-4" />
              {hideExtraDetails ? null : (
                <p>Contact administrator if something is wrong.</p>
              )}
              {hideExtraDetails ? null : (
                <a className="btn btn-primary btn-lg" href="# " role="button">
                  Contact Us
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      showFlashMessage,
      isGuest,
      icoDetails: { processing, error, data, feedbacks },
      myself,
      isOwner,
      getOwnerStats,
      stats,
    } = this.props;

    let details,
      orderIsDisabled = false;

    if (processing || error) {
      orderIsDisabled = true;
    }

    if (!data) {
      return <Loading />;
    } else {
      if (data.is_visible === 1) {
        details = (
          <Details
            ico={data}
            isGuest={isGuest}
            showFlashMessage={showFlashMessage}
            myself={myself}
            {...this.props}
            isOwner={isOwner}
          />
        );
      }
    }

    // Right - Sidebar
    const rightSidebar = isOwner ? (
      <Manager ico={data} orderIsDisabled={orderIsDisabled} {...this.props} />
    ) : (
      <Info
        seller={data.user}
        type={data.type}
        feedbacks={feedbacks}
        getOwnerStats={getOwnerStats}
        stats={stats}
      />
    );

    return (
      <>
        <div id="details">
          <div className="px-sm-5 px-0">
            <div className="row">
              {orderIsDisabled ? this._renderDisabledOrder(true) : null}
              {details}
              {rightSidebar}
            </div>
          </div>
        </div>
        <Closed ref="isHiddenModal" />
      </>
    );
  }
}
