/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import BaseModal from '../common/BaseModal'

export default class TermsOfServiceModal extends BaseModal {
    
    constructor(props) {
        super(props);

        this.state = {
            legalCheck: false,
            tosCheck: false
        }

        this.onChangeLegal = this.onChangeLegal.bind(this)
        this.onChangeTOS = this.onChangeTOS.bind(this)
        this.validateBeforeAccept = this.validateBeforeAccept.bind(this)
    }

    async componentDidMount() {
        await this.setSettings({
            className: 'tos-modal',
            onClickBackdropClose: true,
            showModalClose: true,
        })
    }

    onChangeLegal() {
        const legalCheck = !this.state.legalCheck
        this.setState({ legalCheck })
    }

    onChangeTOS() {
        const tosCheck = !this.state.tosCheck
        this.setState({ tosCheck })
    }

    validateBeforeAccept() {
        const { me, onAccept } = this.props;
        const { legalCheck, tosCheck } = this.state

        if (legalCheck && tosCheck) {
            onAccept()
            me();
            this.close()
        } else {
            window.alert.error('Please make sure you confirmed that you are at least 13 years old and have read terms of service.');
        }
    }

    renderHeader = () => {
        return <h2 className="mb-4">The OTC Room Terms of Service</h2>
    }

    renderBody = () => {        
        return <div>
            Please take a few minutes to read and understand our <a href="#">Terms of Service.</a>
            To continue, confirm if you are at least 13 years of age and have read OTC Room Terms of Service.
            <div className="form-group mt-1">
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="legal-check" value={this.state.legalCheck} onChange={() => this.onChangeLegal()}/>
                    <label className="form-check-label" htmlFor="legal-check">I am at least 13 years of age.</label>
                </div>
                <div className="form-check">
                    <input type="checkbox" className="form-check-input" id="TOS-check" value={this.state.tosCheck} onChange={() => this.onChangeTOS()}/>
                    <label className="form-check-label" htmlFor="TOS-check">Read OTC Room Terms of Service.</label>
                </div>
            </div>
        </div>
    }

    renderFooter = () => {
        return (
            <div className="row mt-4">
                <div className="col-12 text-right buttons p-0" style={{marginBottom: 30}}>
                    <button className="btn btn-blue" onClick={this.validateBeforeAccept}>ACCEPT</button>
                </div>
            </div>
        )
    }
}