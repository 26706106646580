import React from 'react'
import BaseModal from '../../common/BaseModal'
import Loading2 from '../../common/Loading2'

export default class CancelTransactionModal extends BaseModal {
    constructor(props) {
        super(props)

        this.state = {
            transaction: null,
            isLoading: false
        }
    }

    toggleOpen = async (transaction) => {
        await this.setSettings({
            className: 'cancel-transaction-modal',
            dialogClassName: 'modal-dialog-centered',
            onClickBackdropClose: true,
        })

        await this.setState({ transaction })
        this.open()
    }

    _close = () => {
        this.close()
    }

    _beforeClose = async () => {
        const { transaction: { uuid, ico } } = this.state
        const { updateTransaction } = this.props
        this.setState({ isLoading: true })
        try {
            await updateTransaction(ico.uuid, uuid, { status: 'failed' })
            this.setState({ isLoading: false })
            this._close()
        } catch (e) {
            window.alert.error(e)
            this.setState({ isLoading: false })
        }
    }

    renderHeader = () => {
        return (
            <h2 className="cancel-transaction-modal__title">Confirmation</h2>
        )
    }

    renderBody = () => {
        const { isLoading } = this.state
        return (
            <div className="cancel-transaction-modal__meta">
                <p className="text-gray-secondary">You are about to cancel a deal transaction. If you’re sure you want to cancel this transaction click proceed below.</p>

                {isLoading && <Loading2 />}
            </div>
        )
    }

    renderFooter = () => {
        return (
            <React.Fragment>
                <a href="#" className="mr-5" onClick={(e) => {e.preventDefault(); this._close()}}>Cancel</a>
                <a href="#" onClick={(e) => {e.preventDefault(); this._beforeClose()}}>PROCEED</a>
            </React.Fragment>
        )
    }
}
