import React, { Component } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import Ico from './Ico'
import Youtube from './Youtube'
import Instagram from './Instagram'
import Twitter from './Twitter'

class Assets extends Component {
    constructor(props) {
        super(props)
    }

    onChange = (e) => {

    }

    render() {
        return (
            // <div id="asset-container" className="p-5">
            //     <Tabs defaultActiveKey="ico" className="asset-tabs">
            //         <Tab eventKey="ico" title="ICO" tabClassName="asset-item">
            //             <Ico { ...this.props } />
            //         </Tab>
            //         <Tab eventKey="youtube" title="YouTube" tabClassName="asset-item">
            //             <Youtube { ...this.props } />
            //         </Tab>
            //         <Tab eventKey="instagram" title="Instagram" tabClassName="asset-item">
            //             <Instagram { ...this.props } />
            //         </Tab>
            //         <Tab eventKey="twitter" title="Twitter" tabClassName="asset-item">
            //             <Twitter { ...this.props } />
            //         </Tab>
            //     </Tabs>
            // </div>
            <div id="asset-container" className="p-2 p-sm-5">
                <Ico { ...this.props } />
            </div>
        )
    }
}

export default Assets