import Callback from '../components/common/Callback';
import { connect } from 'react-redux';
import { linkTelegram } from '../actions/users';
import { me } from '../actions/auth'

const mapActionCreators = {
  linkTelegram,
  me,
};

const mapStateToProps = (state) => ({
  isLoading: state.users.isLinkingTelegram,
  isSuccess: state.users.linkTelegramSuccess,
  isError: state.users.linkTelegramError,
  errorMessage: state.users.linkTelegramErrorMessage,
});

export default connect(mapStateToProps, mapActionCreators)(Callback);
