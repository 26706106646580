import React from 'react'
import cx from 'classnames'
import { timeFromNow } from '../../../../modules/Utilities'

export function Transaction({
    transaction,
    comments,
    acceptTransaction,
    denyTransaction,
    completeTransaction,
    openChatModal,
    openDetailsModal,
    writeReview,
    hasFeedback,
    reviseTransaction,
    openHistoryModal,
    highlightId
}) {
    const { user, status } = transaction
    const hasComments = comments.length > 0 ? true : false
    const lastComment = comments[comments.length - 1]
    // const hasNotes = notes.length > 0 ? true : false
    let html, firstName, lastName, highlight = false
    
    firstName = user.first_name ? user.first_name : 'User'
    lastName = user.last_name ? user.last_name : user.id

    if (highlightId === transaction.id) {
        highlight = true
    }

    switch (status) {
        case 'processed':
            html = <div className="btn-group w-100">
                <button
                    type="button"
                    className="btn btn-info dropdown-toggle w-100 btn-block"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    PROCESSING
                </button>
                <div className="dropdown-menu">
                    <li
                        className="dropdown-item text-primary"
                        onClick={() => completeTransaction(transaction)}
                    >
                        COMPLETE
                    </li>
                    <li
                        className="dropdown-item text-primary"
                        onClick={() => reviseTransaction(transaction)}
                    >
                        REVISE TRANSACTION
                    </li>
                    <li
                        className="dropdown-item text-danger"
                        onClick={() => denyTransaction(transaction)}
                    >
                        DENY
                    </li>
                </div>
            </div>
            break;
        case 'completed':
            html = <div className="text-capitalize text-primary font-weight-bold">
                <span>COMPLETED</span>
                {!hasFeedback && (
                    <span
                        onClick={() => writeReview(transaction.id)}
                        className="btn btn-info btn-sm btn-block w-auto"
                    >
                        Write a Review
                    </span>
                )}
            </div>
            break;
        case 'failed':
            html = <div className="w-100 text-capitalize text-danger font-weight-bold">
                The transaction was cancelled by you or the other party.
            </div>
            break;
        default:
            html = <div className="btn-group w-100">
                <button
                    type="button"
                    className="btn btn-info dropdown-toggle w-100 btn-block"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    ACTION
                </button>
                <div className="dropdown-menu">
                    <li
                        className="dropdown-item text-info"
                        onClick={() => acceptTransaction(transaction)}
                    >
                        ACCEPT
                    </li>
                    <li
                        className="dropdown-item text-danger"
                        onClick={() => denyTransaction(transaction)}
                    >
                        DENY
                    </li>
                </div>
            </div>
            break;
    }

    return (
        <div className={`user-message col-lg-12 ${status} ${highlight ? 'highlight' : ''}`}>
            <div className="media">
                <div className="image align-self-center mr-3">
                    <img
                        src={user.profile_picture || "/no-user-image.jpg"}
                        className="w-100"
                        alt="avatar"
                    />
                </div>
                <div className={cx("media-body", !hasComments && "mt-4")}>
                    <div>
                        <h5 className="mt-0 mr-3">
                            {firstName} {lastName}
                        </h5>
                        <span>
                            {hasComments && timeFromNow(lastComment.created_at)}
                        </span>
                    </div>
                    <p className="float-left w-100">
                        {highlight ? (
                            <strong className="text-primary">{hasComments && lastComment.data.message}</strong>
                        ) : (
                            hasComments && lastComment.data.message
                        )}
                    </p>
                </div>
                <div className="col-lg-3 p-0 text-center reply">
                    {hasComments && (
                        <a onClick={() => openChatModal(user, transaction)} style={{ cursor: 'pointer' }}>
                            SHOW MESSAGE
                        </a>
                    )}
                    <br />
                    <a onClick={openDetailsModal} style={{ cursor: 'pointer' }}>
                        SHOW DETAILS
                    </a>
                    <br />
                    <a onClick={() => openHistoryModal(transaction)} style={{ cursor: 'pointer' }}>
                        SHOW TRANSACTION HISTORY
                    </a>
                    { html }
                </div>
            </div>
        </div>
    )
}