import React, { Component } from 'react'
import Cleave from 'cleave.js/react'
import { Redirect } from 'react-router-dom'
import Input from '../common/Input'
import Textarea from '../common/Textarea'
import OrderType from '../common/OrderType'
import Currency from '../common/Currency'
import Category from '../common/Category'

export default class Youtube extends Component {
    constructor(props) {
        super(props)

        this.state = {
            account_name: '',
            type: 'sell',
            subscribers: '',
            price: '',
            notes: '',
            category: 'gaming',
            currency: 'ETH',
            isLoading: false,
            isSuccessful: false
        }

        this._handleTypeClick = this._handleTypeClick.bind(this)
        this._handleCurrencyClick = this._handleCurrencyClick.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
    }

    componentWillReceiveProps(nextProps) {
        const { ads: { creatingAd, creatingAdSuccess, createAdFailure } } = nextProps

        if (creatingAd && !creatingAdSuccess) {
            this.setState({ isLoading: true })
        }

        if (!creatingAd && !creatingAdSuccess && createAdFailure) {
            this.setState({ isLoading: false })
        }

        if (!creatingAd && creatingAdSuccess && !createAdFailure) {
            this.setState({ isLoading: false, isSuccessful: true })
        }
    }

    _handleTypeClick(type) {
        this.setState({ type })
    }

    _handleCurrencyClick(currency) {
        this.setState({ currency })
    }

    onChange(e) {
        const name = e.target.name
        const value = name === 'subscribers' || name === 'price' ? e.target.rawValue : e.target.value

        this.setState({
            [name]: value
        })
    }

    onSubmit(e) {
        e.preventDefault()

        const { createAd } = this.props
        createAd({ ...this.state }, 'youtube')
    }

    render() {
        const {
            account_name,
            type,
            category,
            subscribers,
            currency,
            price,
            notes,
            isLoading,
            isSuccessful
        } = this.state

        if (isSuccessful) return <Redirect push to="/"/>

        return (
            <div id="new-assets" className="row session-block">
                <div className="col-12 p-0">
                    <div className="row form-header">
                        <div className="logo p-0">
                            <img src="/new-ico-order.svg" width="75px" alt="ICO Order"/>
                        </div>
                        <div className="col-lg-9 p-0">
                            <h3>Post Youtube Account Order</h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 p-0">
                    <div className="col-lg-10 p-0">
                        <div className="form-block">
                            <OrderType clickHandler={this._handleTypeClick} type={type} />

                            {type === 'sell' &&
                                <div className="inner-block">
                                    <Input
                                        label="Account Name"
                                        name="account_name"
                                        value={account_name}
                                        placeholder="e.g. Onegamesplus, Chills, The Infographic Show"
                                        onChange={this.onChange}
                                    />
                                </div>
                            }

                            <Currency clickHandler={this._handleCurrencyClick} currency={currency} />

                            <div className="inner-block">
                                <div className="input-group mb-3">
                                    <label>Subscribers</label>
                                    <Cleave
                                        name="subscribers"
                                        className="form-control"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'thousand'}}
                                        value={subscribers}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>

                            <div className="inner-block">
                                <div className="input-group mb-3">
                                    <label>Price</label>
                                    <Cleave
                                        name="price"
                                        className="form-control"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'thousand'}}
                                        value={price}
                                        onChange={this.onChange}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">{currency}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 p-0 right-col">
                    <div className="col-lg-9 p-0">
                        <Category category={category} changeHandler={this.onChange} />
                        <div className="input-block">
                            <Textarea
                                label="Notes" 
                                name="notes"
                                value={notes}
                                onChange={this.onChange}
                                placeholder={type === 'buy' ? 'Describe the youtube channel that you want to buy' : 'Describe the youtube channel that you are planning to sell'}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 footer">
                    <hr/>
                    <button className="btn btn-blue" disabled={isLoading} onClick={this.onSubmit}>{type.toUpperCase()} ACCOUNT</button>
                </div>
            </div>
        )
    }
}