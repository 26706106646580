import React, { Component, createRef } from 'react'
import { filter } from 'lodash'
import Loading2 from '../../common/Loading2'
import DetailsModal from './DetailsModal'
import ChatModal from '../Comment/Owner/ChatModal'
import ConfirmDialog from '../../common/ConfirmDialog'
import ReviewFormModal from './Viewer/ReviewFormModal'
// import { getOrderTransactionComments } from '../../../api/Order'
import { Transaction } from './Owner/Transaction'
import TransactionEditModal from './Owner/TransactionEditModal'
import TransactionHistoryModal from './TransactionHistoryModal'

export default class TransactionOwner extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isLoading: true,
            isShowConfirmDialog: false,
            message: '',
            type: null,
            id: null,
            replyToTransactionId: null,
            conversations: [],
            transactee: null,
            reviewTransactionId: null,
            transactionAction: {
                isShowConfirmDialog: false,
                message: '',
                status: null,
                uuid: null,
                pricePerToken: null,
                fee: null
            }
        }

        this._getTransactionComments = this._getTransactionComments.bind(this)
        this._confirmTransactAction = this._confirmTransactAction.bind(this)
        this._cancelTransactAction = this._cancelTransactAction.bind(this)
        this._acceptTransaction = this._acceptTransaction.bind(this)
        this._denyTransaction = this._denyTransaction.bind(this)
        this._completeTransaction = this._completeTransaction.bind(this)
        this._reviseTransaction = this._reviseTransaction.bind(this)
        this._openDetailsModal = this._openDetailsModal.bind(this)
        this._transEditOnSubmit = this._transEditOnSubmit.bind(this)
        this._openChatModal = this._openChatModal.bind(this)
        this._openReviewModal = this._openReviewModal.bind(this)

        this.chatModalRef = createRef()
        this.historyModal = createRef()
    }

    componentDidMount() {
        if (this.props.transactions)
            this._getTransactionComments()
        else
            this.setState({ isLoading: false })
    }

    async _getTransactionComments() {
        const { ico: { uuid }, getOrderComments } = this.props
        await getOrderComments(uuid)
        this.setState({ isLoading: false })
    }

    async _cancelTransactAction() {
        await this.setState({
            transactionAction: {
                isShowConfirmDialog: false,
                message: '',
                status: null,
                uuid: null,
            },
            isLoading: false
        })
    }

    async _confirmTransactAction() {
        const {
            transactionAction: {
                status,
                uuid,
                pricePerToken,
                fee
            }
        } = this.state

        const { transactionEditModal } = this.refs
        const { updateTransaction } = this.props

        this.setState({ isLoading: true })

        if (status === 'revise' && (pricePerToken && fee)) {
            const {
                ico: { uuid: icoUUID },
                refreshTransactions,
                fetchIcoDetails
            } = this.props

            try {
                await updateTransaction(icoUUID, uuid, { price_per_token: pricePerToken, fee })
                await this._cancelTransactAction()
                transactionEditModal.close()
                refreshTransactions()
                fetchIcoDetails(icoUUID)
            } catch (e) {
                window.alert.error(e)
            }
        } else {
            const {
                ico: { uuid: icoUUID },
                refreshTransactions,
                fetchIcoDetails
            } = this.props
    
            try {
                await updateTransaction(icoUUID, uuid, { status })
                this._cancelTransactAction()
                refreshTransactions()
                fetchIcoDetails(icoUUID)
            } catch (e) {
                window.alert.error(e)
            }
        }
    }

    _acceptTransaction({ status, uuid }) {
        if (status === 'reserved' || status === 'negotiated') {
            this.setState({
                transactionAction: {
                    isShowConfirmDialog: true,
                    message: 'Are you sure you want to accept this transaction?',
                    status: 'processed',
                    uuid,
                }
            })
        }
    }

    _denyTransaction({ uuid }) {
        this.setState({
            transactionAction: {
                isShowConfirmDialog: true,
                message: 'Are you sure you want to deny this transaction?',
                status: 'failed',
                uuid,
            }
        })
    }

    _completeTransaction({ uuid }) {
        this.setState({
            transactionAction: {
                isShowConfirmDialog: true,
                message: 'Are you sure you want to complete this transaction?',
                status: 'completed',
                uuid: uuid
            }
        })
    }

    _reviseTransaction() {
        const { transactionEditModal } = this.refs

        transactionEditModal.open()
    }

    _openDetailsModal() {
        const { detailsModal } = this.refs
        detailsModal.setClass('details-modal')
        detailsModal.open()
    }

    _openChatModal = (transactee, { uuid }) => {
        this.setState({ transactee, replyToTransactionId: uuid })
        this.chatModalRef.current.setClass('chat-modal')
        this.chatModalRef.current.open()
    }

    _openHistoryModal = async({ uuid }) => {
        const { getTransactionHistory, ico } = this.props

        await getTransactionHistory(ico.uuid, uuid)
        this.historyModal.current.toggleOpen()
    }

    _openReviewModal() {
        const { reviewFormModal } = this.refs
        reviewFormModal.open()
    }

    _renderTransactions = () => {
        const { 
            transactions,
            ico,
            histories,
            refreshTransactions,
            createReview,
            orderComments,
            getTransactionComments,
            showFlashMessage,
            transactionComments,
            myself,
            getOrderComments
        } = this.props

        let transactionId = null
        const { state } = this.props.history.location

        if (state && state.transactionId) {
            transactionId = state.transactionId
        }

        const { isLoading, transactee, replyToTransactionId } = this.state

        if (isLoading) {
            return <Loading2 />
        }
        
        if (transactions) {
            return transactions.map((transaction) => {
                let userComments = filter(
                    orderComments,
                    (comment) => comment.transaction_id === transaction.id
                )

                return (
                    <div key={transaction.id}>
                        <Transaction
                            key={transaction.id}
                            hasFeedback={transaction.feedbacks_count > 0}
                            transaction={transaction}
                            comments={userComments}
                            acceptTransaction={this._acceptTransaction}
                            denyTransaction={this._denyTransaction}
                            completeTransaction={this._completeTransaction}
                            openChatModal={this._openChatModal}
                            openDetailsModal={this._openDetailsModal}
                            writeReview={this._openReviewModal}
                            reviseTransaction={this._reviseTransaction}
                            openHistoryModal={this._openHistoryModal}
                            highlightId={transactionId}
                        />
                        <DetailsModal
                            transaction={transaction}
                            ico={ico}
                            ref='detailsModal'
                        />
                        <TransactionEditModal
                            ref="transactionEditModal"
                            transaction={transaction}
                            transEditOnSubmit={this._transEditOnSubmit}
                            isLoading={isLoading}
                        />
                        <TransactionHistoryModal
                            ref={this.historyModal}
                            histories={histories}
                            transaction={transaction}
                        />
                        <ReviewFormModal
                            ref="reviewFormModal"
                            ico={ico}
                            transaction={transaction}
                            createReview={createReview}
                            refreshTransactions={refreshTransactions}
                        />
                        <ChatModal
                            ref={this.chatModalRef}
                            myself={myself}
                            transactionId={replyToTransactionId}
                            comments={transactionComments}
                            transactee={transactee}
                            ico={ico}
                            showFlashMessage={showFlashMessage}
                            getTransactionComments={getTransactionComments}
                            getOrderComments={getOrderComments}
                        />
                    </div>
                )
            })
        } else {
            return (
                <div className='user-message col-lg-12'>
                    <h4 className='text-center'>No transactions made yet.</h4>
                </div>
            )
        }
    }

    _transEditOnSubmit({ uuid }, pricePerToken, fee) {
        this.setState({
            transactionAction: {
                isShowConfirmDialog: true,
                message: 'Are you sure you want to revise this transaction?',
                status: 'revise',
                uuid,
                pricePerToken,
                fee
            }
        })
    }

    render() {
        const { 
            transactionAction,
            isLoading
        } = this.state

        let status

        switch (transactionAction.status) {
            case 'deny':
                status = 'danger'
                break
            case 'complete':
                status = 'primary'
                break
            default:
                status = 'info'
                break
        }
        
        return (
            <div className='col-lg-12'>
                <ConfirmDialog
                    cancel={this._cancelTransactAction}
                    confirm={this._confirmTransactAction}
                    isShow={transactionAction.isShowConfirmDialog}
                    type={status}
                    message={transactionAction.message}
                    isLoading={isLoading}
                />
                <h3>Transactions</h3>
                {this._renderTransactions()}
            </div>
        )
    }
}