import React, { Component } from 'react'
import Cleave from 'cleave.js/react'
import { Redirect, withRouter } from 'react-router-dom'
import DatePicker from 'react-datepicker/es'
import cx from 'classnames'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import 'react-datepicker/dist/react-datepicker.css'
import Input from '../common/Input'
import Textarea from '../common/Textarea'
import OrderType from '../common/OrderType'
import Currency from '../common/Currency'
import ConfirmDialog from '../common/ConfirmDialog'

class Ico extends Component {
    constructor(props) {
        super(props)

        this.state = {
            type: 'sell',
            asset_type: 'future',
            name: '',
            symbol: '',
            currency: 'ETH',
            ico_price_token: '',
            selling_price_token: '',
            fee: '',
            supply: '',
            token_release_date: moment().format('YYYY-MM-DD HH:mm:ss'),
            vesting_schedule: '',
            notes: '',
            confirmModalOpen: false,
            isLoading: false,
            isSuccessful: false,
            editMode: false,
            offer_deadline_date: moment().format('YYYY-MM-DD HH:mm:ss'),
            buying_price_token: '',
        }
    }

    async componentDidMount() {
        const { match: { params }, fetchIcoDetails } = this.props

        if (params && params.id) {
            const { payload: { ico } } = await fetchIcoDetails(params.id)

            this.setState({
                type: ico.type,
                asset_type: ico.asset_type,
                name: ico.name,
                symbol: ico.symbol,
                currency: ico.currency,
                ico_price_token: ico.ico_price_token,
                fee: ico.fee,
                supply: ico.supply,
                token_release_date: ico.token_release_date,
                vesting_schedule: ico.vesting_schedule,
                notes: ico.notes,
                editMode: true,
                buying_price_token: ico.buying_price_token,
                offer_deadline_date: ico.offer_deadline_date,

            })
        }
        
        if(params && params.ticker) {
            const paramType = params.type === 'Selling' ? 'sell' : 'buy';
            this.setState({
                type: paramType,
                name: params.name,
                symbol: params.ticker,
                asset_type: params.asset_type
                // editMode: true
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { ads: { creatingAd, creatingAdSuccess, createAdFailure } } = nextProps

        if (creatingAd && !creatingAdSuccess) {
            this.setState({ isLoading: true })
        }

        if (!creatingAd && !creatingAdSuccess && createAdFailure) {
            this.setState({ isLoading: false })
        }

        if (!creatingAd && creatingAdSuccess && !createAdFailure) {
            this.setState({ isLoading: false, isSuccessful: true })
        }
    }

    _handleTypeClick = (type) => {
        this.setState({ type })
    }

    _handleCurrencyClick = (currency) => {
        this.setState({ currency })
    }

    _handleDateChange = (date) => {
        this.setState({
            token_release_date: moment(date).format('YYYY-MM-DD HH:mm:ss')
        });
    }

    _handleExpireDateChange = (date) => {
        this.setState({
            offer_deadline_date: moment(date).format('YYYY-MM-DD HH:mm:ss')
        });
    }

    onChange = (e) => {
        const name = e.target.name
        const value = e.target.value

        if (name === 'ico_price_token') {
            this.setState({
                [name]: e.target.rawValue
            });

            return
        }

        if(name === 'buying_price_token') {
            this.setState({
                [name]: e.target.rawValue
            });

            return
        }

        if (name === 'supply'){
            this.setState({
                [name]: e.target.rawValue
            });

            return
        }

        this.setState({
            [name]: value
        })
    }

    onSubmit = (e) => {
        e.preventDefault()

        this.setState({ confirmModalOpen: true })
    }

    _handleConfirmClick = async() => {
        this.setState({ isLoading: true })

        const { editMode } = this.state

        if (editMode) {
            const { updateAd, match: { params } } = this.props
            const { type, name, symbol, currency, ico_price_token, fee, supply, token_release_date, vesting_schedule, notes } = this.state
            await updateAd(params.id,{
                type,
                name,
                symbol,
                currency,
                ico_price_token,
                fee,
                supply,
                token_release_date,
                vesting_schedule,
                notes
            })
            this.setState({ isLoading: false })
            this.props.history.goBack()
        } else {
            this.setState({ isLoading: true })
            const { createAd } = this.props
            await createAd({ ...this.state })
            this.setState({ isLoading: false })
        }
    }
    _handleCancelClick = async() => {
        this.setState({confirmModalOpen: false})
    }
    render() {
        const {
            type,
            asset_type,
            name,
            symbol,
            currency,
            ico_price_token,
            supply,
            token_release_date,
            vesting_schedule,
            notes,
            isLoading,
            isSuccessful,
            editMode,
            confirmModalOpen,
            offer_deadline_date,
            buying_price_token
        } = this.state

        if (isSuccessful) return <Redirect push to="/"/>

        return (
            <div id="new-assets" className="row session-block">
                <div className="col-12 p-1 p-sm-0">
                    <div className="row form-header">
                        <div className="logo p-0">
                            <img src="/new-ico-order.svg" width="75px" alt="ICO Order"/>
                        </div>
                        <div className="col-lg-9 p-0">
                            <h3>Fill the order details</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 p-0">
                    <div className="col-lg-10 p-0">
                        <div className="form-block">
                            <OrderType clickHandler={this._handleTypeClick} type={type} editMode={editMode} />

                            <div className="inner-block">
                                <Input
                                    label="Name of the token"
                                    name="name"
                                    value={name}
                                    placeholder="e.g. Holochain, Rootstock"
                                    onChange={this.onChange}
                                />
                            </div>

                            <div className="inner-block">
                                <Input
                                    label="Symbol of the Token"
                                    name="symbol" value={symbol.toUpperCase()}
                                    placeholder="e.g. HOT, RSK"
                                    onChange={this.onChange}
                                    maxLength={12}
                                />
                            </div>

                            <Currency clickHandler={this._handleCurrencyClick} currency={currency} />

                            <div className="inner-block">
                                {type === 'sell' && 
                                    <div className="input-group mb-3">
                                        <label>ICO Price per Token</label>
                                        <Cleave
                                            name="ico_price_token"
                                            className="form-control"
                                            options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', numeralDecimalScale: 16 }}
                                            value={ico_price_token}
                                            onChange={this.onChange}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">{currency}</span>
                                        </div>
                                    </div>
                                }
                                {type === 'buy' && 
                                    <div className="input-group mb-3">
                                        <label>Buying Price</label>
                                        <Cleave
                                            name="buying_price_token"
                                            className="form-control"
                                            options={{ numeral: true, numeralThousandsGroupStyle: 'thousand', numeralDecimalScale: 16 }}
                                            value={buying_price_token}
                                            onChange={this.onChange}
                                        />
                                        <div className="input-group-append">
                                            <span className="input-group-text">{currency}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="inner-block">
                                <div className="input-group mb-3">
                                    <label>{type === 'sell' ? `Supply` : `Supply to buy` }</label>
                                    <Cleave
                                        name="supply"
                                        className="form-control"
                                        options={{ numeral: true, numeralThousandsGroupStyle: 'thousand'}}
                                        value={supply}
                                        onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 p-0 right-col">
                    <div className="col-lg-9 p-0">
                        <div className="form-block">
                            {(type === 'sell' && asset_type !== 'liquid') &&
                                <div className="inner-block date-picker">
                                    <div className="input-group mb-3">
                                        <label>Token Release Date</label>
                                        <div className="datepicker-container">
                                            <DatePicker
                                                name="token_release_date"
                                                selected={moment(token_release_date)}
                                                className={cx("form-control w-100")}
                                                onChange={this._handleDateChange}
                                                dropdownMode="select"
                                                dateFormat={"DD/MM/YYYY"}
                                                />
                                            <div className="input-group-append">
                                                <span className="input-group-text bg-blue">
                                                    <FontAwesomeIcon icon={faCalendar} />
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }

                            <div className="inner-block">
                                {(type === 'sell' && asset_type !== 'liquid') && 
                                    <Input
                                        label="Vesting Schedule"
                                        name="vesting_schedule"
                                        value={vesting_schedule}
                                        placeholder="e.g. 50% on release date, 20% every month for 5 months"
                                        onChange={this.onChange}
                                    />
                                }
                                {(type === 'buy' && asset_type !== 'liquid') &&
                                <div className="inner-block date-picker">
                                    <div className="input-group mb-3">
                                        <label>Offer Expiration</label>
                                        <div className="datepicker-container">
                                            <DatePicker
                                                name="offer_deadline_date"
                                                selected={moment(offer_deadline_date)}
                                                className={cx("form-control w-100")}
                                                onChange={this._handleExpireDateChange}
                                                dropdownMode="select"
                                                dateFormat={"DD/MM/YYYY"}
                                                />
                                            <div className="input-group-append">
                                                <span className="input-group-text bg-blue">
                                                    <FontAwesomeIcon icon={faCalendar} />
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            }
                            </div>

                            <div className="input-block">
                                <Textarea
                                    label="Notes" 
                                    name="notes"
                                    value={notes}
                                    onChange={this.onChange}
                                    placeholder="Write down any pertinent information about this allocation to ensure a smooth transaction"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 footer">
                    <hr/>
                    {editMode && (
                        <button className="btn btn-white mr-2" disabled={isLoading} onClick={this.props.history.goBack}>CANCEL</button>
                    )}
                    <button className="btn btn-blue" disabled={isLoading} onClick={this.onSubmit}>{editMode ? 'EDIT' : 'POST'} {this.state.type.toUpperCase()} AD</button>
                </div>
                <ConfirmDialog
                        cancel={this._handleCancelClick}
                        confirm={this._handleConfirmClick}
                        isShow={confirmModalOpen}
                        message={`Proceed with ${editMode ? 'editing' : 'posting'} ad?`}
                    />
            </div>
        )
    }
}

export default withRouter(Ico)
