import React, { Component, createRef } from 'react'
import CreateOrderModal from '../Modals/CreateOrderModal'
import ListedTokenModal from '../Modals/ListedTokenModal'
import { withRouter } from 'react-router-dom';

class CreateOrder extends Component {
    constructor(props) {
        super(props);

        this.createOrderModalRef = createRef()
        this.listedTokenModalRef = createRef()
    }

    _openCreateOrderModal = () => {
        this.createOrderModalRef.current.toggleOpen()
    }
    
    _onClickBackdrop = () => {
        this.createOrderModalRef.current.onClickBackdropClose(true)
    }

    render() {
        const { isAdmin } = this.props

        if (isAdmin) {
            return (
                <div className="create-order-container">
                    <a onClick={this._openCreateOrderModal} className="btn btn-primary text-white">CREATE ORDER</a>
                    <CreateOrderModal ref={this.createOrderModalRef} modalRef={this.listedTokenModalRef} />
                    <ListedTokenModal ref={this.listedTokenModalRef} {...this.props} />
                </div>
            )
        } else {
            return (
                <div className="create-order-container">
                    <div className="box">
                        <div className="header-title">Create Order</div>
                        <div className="header-title-description">Create order to start trading.</div>
                        <div className="text-center button-container">
                            <a onClick={this._openCreateOrderModal} className="btn btn-blue w-50 text-white">CREATE ORDER</a>
                        </div>
                    </div>
                    <CreateOrderModal ref={this.createOrderModalRef} modalRef={this.listedTokenModalRef} />
                    <ListedTokenModal ref={this.listedTokenModalRef} {...this.props} />
                </div>
            )
        }
    }
}

export default withRouter(CreateOrder)
