import React, { Component } from "react";
import Statistics from "./Statistics";
import Chart from "./Chart";
import Orders from "./Orders";
import Members from "./Members";

export default class Dashboard extends Component {

    render() {
        const {
            adminUpdateUser,
            adminGetUser,
            user,
            adminGetMembersSummary,
            summary,
            adminGetUsers,
            users,
            searchAds,
            ads,
            adminGetWeeklyUsers,
            weeklyUsers,
            adminGetStatistics,
            statistics,
            adminGetOrders,
            orders,
            updateAd
        } = this.props

        return (
            <div id="dashboard" className="col-12 dashboard-container">
                <Statistics adminGetStatistics={adminGetStatistics} statistics={statistics} size="col-lg-2 col-sm-6 col-xs-12" />
                <Chart adminGetWeeklyUsers={adminGetWeeklyUsers} weeklyUsers={weeklyUsers} />
                <Members
                    adminUpdateUser={adminUpdateUser}
                    adminGetUser={adminGetUser}
                    user={user}
                    adminGetMembersSummary={adminGetMembersSummary}
                    summary={summary}
                    adminGetUsers={adminGetUsers}
                    users={users}
                />
                <Orders
                    searchAds={searchAds}
                    ads={ads}
                    adminGetOrders={adminGetOrders}
                    orders={orders}
                    updateAd={updateAd}
                />
            </div>
        )
    }
}
