import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function Denied({ transaction }) {

    return (
        <div className="card text-white bg-danger mb-3">
            <div className="card-body">
                <h4 className="card-title"><FontAwesomeIcon icon="exclamation-circle" size="lg" className="mr-2" /> Denied</h4>
                <p className="card-text">Your transaction has been denied.</p>
            </div>
        </div>
    )
}

Denied.propTypes = {
    reservation: PropTypes.object.isRequired,
}

export function Accepted({ transaction }) {

    return (
        <div className="card text-white bg-info mb-3">
            <div className="card-body">
                <h4 className="card-title"><FontAwesomeIcon icon="check-circle" size="lg" className="mr-2" /> Accepted</h4>
                <p className="card-text">Your reservation has been completed.</p>
            </div>
        </div>
    )
}

Accepted.propTypes = {
    reservation: PropTypes.object.isRequired,
}

export function Completed({ transaction, writeReviewClick }) {
    return (
        <div className="card text-white bg-purple-700 mb-3">
            <div className="card-body">
                <h4 className="card-title">
                    <FontAwesomeIcon
                        icon="handshake"
                        size="lg"
                        className="mr-2"
                    />{" "}
                    Completed
                </h4>
                <p className="card-text">
                    Transaction has been completed.
                    {transaction.feedbacks_count === 0 && (
                        <button
                            type="button"
                            onClick={writeReviewClick}
                            className="btn btn-default text-dark float-right"
                        >
                            Write a Review
                        </button>
                    )}
                </p>
            </div>
        </div>
    )
}

Completed.propTypes = {
    transaction: PropTypes.object.isRequired,
    writeReviewClick: PropTypes.func.isRequired,
}
