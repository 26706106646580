import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class VerifyUser extends Component {
  componentDidMount() {
    const { match: { params: { token } } } = this.props

    window.http.get(`verify-user/${token}`)
      .then(({data}) => {
          this.props.showFlashMessage(data.message)
      })
      .catch(({data}) => {
          this.props.showFlashMessage(data.error, 'error')
      });
  }

  render() {
    return (
      <div className="mt-5">
        <div className="row">
            <div className="col-xs-12">
                <div className="media">
                    <div className="media-body">
                      <h1>Email verification complete!</h1>
                      <p className="text-gray-secondary">You have successfully verified your account.</p>
                      <Link to={{ pathname: '/', state: { showLoginModal: true }}} className='btn btn-blue'>Proceed to login</Link>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}