import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Header extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => {
    const { me } = this.props;
    const auth = JSON.parse(localStorage.getItem('persist:root'));
    const token = auth && JSON.parse(auth.auth);

    if (token && token.token) {
      me();
    }
  };

  componentDidUpdate(prevProps) {
    const { me, auth } = this.props;

    if (auth.token !== prevProps.auth.token) {
      me();
    }
  }

  onLogout = async () => {
    const { logout } = this.props;

    await logout(true);
  };

  back = () => {
    window.history.back();
  }

  render() {
    const { myself, path } = this.props;

    return (
      <>
        <header className="navbar navbar-expand-md navbar-light">
          <div className="container">
            {path !== '/mini-app' && (
              <NavLink to="#" onClick={() => this.back()} className="mini-app__back-btn">
                <FontAwesomeIcon icon="arrow-left" color="#171C34" />
              </NavLink>
            )}
            <NavLink to="/mini-app" className="logo">
              <img src="/octbot.png" alt="logo" />
            </NavLink>
            {myself && (
              <NavLink to="#" className="btn btn-light btn-light--transparent-bg mini-app__logout" onClick={this.onLogout}>
                Logout
              </NavLink>
            )}
          </div>
        </header>
      </>
    );
  }
}

export default Header;
