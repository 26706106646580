import React, { Component, createRef } from 'react';
import { NavLink } from 'react-router-dom';
import ConfirmModal from '../Home/ConfirmModal';
import RegisterModal from '../Home/RegisterModal';
import LoginModal from '../Home/LoginModal';
import ForgotPasswordModal from '../Home/ForgotPasswordModal';

class Post extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      assetType: null,
      transactionType: null,
      formData: {
        assetName: '',
        assetTicker: '',
        chain: '',
      },
    };

    this.ASSET_LIQUID = 'liquid';
    this.ASSET_FUTURE = 'future';

    this.TRANSACTION_BUY = 'buy';
    this.TRANSACTION_SELL = 'sell';

    this.confirmModalRef = createRef();
    this.forgotPasswordModalRef = createRef();
    this.loginModalRef = createRef();
    this.registerModalRef = createRef();
  }

  componentDidMount = async () => {
    const { me } = this.props;
    const auth = JSON.parse(localStorage.getItem('persist:root'));
    const token = auth && JSON.parse(auth.auth);

    if (token && token.token) {
      me();
    }
  };

  componentDidUpdate(prevProps) {
    const { me, auth } = this.props;

    if (auth.token !== prevProps.auth.token) {
      me();
    }
  }

  setTransactionType = (transactionType = null) => {
    this.setState({ transactionType });
  };

  setAssetType = (assetType = null) => {
    this.setState({ assetType });
  };

  cancelTransaction = () => {
    this.setState({
      transactionType: null,
      formData: {
        assetName: '',
        assetTicker: '',
        chain: '',
      },
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();

    const { formData } = this.state;
    const { name, value } = e.target;

    this.setState({
      formData: {
        ...formData,
        [name]: value,
      },
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const { transactionType, formData: { assetName, assetTicker } } = this.state;

    const url = `/mini-app/order/new/${transactionType}/${assetName}/${assetTicker}/liquid`;
    this.props.history.push(url)
  };

  _openConfirmModal = (email) => {
    this.setState({ email });
    this.confirmModalRef.current.setClass('confirm-modal');
    this.confirmModalRef.current.open();
  };

  _openForgotPasswordModal = () => {
    this.forgotPasswordModalRef.current.open();
  };

  _openLoginModal = () => {
    this.loginModalRef.current.open();
  };

  _openRegisterModal = () => {
    this.registerModalRef.current.open();
  };

  render() {
    const { auth, login, register, myself, showFlashMessage } = this.props;
    const { email, assetType, transactionType, formData } = this.state;

    return (
      <main className="main-content main-content--centered">
        {!myself ? (
          <>
            <h1 className="main-content__heading">You are not signed in</h1>
            <p className="main-content__text">Please login or register below</p>

            <nav className="main-content__nav-links">
              <button
                className="nav-link btn btn-blue"
                onClick={this._openLoginModal}
              >
                Login
              </button>
              <button
                className="nav-link btn btn-blue"
                onClick={this._openRegisterModal}
              >
                Register
              </button>
              <NavLink
                className="nav-link btn btn-blue mt-3"
                to="/mini-app"
              >
                Cancel
              </NavLink>
            </nav>

            {/* Modals */}
            <LoginModal
              ref={this.loginModalRef}
              login={login}
              auth={auth}
              openRegisterModal={this._openRegisterModal}
              openForgotPasswordModal={this._openForgotPasswordModal}
            />
            <RegisterModal
              ref={this.registerModalRef}
              register={register}
              auth={auth}
              openConfirmModal={this._openConfirmModal}
            />
            <ConfirmModal ref={this.confirmModalRef} email={email} />
            <ForgotPasswordModal
              showFlashMessage={showFlashMessage}
              ref={this.forgotPasswordModalRef}
            />
          </>
        ) : assetType === this.ASSET_LIQUID ? (
          !transactionType ? (
            <>
              <h1 className="main-content__heading">Select your transaction</h1>
              <p className="main-content__text">Are you buying or selling?</p>

              <nav className="main-content__nav-links">
                <button
                  className="nav-link btn btn-blue"
                  onClick={() => this.setTransactionType(this.TRANSACTION_BUY)}
                >
                  Buying
                </button>
                <button
                  className="nav-link btn btn-blue"
                  onClick={() => this.setTransactionType(this.TRANSACTION_SELL)}
                >
                  Selling
                </button>
                <button
                  className="nav-link btn btn-blue mt-3"
                  onClick={() => this.setAssetType()}
                >
                  Cancel
                </button>
              </nav>
            </>
          ) : (
            <>
              <form className="d-flex flex-column" onSubmit={this.handleSubmit}>
                <label
                  className="listed-input__label text-black-50 fs-6"
                  htmlFor="assetName"
                >
                  Token name
                </label>
                <input
                  onChange={this.handleInputChange}
                  type="text"
                  value={formData.assetName}
                  name="assetName"
                  id="assetName"
                  className="mb-3"
                  required
                />
                <label
                  className="listed-input__label text-black-50 fs-6"
                  htmlFor="assetTicker"
                >
                  Ticker
                </label>
                <input
                  onChange={this.handleInputChange}
                  type="text"
                  value={formData.assetTicker}
                  name="assetTicker"
                  id="assetTicker"
                  className="mb-5"
                  required
                />
                {/* <label
                  className="listed-input__label text-black-50 fs-6"
                  htmlFor="chain"
                >
                  Chain
                </label>
                <input
                  onChange={this.handleInputChange}
                  type="text"
                  value={formData.chain}
                  name="chain"
                  id="chain"
                  className="mb-5"
                /> */}
                <nav className="main-content__nav-links">
                  <input
                    className="nav-link btn btn-blue"
                    type="submit"
                    value={'Continue'}
                  />
                  <button
                    type="button"
                    className="nav-link btn btn-blue"
                    onClick={this.cancelTransaction}
                  >
                    Cancel
                  </button>
                </nav>
              </form>
            </>
          )
        ) : (
          <>
            <h1 className="main-content__heading">Create an order</h1>
            <p className="main-content__text">Select the type of token below</p>

            <nav className="main-content__nav-links">
              <NavLink
                className="nav-link btn btn-blue"
                to="#"
                onClick={() => this.setAssetType(this.ASSET_LIQUID)}
              >
                Liquid
              </NavLink>
              <NavLink
                className="nav-link btn btn-blue"
                to="/mini-app/order/new"
              >
                Future
              </NavLink>
              <NavLink
                className="nav-link btn btn-blue mt-3"
                to="/mini-app"
              >
                Cancel
              </NavLink>
            </nav>
          </>
        )}
      </main>
    );
  }
}

export default Post;
