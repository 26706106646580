import { RSAA } from "redux-api-middleware";

export const MESSAGE = "app:message";
export const MESSAGE_SUCCESS = "app:message_success";
export const MESSAGE_FAILURE = "app:message_failure";

export function getMessages() {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();
        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/me/messages`,
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Accept": "application/json; charset=UTF-8",
                },
                types: [MESSAGE, MESSAGE_SUCCESS, MESSAGE_FAILURE]
            }
        })
    }
}

const ping = () => {
    return (state, action) => ({
        ...state,
        messages: action.payload.messages
    })
};

const ACTION_HANDLERS = {
    [MESSAGE]: state => ({...state}),
    [MESSAGE_SUCCESS]: ping(),
    [MESSAGE_FAILURE]: state => ({...state})
};

const initialState = {
    messages: []
};

export default function message(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}