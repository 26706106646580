import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: '',
      max: '',
      min: '',
      currency: '',
      trustScore: '',
      sortOrder: '',
      page: 1,
    };
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  _onSearch = () => {
    const { sortOrder, trustScore, min, max } = this.state;
    this.props.advanceSearch({ max, min, trustScore, sortOrder });
  };

  onCheck = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  _onReset = () => {
    const { sortOrder, trustScore, min, max } = this.state;
    this.props.advanceSearch({
      max: '',
      min: '',
      trustScore: '',
      sortOrder: '',
    });
    this.setState({
      type: '',
      max: '',
      min: '',
      currency: '',
      trustScore: '',
      sortOrder: '',
      page: 1,
    });
  };

  render() {
    const { currency, max, min, trustScore, sortOrder } = this.state;
    return (
      <div className="text-center filter-container w-25 pt-sm-4 pt-0">
        <div className="d-flex flex-column h-100 px-sm-5 px-0">
          <div className="mb-3">
            <p className='h3'>Filters</p>
          </div>
          <div className="">
            <div className="form-group ">
              <p className="h3 filter-title">Price</p>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  className="form-control"
                  onChange={this.onChange}
                  placeholder="Min"
                  value={min}
                  name="min"
                />
                <span className="px-2">to</span>
                <input
                  type="text"
                  className="form-control"
                  onChange={this.onChange}
                  placeholder="Max"
                  value={max}
                  name="max"
                />
              </div>
            </div>
          </div>
          {/* <div className="">
            <p className="h3 filter-title">Trust Score</p>
            <div className="form-group">
              <div className="d-flex align-items-center">
                <input
                  type="radio"
                  className="form-control mr-3"
                  onChange={this.onCheck}
                  placeholder="All"
                  value="5"
                  name="trustScore"
                  id="trust5"
                  checked={trustScore === "5"}
                />
                <label className="mb-0" htmlFor="trust5">
                  <img alt="5 star" src="/5star.svg" />
                </label>
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="radio"
                  className="form-control mr-3"
                  onChange={this.onCheck}
                  placeholder="All"
                  value="4"
                  name="trustScore"
                  id="trust4"
                  checked={trustScore === "4"}
                />
                <label className="mb-0" htmlFor="trust4">
                  <img alt="4 star" src="/4star.svg" />
                </label>
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="radio"
                  className="form-control mr-3"
                  onChange={this.onCheck}
                  placeholder="All"
                  value="3"
                  name="trustScore"
                  id="trust3"
                  checked={trustScore === "3"}
                />
                <label className="mb-0" htmlFor="trust3">
                  <img alt="3 star" src="/3star.svg" />
                </label>
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="radio"
                  className="form-control mr-3"
                  onChange={this.onCheck}
                  placeholder="All"
                  value="2"
                  name="trustScore"
                  id="trust2"
                  checked={trustScore === "2"}
                />
                <label className="mb-0" htmlFor="trust2">
                  <img alt="2 star" src="/2star.svg" />
                </label>
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="radio"
                  className="form-control mr-3"
                  onChange={this.onCheck}
                  placeholder="All"
                  value="1"
                  name="trustScore"
                  id="trust1"
                  checked={trustScore === "1"}
                />
                <label className="mb-0" htmlFor="trust1">
                  <img alt="1 star" src="/1star.svg" />
                </label>
              </div>
            </div>
          </div> */}
          <div className="">
            <div className="form-group">
              <p className="h3 filter-title">Sort by</p>
              <div className="d-flex align-items-center">
                <input
                  type="radio"
                  className="form-control mr-3"
                  onChange={this.onCheck}
                  placeholder="All"
                  value="new"
                  name="sortOrder"
                  id="new"
                  checked={sortOrder === 'new'}
                />
                <label className="mb-0" htmlFor="newestnewest">
                  Date listed: Newest First
                </label>
              </div>
              <div className="d-flex align-items-center">
                <input
                  type="radio"
                  className="form-control mr-3"
                  onChange={this.onCheck}
                  placeholder="All"
                  value="old"
                  name="sortOrder"
                  id="oldest"
                  checked={sortOrder === 'old'}
                />
                <label className="mb-0" htmlFor="oldest">
                  Date listed: Oldest First
                </label>
              </div>
            </div>
          </div>

          <div className="">
            <div className="form-group d-flex align-items-center justify-content-center">
              <button
                type="button"
                className="btn btn-blue btn-search mr-3"
                onClick={this._onSearch}
              >
                Apply
              </button>
              <button
                type="button"
                className="btn btn-reset"
                onClick={this._onReset}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Filter.propTypes = {
  search: PropTypes.func.isRequired,
};
