import React, { Component } from 'react';
import { debounce } from 'lodash'
import _ from 'lodash'
import TransactionsList from './TransactionsList';
import Filter from './Filter';

class Search extends Component {
    constructor() {
      super();

      this.state = {
        searchKey: '',
    }
      this._search = this._search.bind(this)
      this.fetchAllAds = this.fetchAllAds.bind(this);
      this._advanceSearch = this._advanceSearch.bind(this);
      
    }
    async componentDidMount() {
        const { getTransactionsCount, location: { state }, history } = this.props

        await this.fetchAllAds()
        await getTransactionsCount()

        if (typeof state !== 'undefined' && state.showLoginModal) {
            this._openLoginModal();
            history.replace();
        }
    }

    async fetchAllAds(page = 1, data) {
      const { fetchAds } = this.props
      const { searchKey } = this.state
      await fetchAds(page, searchKey, data)
    }

    _search(e) {
      e.persist()

      if (!this.debouncedFn) {
          this.debouncedFn = debounce(() => {
              let name = e.target.value

              if (name.length > 0)
                  this.setState({ searchKey: name })

              this.fetchAllAds()

          }, 1000)
      }
      this.debouncedFn()
    };

    _advanceSearch(data, page = 1 ) {
      if(data) {
        this.fetchAllAds(page, data);
      }
    };

    render() {
      const {
          searchKey
      } = this.state
      
      const {
          fetchAds,
          allAds,
          path,
          me,
          auth,
          myself,
      } = this.props

      return typeof allAds !== 'undefined' && (
          <div className="p-sm-4 p-3 search-container mt-sm-5">
              <Filter search={this._search} advanceSearch={this._advanceSearch} searchKey={searchKey} />
              <TransactionsList allAds={allAds} fetchAds={fetchAds} path={path} me={me} auth={auth} myself={myself} />
          </div>
      )
  }
}

export default Search