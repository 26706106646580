import Referral from '../../components/Referral';
import { connect } from 'react-redux';
import { showFlashMessage } from '../../actions/app'
import { me } from '../../actions/auth';
import { getReferrals, generateReferralCode } from '../../actions/referrals';

const mapActionCreators = {
  me,
  getReferrals,
  generateReferralCode,
  showFlashMessage,
};

const mapStateToProps = (state) => ({
  myself: state.auth.myself,
  referrals: state.referrals.referrals,
});

export default connect(mapStateToProps, mapActionCreators)(Referral);
