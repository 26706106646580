import React, { createRef, Component } from 'react'
import { debounce } from 'lodash'
import _ from 'lodash'
import Search from './Search'
import Assets from './Assets'
import Statistics from './Statistics'
import NewUserModal from '../MyAccount/NewUserModal'
import LoginModal from './LoginModal'
import ForgotPasswordModal from './ForgotPasswordModal'

class Home extends Component {
    constructor() {
        super();

        this.state = {
            total_buy_orders: 0,
            total_sell_orders: 0,
            total_ongoing_orders: 0,
            total_completed_orders: 0,
            searchKey: '',
            isLogginIn: false,
        }

        this._search = this._search.bind(this)
        this.fetchAllAds = this.fetchAllAds.bind(this)
        this.loginModalRef = createRef()
        this.forgotPasswordModalRef = createRef()
    }

    async componentDidMount() {
        const { getTransactionsCount, location: { state }, history } = this.props
        await this.fetchAllAds()
        await getTransactionsCount()

        if (typeof state !== 'undefined' && state.showLoginModal) {
            this._openLoginModal();
            history.replace();
        }
    }

    async fetchAllAds(page = 1) {
        const { fetchBuyAds, fetchSellAds } = this.props
        const { searchKey } = this.state
        await fetchBuyAds(page, searchKey)
        await fetchSellAds(page, searchKey)
    }

    componentDidUpdate(prevProps, nextState) {
        const { auth } = this.props
        if (auth) {
            const pathname = window.location.pathname

            if (pathname === '/'
                && !auth.email
                && !auth.first_name
                && !auth.last_name
                && !auth.password
            ) {
                this._openNewUserModal()
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { buyAds, sellAds, totalOngoing, totalCompleted } = nextProps

        if (buyAds && sellAds) {

            this.setState({
                total_buy_orders: buyAds.data.length,
                total_sell_orders: sellAds.data.length,
                total_ongoing_orders: totalOngoing ? totalOngoing : 0,
                total_completed_orders: totalCompleted ? totalCompleted : 0
            })
        }
    }

    _search(e) {
        e.persist()

        if (!this.debouncedFn) {
            this.debouncedFn = debounce(() => {
                let name = e.target.value

                if (name.length > 0)
                    this.setState({ searchKey: name })

                this.fetchAllAds()

            }, 1000)
        }
        this.debouncedFn()
    };

    _openNewUserModal = () => {
        const { newUserModal } = this.refs
        
        if (newUserModal) {
            newUserModal.setClass('new-user-modal')
            newUserModal.open()
        }
    };

    _openForgotPasswordModal = () => {
        this.forgotPasswordModalRef.current.open()
    }

    _openLoginModal = () => {
        this.loginModalRef.current.open()
        this.setState({
            isLogginIn: false,
        });
    }

    render() {
        const {
            total_buy_orders,
            total_sell_orders,
            total_ongoing_orders,
            total_completed_orders,
            searchKey
        } = this.state
        
        const {
            buyAds,
            sellAds,
            fetchBuyAds,
            fetchSellAds,
            login,
            auth,
            authBase,
            showFlashMessage,
        } = this.props

        return buyAds && sellAds && (
            <div>
                <Search search={this._search} searchKey={searchKey} />
                <Assets buyAds={buyAds} sellAds={sellAds} fetchBuyAds={fetchBuyAds} fetchSellAds={fetchSellAds} />
                <Statistics buy={total_buy_orders} sell={total_sell_orders} ongoing={total_ongoing_orders} completed={total_completed_orders} />
                <NewUserModal ref="newUserModal" />
                <LoginModal ref={this.loginModalRef} login={login} auth={authBase} openRegisterModal={this._openRegisterModal} openForgotPasswordModal={this._openForgotPasswordModal} />
                <ForgotPasswordModal
                    showFlashMessage={showFlashMessage}
                    ref={this.forgotPasswordModalRef}
                />
            </div>
        )
    }
}

export default Home
