/**
 * User Dashboard
 */
import { connect } from 'react-redux'
import Dashboard from '../../components/Dashboard/User/Dashboard'
import { fetchIcoHistory, searchAds } from '../../actions/ads'
import { getUserInteractions } from '../../actions/users'
import { logout } from '../../actions/auth'
import { getMessages } from '../../actions/messages'
import { showFlashMessage } from '../../actions/app'
import { updateTransaction } from '../../actions/transactions'

const mapActionCreators = {
    getMessages,
    showFlashMessage,
    fetchIcoHistory,
    getUserInteractions,
    updateTransaction,
    searchAds,
    logout,
}

const mapStateToProps = state => ({
    myself: state.auth.myself,
    messages: state.messages,
    icoHistory: state.ads.icoHistory,
    dashboardData: state.users.dashboardData,
    ads: state.ads.ads,
})

export default connect(mapStateToProps, mapActionCreators)(Dashboard)
