import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { Switch } from 'react-router';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import FlashMessage from '../containers/FlashMessage';
import Callback from '../containers/Callback';
import Header from '../containers/Home/Header';
import Home from '../containers/Home';
import MyAccount from '../containers/MyAccount';
import Settings from '../containers/Settings';
import EditProfile from '../containers/MyAccount/EditProfile';
import Assets from '../containers/Assets';
import Order from '../containers/Order';
import Footer from '../components/Footer/Footer';
import BrowseOrders from '../containers/Order/Browse';
import AdminDashboard from '../containers/Dashboard';
import UserDashboard from '../containers/Dashboard/UserDashboard';
import Leaderboard from '../containers/Leaderboard';
import MiniApp from '../containers/MiniApp/MiniApp';
import MiniAppHeader from '../containers/MiniApp/Header';
import Asks from '../containers/MiniApp/Asks';
import Bids from '../containers/MiniApp/Bids';
import Post from '../containers/MiniApp/Post';
import Referral from './Referral';
import ResetPassword from './Auth/ResetPassword';
import VerifyUser from './Auth/VerifyUser';
import Search from './Search';

const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => state.auth.token !== null,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

const miniAppUserIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: '/mini-app',
  authenticatedSelector: (state) => state.auth.token !== null,
  wrapperDisplayName: 'UserIsNotAuthenticated',
});

const DefaultLayout = ({ children, ...rest }) => {
  const path = children.props.location.pathname;
  const { location, match } = children.props;
  let addIdMain = true;
  let fullWIdth = false;
  let fullBleed = false;

  if (path === '/me/settings') {
    addIdMain = false;
  }

  if (
    path === '/me/dashboard' ||
    path === '/referrals' ||
    path === '/search' ||
    ((path.includes('sell') || path.includes('buy')) && !path.includes('new'))
  ) {
    fullWIdth = true;
  }

  if (
    path.includes('verify-user') ||
    path.includes('reset-password') ||
    path.includes('/telegram/callback')
  ) {
    fullBleed = true;
  }

  return (
    <div className={fullBleed ? 'full-bleed' : ''}>
      <Header path={path} location={location} match={match} />
      <div
        id={addIdMain ? 'main' : ''}
        className={`${fullWIdth ? 'container-fluid' : 'container'} ${
          fullBleed ? 'full-bleed__container' : ''
        }`}
      >
        <div id="alert-container"></div>
        <FlashMessage />
        {children}
      </div>
      <Footer />
    </div>
  );
};

const DefaultRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <DefaultLayout>
          <Component {...matchProps} {...rest} />
        </DefaultLayout>
      )}
    />
  );
};

const MiniAppRoute = ({ component: Component, ...rest }) => {
  const path = rest.location.pathname;
  const { location, match, history } = rest;
  let hasContainer = true;

  if (path === '/mini-app' || path === '/mini-app/post') {
    hasContainer = false;
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <div className="mini-app">
          <div id="alert-container"></div>
          <FlashMessage />
          <MiniAppHeader
            path={path}
            location={location}
            match={match}
            history={history}
          />

          <div className="container mini-app__container">
            <Component {...matchProps} {...rest} />
          </div>
        </div>
      )}
    />
  );
};

class AppContainer extends Component {
  render() {
    let Dashboard = AdminDashboard;

    if (this.props.myself) {
      let { is_admin } = this.props.myself;
      Dashboard = is_admin === '1' ? AdminDashboard : UserDashboard;
    }

    return (
      <BrowserRouter>
        <Switch>
          <DefaultRoute
            exact
            path="/profile"
            component={userIsNotAuthenticated(MyAccount)}
          />
          <DefaultRoute
            exact
            path="/profile/:id"
            component={userIsNotAuthenticated(MyAccount)}
          />
          <DefaultRoute
            exact
            path="/reset-password/:token"
            component={ResetPassword}
          />
          <DefaultRoute
            exact
            path="/verify-user/:token"
            component={VerifyUser}
          />

          <DefaultRoute
            exact
            path="/me/dashboard"
            component={userIsNotAuthenticated(Dashboard)}
          />
          <DefaultRoute
            exact
            path="/me/edit"
            component={userIsNotAuthenticated(EditProfile)}
          />
          <DefaultRoute
            exact
            path="/me/settings"
            component={userIsNotAuthenticated(Settings)}
          />
          <DefaultRoute
            exact
            path="/me/order"
            component={userIsNotAuthenticated(BrowseOrders)}
          />
          <DefaultRoute
            exact
            path="/order/new"
            component={userIsNotAuthenticated(Assets)}
          />
          <DefaultRoute
            exact
            path="/order/new/:type/:name/:ticker/:asset_type"
            component={userIsNotAuthenticated(Assets)}
          />
          <DefaultRoute
            exact
            path="/order/edit/:id"
            component={userIsNotAuthenticated(Assets)}
          />
          <DefaultRoute
            exact
            path="/trust/leaderboard"
            component={userIsNotAuthenticated(Leaderboard)}
          />
          <DefaultRoute
            exact
            path="/referrals"
            component={userIsNotAuthenticated(Referral)}
          />
          <DefaultRoute
            exact
            path="/search"
            component={userIsNotAuthenticated(Search)}
          />

          {/* Public routes  */}
          <DefaultRoute exact path="/" component={Home} />
          <DefaultRoute
            exact
            path="/referral-code/:referral_code?"
            component={Home}
          />
          <DefaultRoute
            exact
            path="/:full_name/:type/:name/:id"
            component={Order}
          />
          <DefaultRoute path="/telegram/callback" component={Callback} />

          {/* Mini app routes */}
          <MiniAppRoute exact path="/mini-app" component={MiniApp} />
          <MiniAppRoute
            exact
            path="/mini-app/profile/:id"
            component={miniAppUserIsNotAuthenticated(MyAccount)}
          />
          <MiniAppRoute exact path="/mini-app/asks" component={Asks} />
          <MiniAppRoute exact path="/mini-app/bids" component={Bids} />
          <MiniAppRoute exact path="/mini-app/post" component={Post} />
          <MiniAppRoute exact path="/mini-app/search" component={Search} />
          <MiniAppRoute
            exact
            path="/mini-app/:full_name/:type/:name/:id"
            component={Order}
          />
          <MiniAppRoute
            exact
            path="/mini-app/order/new"
            component={miniAppUserIsNotAuthenticated(Assets)}
          />
          <MiniAppRoute
            exact
            path="/mini-app/order/new/:transaction_type/:name/:ticker/:asset_type"
            component={miniAppUserIsNotAuthenticated(Assets)}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  myself: state.auth.myself,
});

export default connect(mapStateToProps)(AppContainer);
