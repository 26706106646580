import PropTypes from "prop-types"
import React from "react"

export function Empty({message = "There is nothing here."}) {
    return (
        <li key="empty-message-alert"  className="list-group-item data">
            {message}
        </li>
    )
}

Empty.propTypes = {
    message: PropTypes.string,
}