import React, { Component, createRef } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tabs from '../../common/Tabs'
import { Empty } from '../../common/Alert'
import ViewNotes from '../Modals/ViewNotes'
import CancelTransactionModal from '../Modals/CancelTransactionModal'

export default class UserLogs extends Component {
    constructor(props) {
        super(props)

        this.cancelTransactionModalRef = createRef()
        this.state = {
            tabs: [
                { display_name: 'Order Activities', name: 'activities' },
                { display_name: 'Transactions', name: 'transactions' },
                { display_name: 'Past Orders', name: 'past' }
            ],
            transactionFilter: 'ongoing',
            showDetails: false
        }

        this._displayDetails = this._displayDetails.bind(this)
        this._setActive = this._setActive.bind(this)
    }

    _displayDetails() {
        const { showDetails } = this.state
        this.setState({ showDetails: !showDetails })
    }

    _openCancelTransactionModal = (event, transaction) => {
        event.preventDefault()
        this.cancelTransactionModalRef.current.toggleOpen(transaction)
    }

    async _setActive(transactionFilter) {
        const { filterChange } = this.props
        this.setState({ transactionFilter, showDetails: false })

        await filterChange(transactionFilter)
    }

    renderDashboardData = (data) => {
        const { activeTab } = this.props

        const { showDetails } = this.state

        if (!data) {
            return <Empty />
        } else if (data && data.length === 0) {
            return <Empty />
        }

        return data.map((d) => {
            const dateCreated = moment(
                d.created_at,
                'YYYY/MM/DD'
            ).format('MMMM')

            const month = dateCreated.substring(0, 3)
            const day = moment(d.created_at, 'YYYY/MM/DD').format('D')

            if (activeTab === 'activities') {
                return (
                    <div key={d.id}>
                        <li className="list-group-item data">
                            <div className="row">
                                <div className="col-1 date text-left">
                                    {month} <br />
                                    <span>{day}</span>
                                </div>
                                <div className="col-4 message text-left">
                                    Received {d.currency} from <br />
                                    <span>{d.from}</span>
                                </div>
                                <div className="col-7 details text-right">
                                    + {d.supply} {d.asset_name}     
                                </div> 
                            </div>
                        </li>
                    </div>
                )
            } else if (activeTab === 'transactions') {
                const type = d.type === 'buy' ? 'Buyer' : 'Seller'
                const { ico } = d
                return (
                    <div key={d.id}>
                        <li className={showDetails ? 'list-group-item data selected' : 'list-group-item data'}>
                            <div className="row">
                                <div className="col-1 date text-left">
                                    {month} <br />
                                    <span>{day}</span>
                                </div>
                                <div className="col-7 message text-left">
                                    Name of {type} <br />
                                    <span>{d.from}</span>
                                </div>
                                <div className="col-3 message text-left">
                                    Price per Token <br />
                                    <span>{d.currency} {d.price_per_token}</span>    
                                </div>
                                <div className="col-1 text-right">
                                    <FontAwesomeIcon icon={!showDetails ? 'chevron-down' : 'chevron-up'} className="mt-3 chevron" onClick={this._displayDetails} />
                                </div>
                            </div>
                            {showDetails && (
                                <div>
                                    <div className="row mt-3">
                                        <div className="col-1"></div>
                                        <div className="col-7 message text-left">
                                            Number of tokens <br />
                                            <span>{d.supply} {ico.symbol}</span>
                                        </div>
                                        <div className="col-3 message text-left">
                                            Deal Status <br />
                                            <p>{d.status.toUpperCase()}</p>    
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-1"></div>
                                        <div className="col-7 message text-left">
                                            Payment Method <br />
                                            <span>{d.currency}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-1"></div>
                                        <div className="col-11">
                                            <hr />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col"></div>
                                        <div className="col-3 text-right">
                                            <NavLink to={`/${d.from}/${d.type}/${ico.name}/${ico.uuid}`}>GO TO DEAL PAGE</NavLink>
                                        </div>
                                        { d.status !== 'failed' &&
                                            <div className="col-3 text-right">
                                                <a href="#" onClick={(e) => this._openCancelTransactionModal(e, d)}>CANCEL TRANSACTION</a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        </li>
                    </div>
                )
            }
        })
    }

    handleTabChanges = async(tab) => {
        if (tab !== 'transactions') {
            this.setState({ transactionFilter: 'ongoing' })
        }
        await this.props.tabChange(tab)
    }

    getOrderDescription = (type, currency) => {
        return `${type.charAt(0).toUpperCase() + type.slice(1)} order in ${currency}:`
    }

    _showToggleViewNotesModal = (order) => {
        const { viewNotes } = this.refs

        viewNotes.open(order)
    }

    _showToggleEditOrder = (order) => {
        const { editOrder } = this.refs

        editOrder.open(order)
    }

    _showToggleEditHistory = (order) => {
        const { editHistory } = this.refs

        editHistory.toggleOpen(order)
    }

    render() {
        const { tabs, transactionFilter } = this.state
        const {
            data,
            activeTab,
            updateTransaction
        } = this.props

        return (
            <div className="user-logs-container">
                <div className="box">
                    <div className="row">
                        <div className="col">
                            <Tabs
                                tabs={tabs}
                                selected={activeTab}
                                onClick={this.handleTabChanges}
                            />
                        </div>
                    </div>
                    <div className="box-list-data">
                        {activeTab === 'transactions' && (
                            <div className="row mb-2 mb-lg-5">
                                <div className="col-lg-6 col-12">
                                    <button
                                        className={transactionFilter === 'ongoing' ? 'btn btn-blue col-12 col-lg-6' : 'btn btn-white col-12 col-lg-6'}
                                        onClick={async() => this._setActive('ongoing')}
                                    >
                                        ONGOING TRANSACTIONS
                                    </button>
                                    <button
                                        className={transactionFilter === 'cancelled' ? 'btn btn-blue col-12 col-lg-6' : 'btn btn-white col-12 col-lg-6'}
                                        onClick={async() => this._setActive('cancelled')}
                                    >
                                        FAILED TRANSACTIONS
                                    </button>
                                </div>
                            </div>
                        )}
                        <ul className="list-group">
                            {this.renderDashboardData(data)}
                        </ul>
                    </div>
                </div>
                <ViewNotes ref="viewNotes" />
                <CancelTransactionModal ref={this.cancelTransactionModalRef} updateTransaction={updateTransaction} />
            </div>
        )
    }
}

UserLogs.propTypes = {
    activeTab: PropTypes.string.isRequired
}
