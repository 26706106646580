import React, { Component } from 'react';
import {
  Accordion,
  Badge,
  Card,
  NavLink,
  ToggleButton,
  ToggleButtonGroup,
  Tabs,
  Tab,
} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import ReactTable from 'react-table';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import copy from 'copy-to-clipboard';
import Loading2 from '../common/Loading2'

class Referral extends Component {
  constructor() {
    super();

    this.state = {
      currentAccordionEventKey: null,
      selectedChartFilter: 'day',
      tabkey: 'referrals',
      isLoading: false,
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    await this.fetchReferrals();

    const { location: { search } } = this.props;
    const params = new URLSearchParams(search);
    const tabkey = params.get('tabkey');

    if (tabkey)
      this._handleOnSelectTabKey(tabkey)

    this.setState({ isLoading: false });
  }

  async fetchReferrals() {
    const { getReferrals } = this.props;
    await getReferrals();
  }

  _handleChartFilterChange = (selectedChartFilter) => {
    this.setState({
      selectedChartFilter,
    });
  };

  _handleSelectAccordion = (currentAccordionEventKey) => {
    this.setState({
      currentAccordionEventKey,
    });
  };

  _handleGenerateReferralCode = async () => {
    const { generateReferralCode, me } = this.props;
    await generateReferralCode();
    await me();
  };

  _handleCopyTextToClipboard(text) {
    const { showFlashMessage } = this.props;

    copy(text);
    showFlashMessage('Copied to Clipboard!');
  }

  _handleOnSelectTabKey(tabkey) {
    const { location, history } = this.props;
    const params = new URLSearchParams({ tabkey });
    history.replace({ pathname: location.pathname, search: params.toString() });
    this.setState({ tabkey });
  }

  noRowsHistoryComponent = (props) => {
    const { myself } = this.props;

    return (
      <div className="referrals__table-no-result" {...props}>
        <img src="referral-icon-grayscale.svg" alt="No referrals" />
        <h2 className="referrals__table-no-result-text">No Referrals Yet</h2>
        <p className="referrals__table-no-result-subtext">
          You have not made any referrals yet. Start inviting friends or <br />
          families to ear rewards
        </p>
        {myself.referral_code ? (
          <div className="referrals__table-no-result-links">
            <div className="copy-content-container">
              <p className="copy-content-label">Your referral code</p>
              <div className="copy-content copy-content--icon-only">
                <p className="copy-content__text">{myself.referral_code}</p>
                <button
                  className="copy-content__button btn btn-transparent btn-no-border"
                  onClick={() =>
                    this._handleCopyTextToClipboard(myself.referral_code)
                  }
                >
                  <img src="copy-blue.svg" />
                  <p>Copy</p>
                </button>
              </div>
            </div>
            <div className="copy-content-container mt-3">
              <p className="copy-content-label">Your referral link</p>
              <div className="copy-content copy-content--icon-only">
                <p className="copy-content__text">
                  {process.env.REACT_APP_FRONTEND_URL.slice(0, 12)}
                  ...{myself.referral_code}
                </p>
                <button
                  className="copy-content__button btn btn-transparent btn-no-border"
                  onClick={() =>
                    this._handleCopyTextToClipboard(
                      `${process.env.REACT_APP_FRONTEND_URL}/referral-code/${myself.referral_code}`
                    )
                  }
                >
                  <img src="copy-blue.svg" />
                  <p>Copy</p>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <button
            className="btn btn-blue mt-3"
            onClick={this._handleGenerateReferralCode}
          >
            GENERATE REFERRAL CODE
          </button>
        )}
      </div>
    );
  };

  render() {
    const { currentAccordionEventKey, selectedChartFilter, tabkey, isLoading } = this.state;
    const { referrals, myself } = this.props;

    const data = {
      day: {
        labels: ['Trades', 'Claims'],
        datasets: [
          {
            data: [1, 1],
            backgroundColor: ['#5E82F6', '#F29543'],
          },
        ],
      },
      week: {
        labels: ['Trades', 'Claims'],
        datasets: [
          {
            data: [10, 5],
            backgroundColor: ['#5E82F6', '#F29543'],
          },
        ],
      },
      month: {
        labels: ['Trades', 'Claims'],
        datasets: [
          {
            data: [20, 30],
            backgroundColor: ['#5E82F6', '#F29543'],
          },
        ],
      },
      year: {
        labels: ['Trades', 'Claims'],
        datasets: [
          {
            data: [50, 35],
            backgroundColor: ['#5E82F6', '#F29543'],
          },
        ],
      },
    };

    const options = {
      cutout: '70%',
      plugins: {
        legend: {
          align: 'start',
          labels: {
            boxWidth: 200,
            color: '#171C34',
            font: {
              family: 'Roboto Regular',
              size: 16,
              weight: 400,
            },
            padding: 20,
            pointStyle: 'circle',
            textAlign: 'right',
            usePointStyle: true,
          },
          position: 'left',
        },
      },
    };

    const columns = [
      {
        id: 'referee',
        Header: 'Name',
        accessor: (a) => (
          <span className="table-row table-normal">{a.referee.full_name}</span>
        ),
      },
      {
        id: 'created_at',
        Header: 'Referral date',
        accessor: (a) => (
          <span className="table-row table-normal">
            {moment(a.created_at).format('MMMM D, YYYY')}
          </span>
        ),
      },
      {
        id: 'commissions',
        Header: 'Commission earned',
        accessor: (a) => {
          const totalCommission = a.commissions.reduce(
            (accumulator, currentValue) => {
              return currentValue.status === 'confirmed' ? accumulator + +currentValue.commission_value : accumulator;
            },
            0
          );

          return (
            <span className="table-row table-normal">
              {totalCommission}
            </span>
          );
        },
      },
      // {
      //   id: 'referral_type',
      //   Header: 'Referral Type',
      //   accessor: (a) => (
      //     <span className="table-row table-normal">
      //       {this._currency(a.ico_price_token, a.currency)} {a.currency}
      //     </span>
      //   ),
      // },
    ];

    // const previousLabel = <FontAwesomeIcon icon="chevron-left" color="#171C34" />,
    //     nextLabel = <FontAwesomeIcon icon="chevron-right" color="#171C34" />,
    //     containerClassName = 'pagination justify-content-center',
    //     pageClassName = 'page-item',
    //     pageLinkClassName = 'page-link',
    //     previousClassName = 'pr-2',
    //     nextClassName='pl-2',
    //     previousLinkClassName='page-link',
    //     nextLinkClassName='page-link'

    if (isLoading) {
      return (
        <Loading2 />
      );
    }

    return (
      <div className="content-container referrals">
        <div className="referrals__head d-flex align-items-center">
          <img src="referral-icon.svg" alt="Referrals" />
          <h1 className="ml-3 mb-0 text-uppercase">Referrals</h1>
        </div>
        <div className="tabs-container tabs-default">
          <Tabs className="tabs" activeKey={tabkey} onSelect={(k) => this._handleOnSelectTabKey(k)}>
            {/* Tab: Referrals */}
            <Tab tabClassName="tab" eventKey="referrals" title="Referrals">
              <div className="tab-content-container referrals__tab-content-referrals">
                {/* Section 1 */}
                <div className="tab-content__section">
                  <div className="tab-content__section-container">
                    <div className="referrals__user-info">
                      <p className="referrals__user-info-greeting">
                        Hi {myself.first_name},
                      </p>
                      <div className="referrals__user-info-commission">
                        <p>Your current commision rate: 20%</p>
                        <a href="#">Complete your KYC</a>
                      </div>
                      <div className="referrals__commision-rates">
                        <div className="referrals__commision-method">
                          <Badge pill className="badge badge--pill badge--grey">
                            Trading
                          </Badge>
                          <div className="mt-3">
                            <p className="referrals__commision-rates-heading">
                              Refer to trade on OTC
                            </p>
                            <ul className="referrals__commision-rates-description-list">
                              <li>
                                <p>If you have not completed KYC - 20%</p>
                              </li>
                              <li>
                                <p>If you have completed KYC - 30%</p>
                              </li>
                              <li>
                                <p>Commission on trade fees for every trade</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="referrals__commision-method">
                          <Badge pill className="badge badge--pill badge--grey">
                            Claim
                          </Badge>
                          <div className="mt-3">
                            <p className="referrals__commision-rates-heading">
                              Refer a FTX claim on OTC
                            </p>
                            <p className="referrals__commision-rates-description">
                              Earn 5% of their claim value* and 30 USDT for
                              every successful FTX claim (*Paid in USDT)
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="referrals__referral-options card">
                      {myself.referral_code ? (
                        <>
                          <div>
                            <p className="copy-content-label">
                              Your referral code
                            </p>
                            <div className="copy-content">
                              <p className="copy-content__text">
                                {myself.referral_code}
                              </p>
                              <button
                                className="copy-content__button btn btn-lg btn-blue"
                                onClick={() =>
                                  this._handleCopyTextToClipboard(
                                    myself.referral_code
                                  )
                                }
                              >
                                <img src="copy.svg" />
                                <p>Copy</p>
                              </button>
                            </div>
                          </div>
                          <div className="mt-3">
                            <p className="copy-content-label">
                              Your referral link
                            </p>
                            <div className="copy-content">
                              <p className="copy-content__text">
                                {process.env.REACT_APP_FRONTEND_URL.slice(
                                  0,
                                  12
                                )}
                                ...{myself.referral_code}
                              </p>
                              <button
                                className="copy-content__button btn btn-lg btn-blue"
                                onClick={() =>
                                  this._handleCopyTextToClipboard(
                                    `${process.env.REACT_APP_FRONTEND_URL}/referral-code/${myself.referral_code}`
                                  )
                                }
                              >
                                <img src="copy.svg" />
                                <p>Copy</p>
                              </button>
                            </div>
                          </div>
                          {/* TODO: Implement later */}
                          {/* <div className='referrals__socials'></div> */}
                        </>
                      ) : (
                        <button
                          className="btn btn-blue"
                          onClick={this._handleGenerateReferralCode}
                        >
                          GENERATE REFERRAL CODE
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                {/* Section 2 */}
                <div className="tab-content__section">
                  <h2 className="text-center">Commission rates and rewards</h2>

                  <div className="referrals__rates-rewards">
                    <div className="card">
                      <h3 className="referrals__rates-rewards-heading">
                        What you get
                      </h3>
                      <p className="referrals__rates-rewards-subheading">
                        Referrer
                      </p>
                      <p className="referrals__rates-rewards-description">
                        Every time someone you’ve invited trades (perps), you
                        receive 20 -30% commission of their trading fees.
                      </p>
                      <div className="referrals__rates-rewards-card-list card-list">
                        <div className="card-list__item card card--no-shadow">
                          <img
                            className="card-list__item-start-img"
                            src="commision-30.svg"
                            alt="30% commission"
                          />
                          <div>
                            <p className="card-list__item-text">
                              30% commission
                            </p>
                            <p className="card-list__item-subtext">
                              You have completed KYC verification
                            </p>
                          </div>
                          <Badge
                            pill
                            className="badge badge--pill badge--grey badge--with-icon"
                          >
                            <img src="mark-check.svg" alt="" />
                            <p>KYC</p>
                          </Badge>
                        </div>
                        <div className="card-list__item card card--no-shadow">
                          <img
                            className="card-list__item-start-img"
                            src="commision-20.svg"
                            alt="20% commission"
                          />
                          <div>
                            <p className="card-list__item-text">
                              20% commission
                            </p>
                            <p className="card-list__item-subtext">
                              You have not completed KYC verification
                            </p>
                          </div>
                          <Badge
                            pill
                            className="badge badge--pill badge--grey badge--with-icon"
                          >
                            <img src="mark-cross.svg" alt="" />
                            <p>KYC</p>
                          </Badge>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <h3 className="referrals__rates-rewards-heading">
                        What they get
                      </h3>
                      <p className="referrals__rates-rewards-subheading">
                        Referee
                      </p>
                      <p className="referrals__rates-rewards-description">
                        Referred members are instantly offered the following
                        rewards after a successful trade or claim:
                      </p>
                      <div className="referrals__rates-rewards-card-list card-list">
                        <div className="card-list__item card card--no-shadow">
                          <img
                            className="card-list__item-start-img"
                            src="vip-lvl-3.svg"
                            alt="Indefinite VIP level 3 status"
                          />
                          <p className="card-list__item-text">
                            Indefinite VIP level 3 status
                          </p>
                        </div>
                        <div className="card-list__item card card--no-shadow">
                          <img
                            className="card-list__item-start-img"
                            src="reduced-trading-fee.svg"
                            alt="Reduced trading fees"
                          />
                          <p className="card-list__item-text">
                            Reduced trading fees
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Section 3 */}
                <div className="tab-content__section">
                  <h2 className="text-center">How it works</h2>

                  <div className="referrals__how-it-works-list card-list card-list--horizontal card-list--h-stretch card-list--full-item ">
                    <div className="card-list__item card card--no-shadow card--custom-padding">
                      <p className="card-list__item-text">Sign up</p>
                      <p className="card-list__item-subtext">
                        Sign up for an OTC account.
                      </p>
                      {/* <button className="card-list__item-btn btn btn-lg btn-blue">
                        Create an account
                      </button> */}
                    </div>
                    <div className="card-list__item card card--no-shadow card--custom-padding">
                      <p className="card-list__item-text">Invite</p>
                      <p className="card-list__item-subtext">
                        Invite friends and family by using your referral link or
                        code.
                      </p>
                    </div>
                    <div className="card-list__item card card--no-shadow card--custom-padding">
                      <p className="card-list__item-text">Trade</p>
                      <p className="card-list__item-subtext">
                        Get your friend or family members to make a deposit and
                        trade.
                      </p>
                    </div>
                    <div className="card-list__item card card--no-shadow card--custom-padding">
                      <p className="card-list__item-text">Rewards</p>
                      <p className="card-list__item-subtext">
                        Get rewarded 20-30% commission of their trading fee
                        every time they trade.
                      </p>
                    </div>
                  </div>
                </div>

                {/* TODO: Update the content */}
                {/* Section 4 */}
                {/* <div className="tab-content__section">
                  <h2 className="text-center">Frequently Asked Questions</h2>
                  <Accordion
                    className="referrals__faq-accordion"
                    onSelect={this._handleSelectAccordion}
                  >
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        <p>
                          How long does the referral code and invite link last?
                        </p>
                        <FontAwesomeIcon
                          icon={
                            currentAccordionEventKey == 0 ? 'minus' : 'plus'
                          }
                        />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>Hello! I'm the body</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        <p>
                          Is there a limit to how many referrals I can make?
                        </p>
                        <FontAwesomeIcon
                          icon={
                            currentAccordionEventKey == 1 ? 'minus' : 'plus'
                          }
                        />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>Hello! I'm another body</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="2">
                        <p>When will I receive my reward?</p>
                        <FontAwesomeIcon
                          icon={
                            currentAccordionEventKey == 2 ? 'minus' : 'plus'
                          }
                        />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>Hello! I'm another body</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="3">
                        <p>How do I claim my reward?</p>
                        <FontAwesomeIcon
                          icon={
                            currentAccordionEventKey == 3 ? 'minus' : 'plus'
                          }
                        />
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>Hello! I'm another body</Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div> */}
              </div>
            </Tab>
            
            {/* TODO: Clarify the functionality */}
            {/* Tab: Dashboard */}
            {/* <Tab tabClassName="tab" eventKey="dashboard" title="Dashboard">
              <div className="tab-content-container referrals__tab-content-dashboard">
                <div className="referrals__dashboard-cards">
                  <div className="card-list">
                    <div className="card-list__item card card--no-shadow">
                      <p className="h2 font-weight-bold">Total referrals</p>
                      <p className="referrals__dashboard-cards-value">0</p>
                    </div>
                    <div className="card-list__item card card--no-shadow">
                      <p className="h2 font-weight-bold">Total rewards</p>
                      <div className="referrals__dashboard-total-rewards">
                        <div>
                          <p className="referrals__dashboard-cards-label">
                            Trades
                          </p>
                          <p className="referrals__dashboard-cards-value">0</p>
                        </div>
                        <div>
                          <p className="referrals__dashboard-cards-label">
                            Claims
                          </p>
                          <p className="referrals__dashboard-cards-value">
                            0 USDT
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="referrals__dashboard-chart">
                  <div className="referrals__chart-container">
                    <h2 className="font-weight-bold">Total Referrals</h2>
                    <Doughnut
                      className="referrals__chart"
                      data={data[selectedChartFilter]}
                      options={options}
                    />
                  </div>
                  <ToggleButtonGroup
                    className="referrals__chart-filter"
                    type="radio"
                    name="chart-filter"
                    defaultValue="day"
                    onChange={this._handleChartFilterChange}
                  >
                    <ToggleButton
                      className="btn-light--transparent-bg"
                      variant="light"
                      value="day"
                    >
                      1D
                    </ToggleButton>
                    <ToggleButton
                      className="btn-light--transparent-bg"
                      variant="light"
                      value="week"
                    >
                      1W
                    </ToggleButton>
                    <ToggleButton
                      className="btn-light--transparent-bg"
                      variant="light"
                      value="month"
                    >
                      1M
                    </ToggleButton>
                    <ToggleButton
                      className="btn-light--transparent-bg"
                      variant="light"
                      value="year"
                    >
                      1Y
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            </Tab> */}

            {/* Tab: History */}
            <Tab tabClassName="tab" eventKey="history" title="History">
              <ReactTable
                className="text-center mb-3 -highlight"
                // getTrProps={this._rowSelected}
                columns={columns}
                data={referrals}
                pageSize={10}
                minRows={2}
                manual
                NoDataComponent={this.noRowsHistoryComponent}
                showPageJump={false}
                showPageSizeOptions={false}
                showPagination={false}
              />
              {/* <ReactPaginate
                previousLabel={previousLabel}
                nextLabel={nextLabel}
                pageCount={buyAds.last_page}
                onPageChange={this._handleBuyPageClick}
                containerClassName={containerClassName}
                pageClassName={pageClassName}
                pageLinkClassName={pageLinkClassName}
                previousClassName={previousClassName}
                nextClassName={nextClassName}
                previousLinkClassName={previousLinkClassName}
                nextLinkClassName={nextLinkClassName}
              /> */}
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

export default Referral;
