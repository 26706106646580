import React from 'react'
import BaseModal from '../common/BaseModal'
import Web3 from 'web3'
import { bufferToHex } from 'ethereumjs-util'
import { recoverPersonalSignature } from 'eth-sig-util'
import WalletConnect from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";

let web3
export default class ConnectWalletModal extends BaseModal {
    constructor(props) {
        super(props)

        this.state = {
            hasConnectedtoMetamask: false,
            connector: null,
            fetching: false,
            connected: false,
            chainId: 1,
            accounts: [],
            address: '',
        }
    }

    async componentDidMount() {
        await this.setSettings({
            dialogClassName: 'modal-dialog-centered',
            onClickBackdropClose: true,
            showModalClose: true,
        });
    }

    componentDidUpdate(prevProps) {
        const { hasConnectedtoMetamask } = this.props;

        if (prevProps.hasConnectedtoMetamask !== hasConnectedtoMetamask) {
            this.setState({ hasConnectedtoMetamask })
        }
    }

    metamaskConnectHandler = async() => {
        const {
            connectWallet,
            initiateConnectionToMetaMask,
            me,
        } = this.props
        
        const { hasConnectedtoMetamask } = this.state

        if (!hasConnectedtoMetamask) {
            if (!window.ethereum) {
                window.alert.error('Please install Metamask first.')
                return
            }

            if (web3) {
                web3 = null;
            }

            await window.ethereum.enable()
            web3 = new Web3(window.ethereum)
            const coinbase = await web3.eth.getCoinbase()

            if (!coinbase) {
                window.alert.error('Please activate MetaMask first.')
                return;
            }

            const publicAddress = coinbase.toLowerCase()
            await connectWallet({ 'public_address': publicAddress, 'wallet': 'metamask' })
            await me();
            await this.handleSignMessage()
        } else {
            await me();
            const { myself } = this.props
            if (myself) initiateConnectionToMetaMask(myself.public_address)
        }
    }

    handleSignMessage = async() => {
        const {
            initiateConnectionToMetaMask,
            myself,
        } = this.props

        try {
            const signature = await web3.eth.personal.sign(
                `I am signing my one-time nonce: ${myself.nonce}`,
                myself.public_address,
                '' // MetaMask will ignore the password argument here
            )

            const msg = `I am signing my one-time nonce: ${myself.nonce}`
            const msgBufferHex = bufferToHex(Buffer.from(msg, 'utf8'))
            const address = recoverPersonalSignature({
                data: msgBufferHex,
                sig: signature
            })

            if (address.toLowerCase() === myself.public_address.toLowerCase()) {
                if (!myself.is_legal) {
                    initiateConnectionToMetaMask(myself.public_address)
                }
            } else {
                window.alert.error('Signature verification failed.')
            }
        } catch (err) {
            console.log(err)
            window.alert.error('You need to sign the message to be able to log in.')
            return
        }
    }
    /** MetaMask Integration End */

    /** WalletConnect Integration Start */
    walletConnectInit = async () => {
        // bridge url
        const bridge = 'https://bridge.walletconnect.org'
    
        // create new connector
        const connector = new WalletConnect({ bridge, qrcodeModal: QRCodeModal })
        await this.setState({ connector })
        // check if already connected
        if (!connector.connected) {
          // create new session
          await connector.createSession()
        }
    
        // subscribe to events
        await this.subscribeToEvents()
    };

    subscribeToEvents = () => {
        const { connector } = this.state
        const { logout } = this.props

        if (!connector) {
            return
        } else {
            const walletConnectJSON = JSON.stringify(connector)
            localStorage.setItem('walletConnect', walletConnectJSON)
        }
    
        connector.on('session_update', async (error, payload) => {   
            if (error) {
                throw error
            }

            const { chainId, accounts } = payload.params[0]
            this.onSessionUpdate(accounts, chainId)
        });
    
        connector.on('connect', (error, payload) => {
            if (error) {
                throw error
            }
        
            this.onConnect(payload)
        });
    
        connector.on('disconnect', (error) => {
            if (error) {
                throw error
            }
        
            this.resetApp()
            logout()
        })
    
        if (connector.connected) {
            const { chainId, accounts } = connector
            const address = accounts[0]
            this.setState({
                connected: true,
                chainId,
                accounts,
                address,
            })
            this.onSessionUpdate(accounts, chainId)
        }
    
        this.setState({ connector })
    }

    killSession = async() => {
        const { connector } = this.state
        if (connector) {
            connector.killSession()
        }
        
        this.resetApp()
    }

    onSessionUpdate = async (accounts, chainId) => {
        const address = accounts[0]
        await this.setState({ chainId, accounts, address })
        await this.getAssets(true)

        window.alert.success('Walletconnect session updated.')
    }

    onConnect = async (payload) => {
        const { connectWallet, me } = this.props
        const { chainId, accounts } = payload.params[0]
        const address = accounts[0]
        await this.setState({
            connected: true,
            chainId,
            accounts,
            address,
        })

        await connectWallet({ 'public_address': address, 'wallet': 'walletconnect' })
        me();
        await this.getAssets()
    }

    getAssets = async(update = false) => {
        const {
            myself,
            getAccountAssets,
            initiateConnectionToWalletConnect
        } = this.props
        
        const { address, chainId } = this.state
        this.setState({ fetching: true })

        try {
            await getAccountAssets(address, chainId)
            const { accountAssets } = this.props
            this.setState({ fetching: false, address, assets: accountAssets })
            if (!update) {
                if (!myself.is_legal) {
                    initiateConnectionToWalletConnect(myself.public_address)
                }
            }
        } catch (err) {
            this.setState({ fetching: false })
        }
    }

    resetApp = () => {
        this.setState({
            connected: false,
            fetching: false,
            chainId: 1,
            accounts: [],
            address: ''
        });
    }
    /** WalletConnect Integration End */
    
    renderHeader = () => {
        return <h2 className="mb-4">Connect your wallet</h2>
    }

    renderBody = () => {    
        // const { metamaskConnectHandler, walletConnectHandler } = this.props;
        return <div className="pb-5">
            <button className="btn btn-blue btn-block" onClick={() => { this.metamaskConnectHandler(); this.close() }}>
                <img alt="metamask" src="/metamask.svg" height="32px" width="32px"/>
                <span className="ml-1">Metamask</span>
            </button>
            <button className="btn btn-blue btn-block" onClick={() => { this.walletConnectInit(); this.close() }}>
                <img alt="metamask" src="/walletconnect.svg"/>
                <span className="ml-1">WalletConnect</span>
            </button>
        </div>
    }
}
