import React from 'react';
import BaseModal from '../common/BaseModal';

export default class NewUserModal extends BaseModal {
    
    // constructor(props) {
    //     super(props);
    // }

    renderHeader = () => {
        return <h2 className="mb-4">Welcome!</h2>
    }

    renderBody = () => {        
        return <div>
            Welcome, new User! Here at The OTC Room we encourage
            you to fill up your profile details <a href="/me/edit" onClick={this.close}>here</a>.
        </div>
    }

    renderFooter = () => {
        return (
            <div className="row mt-4">
                <div className="col-12 text-right buttons p-0" style={{marginBottom: 30}}>
                    <button className="btn btn-blue" onClick={this.close}>CLOSE</button>
                </div>
            </div>
        );
    }
}