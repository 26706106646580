import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_HOST
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"
axios.defaults.headers.common["Accept"] = "application/json"

axios.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (!error.status) {
            if (Object.prototype.hasOwnProperty.call(error, "response")) {
                if (error.response === undefined) {
                    // Network error - no internet connection
                    window.alert.error(`Please check your internet connection.`)
                    return Promise.reject(error)
                }

                const { response: { data } } = error
                window.alert.error(data.error)

                return Promise.reject(data)
            }
        }
    }
)
