import { RSAA } from 'redux-api-middleware'

export const CREATE_REVIEW = 'auth:create_review'
export const CREATE_REVIEW_SUCCESS = 'auth:create_review_success'
export const CREATE_REVIEW_FAILURE = 'auth:create_review_failure'

export function createReview(id, data) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/order/${id}/review`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(data),
                types: [CREATE_REVIEW, CREATE_REVIEW_SUCCESS, CREATE_REVIEW_FAILURE]
            }
        })
    }
}

const ACTION_HANDLERS = {
    [CREATE_REVIEW]: state => ({ ...state, createReview: true, createReviewSuccess: false, createReviewFailure: false }),
    [CREATE_REVIEW_SUCCESS]: state => ({ ...state, createReview: false, createReviewSuccess: true, createReviewFailure: false }),
    [CREATE_REVIEW_FAILURE]: state => ({ ...state, createReview: false, createReviewSuccess: false, createReviewFailure: true })
}

const initialState = {
    createReview: false,
    createReviewSuccess: false,
    createReviewFailure: false
}

export default function reviews (state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}