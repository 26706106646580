import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

class LoggedInMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            myself: {
                first_name: '',
                last_name: '',
                profile_picture: '',
            },
            isLoading: true
        }
    }

    componentWillMount() {
        const root = JSON.parse(localStorage.getItem('persist:root'))
        const auth = root && JSON.parse(root.auth);

        if (auth && auth.myself) {
            this.setState({
                myself: auth.myself
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        const { myself } = nextProps

        if (myself) {
            this.setState({ myself })
        }
    }

    render() {
        const { logout, myself, displayReconnect, reconnectWallet } = this.props

        return (
            <div className="d-none d-md-block d-lg-block d-xl-block">
                <div className="logged-in dropdown media">
                    <img
                        className="align-self-start mr-3"
                        src={myself.profile_picture || '/no-user-image.jpg'}
                        alt={myself.full_name}
                    />
                    <h5 className="dropdown-toggle" data-toggle="dropdown">
                        {myself.full_name}
                    </h5>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <NavLink className="dropdown-item" to="/me/dashboard">
                            Dashboard
                        </NavLink>
                        <NavLink className="dropdown-item" to="/me/edit">
                            Edit Profile
                        </NavLink>
                        <NavLink exact className="dropdown-item" to="/profile">
                            View Profile
                        </NavLink>
                        <NavLink className="dropdown-item" to="/trust/leaderboard">
                            Trust Leaderboard
                        </NavLink>
                        <NavLink className="dropdown-item" to="/referrals">Referrals</NavLink>
                        <NavLink className="dropdown-item" to="/me/settings">Settings</NavLink>
                        { displayReconnect 
                            ? <button className="dropdown-item" onClick={reconnectWallet}>
                                <img className="wallet-connect mr-1" alt="metamask" src="/walletconnect.svg" height="20px" width="20px"/>Reconnect
                            </button> 
                            : '' 
                        }
                        <NavLink className="dropdown-item" to="#" onClick={logout}>
                            Logout
                        </NavLink>
                    </ul>
                </div>
            </div>
        )
    }
}

LoggedInMenu.propTypes = {
    myself: PropTypes.object.isRequired
}

export default LoggedInMenu
