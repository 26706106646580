import React, { Component } from "react";
import { timeFromNow } from "../../../modules/Utilities";

class RecentMessages extends Component {
    constructor(props) {
        super(props);

        this.state = {

        }

    }

    _renderMessages = (messages, index) => {
        const { speaker, created_at, data } = messages;

        if (index <= 3) {
            return <div key={index}>
                <div className="text-center messages-container">
                    <div className="row message">
                        <div className="col-3">
                            <img src="/no-user-image.jpg" className="rounded-circle" alt="user" />
                        </div>
                        <div className="col-9 details">
                            <div className="message-header text-left">{speaker.full_name}<span>{timeFromNow(created_at)}</span></div>
                            <div className="message text-left">
                                {data.message}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else {
            return '';
        }
    }

    render() {
        const { messages: {messages} } = this.props;
        return (
            <div className="recent-messages-container">
                <div className="box">
                    <div className="header-title">Recent Messages</div>
                    <div className="box-header-border-bottom"></div>
                    { messages.length === 0 
                        ? <div className="text-center messages-container">Nothing here.</div> 
                        : messages.map(this._renderMessages)
                    }
                </div>
            </div>
        )
    }
}

export default RecentMessages;
