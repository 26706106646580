import React, { Component } from 'react'
import cx from 'classnames'

export default class OrderType extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { clickHandler, type, editMode } = this.props
        if (editMode) {
            return <div className="inner-block">
                <h4 className="session-title">Order Type: {type.toUpperCase()}</h4>
            </div>
        } else {
            return <div className="inner-block">
                <h4 className="session-title">Order Type</h4>
                <div className="row p-o">
                    <div className="col-lg-4">
                        <button className={cx('btn btn-blue w-100', type === 'buy' && 'inactive')} onClick={() => clickHandler('sell')}>SELL</button>
                    </div>
                    <div className="col-lg-4">
                        <button className={cx('btn btn-blue w-100', type === 'sell' && 'inactive')} onClick={() => clickHandler('buy')}>BUY</button>
                    </div>
                </div>
            </div>
        }
    }
}
