import Assets from '../../components/Assets'
import { connect } from 'react-redux'
import { createAd, fetchIcoDetails, updateAd } from '../../actions/ads'

const mapActionCreators = {
    createAd,
    fetchIcoDetails,
    updateAd
}

const mapStateToProps = state => ({
    ads: state.ads,
    icoDetails: state.ads.icoDetails
})

export default connect(mapStateToProps, mapActionCreators)(Assets)
