import { connect } from 'react-redux'
import { getTrustScores } from '../../actions/users'
import List from '../../components/Leaderboard/List'

const mapActionCreators = {
    getTrustScores
}

const mapStateToProps = state => ({
    rankings: state.users.rankings
})

export default connect(mapStateToProps, mapActionCreators)(List);