import React, { Component } from "react"
import { NavLink, withRouter } from "react-router-dom"
import NumberFormat from "react-number-format"
import ReactTable from "react-table"
import moment from "moment"
import ReactPaginate from 'react-paginate'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from '../../../common/Loading2';

class Result extends Component {
    constructor(props) {
        super(props)

        this.state = {
            selected: null,
        }
    }

    _currency = (val) => {
        return (
            <NumberFormat
                thousandSeparator=","
                decimalSeparator="."
                decimalScale={2}
                value={val}
                displayType="text"
            />
        )
    }

    _rowSelected = (state, row) => {
        if (row && row.row) {
            return {
                onClick: (e) => {
                    this.props.history.push(`/${row.original.user.full_name}/${row.original.type}/${row.original.name}/${row.original.uuid}`)
                },
            }
        }
        return {}
    }

    _handlePageClick = async (data) => {
        const { search, searchPayload } = this.props
        const selected = data.selected + 1

        await search({...searchPayload, page: selected})
    }

    render() {
        const { ads, isShowLoading } = this.props

        const columns = [{
            id: 'name',
            Header: "Asset",
            accessor: a => <NavLink to={`/${a.user.full_name}/${a.type}/${a.name}/${a.uuid}`} className="table-row table-normal text-primary font-weight-bold">{a.name}</NavLink>
        }, {
            id: 'type',
            Header: 'Type',
            accessor: a => <span className="table-row">{a.type.toUpperCase()}</span>,
        }, {
            id: 'supply',
            Header: 'Supply',
            accessor: a => <span className="table-row" style={{ color: '#E29000' }} options={{ numeral: true, numeralThousandsGroupStyle: 'thousand'}}>{a.supply.toLocaleString()}</span>,
        }, {
            id: 'price_per_token',
            Header: 'Price Per Token',
            accessor: a => <span className="table-row table-normal">{this._currency(a.ico_price_token)} {a.currency}</span>
        }, {
            id: 'created_at',
            Header: 'Post Date',
            accessor: a => <span className="table-row table-normal">{moment(a.created_at).format("MMMM D, YYYY")}</span>
        }, {
            id: 'owner',
            Header: 'Owner',
            accessor: a => <span onClick={e => e.stopPropagation()}><NavLink to={`/profile/${a.user_id}`} className="table-row table-normal text-primary font-weight-bold">{a.user.first_name} {a.user.last_name}</NavLink></span>,
        }];

        const previousLabel = <FontAwesomeIcon icon="chevron-left" color="#171C34" />,
            nextLabel = <FontAwesomeIcon icon="chevron-right" color="#171C34" />,
            containerClassName = 'pagination justify-content-center',
            pageClassName = 'page-item',
            pageLinkClassName = 'page-link',
            previousClassName = 'pr-2',
            nextClassName='pl-2',
            previousLinkClassName='page-link',
            nextLinkClassName='page-link'


        return (
            <div className="row">
                <div className="col-12">
                    {isShowLoading && <Loading />}
                    {(ads && !isShowLoading) &&
                        <div className="mb-5">
                            <ReactTable
                                className="text-center mb-3 -highlight"
                                getTrProps={this._rowSelected}
                                columns={columns}
                                data={ads.data}
                                pageSize={10}
                                minRows={2}
                                showPageJump={false}
                                showPageSizeOptions={false}
                                showPaginationBottom={false}
                            />
                            <ReactPaginate
                                previousLabel={previousLabel}
                                nextLabel={nextLabel}
                                pageCount={ads.last_page}
                                onPageChange={this._handlePageClick}
                                containerClassName={containerClassName}
                                pageClassName={pageClassName}
                                pageLinkClassName={pageLinkClassName}
                                previousClassName={previousClassName}
                                nextClassName={nextClassName}
                                previousLinkClassName={previousLinkClassName}
                                nextLinkClassName={nextLinkClassName}
                            />
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(Result)