import React, { Component } from 'react'
import cx from 'classnames'
import Loading2 from '../../common/Loading2'

export default class Statistics extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            buy: 0,
            sell: 0,
            ongoing: 0,
            completed: 0,
            users: 0,
            isLoading: true
        }

        this._fetchStatistics = this._fetchStatistics.bind(this)
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            await this._fetchStatistics()
        }

    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    async _fetchStatistics() {
        const { adminGetStatistics } = this.props
        await adminGetStatistics()
        this.setState({ isLoading: false })
    }

    _renderCardBody = (image, value, label) => {
        const { isLoading } = this.state

        return (
            <div className="statistic col-md-4 col-sm-12 col-xs-12">
                <div className="session-block">
                    {
                        isLoading
                        ? <Loading2/>
                        : <div className="media">
                            <div className={cx("img text-center", image)}>
                                <img src={`/${image}.svg`} alt="loading"/>
                            </div>
                            <div className="media-body">
                                <h5 className="mt-0">{value}</h5>
                                <span>{label}</span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    };

    render() {
        const { statistics } = this.props

        return (
            <div className="statistics col-12 p-0">
                <div className="row">
                    <div className="col-12 text-center pl-0 pr-0">
                        <div className="row">
                            {this._renderCardBody('home-buy-orders', statistics ? statistics.buy_orders : 0, 'Buy Orders')}
                            {this._renderCardBody('home-sell-orders', statistics ? statistics.sell_orders : 0, 'Sell Orders')}
                            {this._renderCardBody('home-ongoing-deals', statistics ? statistics.ongoing_orders : 0, 'Ongoing Orders')}
                            {this._renderCardBody('home-completed-deals', statistics ? statistics.completed_orders : 0, 'Deals Completed')}
                            {this._renderCardBody('total-users', statistics ? statistics.users_count : 0, 'Total Users')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
