import React from "react";
import BaseModal from "../common/BaseModal";

class ConfirmModal extends BaseModal {
    constructor(props) {
        super(props);
    }

    renderBody = () => {
        const { email } = this.props;

        // const { first_name, last_name, email, password } = this.state;
        return (
            <div className="confirm-modal text-center">
                <img src="/register_confirm.svg" alt="confirm registration" />
                <h3>Thanks for Signing Up!</h3>
                <p>
                    Please check your email and hit the verification button in the message that we just sent to { email }
                </p>
            </div>
        );
    }

    renderFooter = () => {

        return (
            <div className="row">
                <div
                    className="col-12 text-right buttons"
                    style={{ marginBottom: 30 }}
                >
                    <button
                        className="btn btn-blue"
                        onClick={() => this.close()}
                    >
                        Okay
                    </button>
                </div>
            </div>
        );
    }
}

export default ConfirmModal;
