import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

class Textarea extends Component {
  render() {
    const { label, name, value, placeholder, className, onChange, cols, full } = this.props;

    return (
        <div className={full ? "form-group w-100" : "form-group"}>
            {label && <label>{label}</label>}
            <textarea
                name={name}
                className={cx("form-control", className)}
                placeholder={placeholder}
                onChange={onChange}
                cols={cols}
                value={value}
                rows="7"
            ></textarea>
        </div>
    )
  }
}

Textarea.propTypes = {
    label: PropTypes.string,
    cols: PropTypes.number,
    full: PropTypes.bool,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

Textarea.defaultProps = {
    isSmall: false,
    full: false,
    cols: 7,
}

export default Textarea;
