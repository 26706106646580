import { RSAA } from 'redux-api-middleware'

// export const CREATE_REFERRAL = 'auth:create_referral'
// export const CREATE_REFERRAL_SUCCESS = 'auth:create_referral_success'
// export const CREATE_REFERRAL_FAILURE = 'auth:create_referral_failure'
export const GENERATE_REFERRAL_CODE = 'auth:generate_referral_code'
export const GENERATE_REFERRAL_CODE_SUCCESS = 'auth:generate_referral_code_success'
export const GENERATE_REFERRAL_CODE_FAILURE = 'auth:generate_referral_code_failure'
export const GET_REFERRALS = 'auth:get_referrals'
export const GET_REFERRALS_SUCCESS = 'auth:get_referrals_success'
export const GET_REFERRALS_FAILURE = 'auth:get_referrals_failure'

// export function createReferral(data){
//     return (dispatch, getState) => {
//         const { auth: { token } } = getState()

//         return dispatch({
//             [RSAA]: {
//                 endpoint: `${process.env.REACT_APP_API_HOST}/referral`,
//                 method: 'POST',
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                     'Content-Type': 'application/json; charset=UTF-8',
//                 },
//                 body: JSON.stringify(data),
//                 types: [CREATE_REFERRAL, CREATE_REFERRAL_SUCCESS, CREATE_REFERRAL_FAILURE]
//             }
//         })
//     }
// }

export function getReferrals() {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/referral`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                types: [GET_REFERRALS, GET_REFERRALS_SUCCESS, GET_REFERRALS_FAILURE]
            }
        })
    }
}

export function generateReferralCode() {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/referral/generate-referral-code`,
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(),
                types: [GENERATE_REFERRAL_CODE, GENERATE_REFERRAL_CODE_SUCCESS, GENERATE_REFERRAL_CODE_FAILURE]
            }
        })
    }
}

const ACTION_HANDLERS = {
    // [CREATE_REFERRAL]: state => ({ 
    //     ...state, 
    //     createReferral: true, 
    //     createReferralSuccess: false, 
    //     createReferralFailure: false 
    // }),
    // [CREATE_REFERRAL_SUCCESS]: state => ({ 
    //     ...state, 
    //     createReferral: false, 
    //     createReferralSuccess: true, 
    //     createReferralFailure: false 
    // }),
    // [CREATE_REFERRAL_FAILURE]: state => ({ 
    //     ...state, 
    //     createReferral: false, 
    //     createReferralSuccess: false, 
    //     createReferralFailure: true 
    // }),
    [GENERATE_REFERRAL_CODE]: state => ({ 
        ...state, 
        generateReferralCode: true, 
        generateReferralCodeSuccess: false, 
        generateReferralCodeFailure: false 
    }),
    [GENERATE_REFERRAL_CODE_SUCCESS]: state => ({ 
        ...state, 
        generateReferralCode: false, 
        generateReferralCodeSuccess: true, 
        generateReferralCodeFailure: false 
    }),
    [GENERATE_REFERRAL_CODE_FAILURE]: state => ({ 
        ...state, 
        generateReferralCode: false, 
        generateReferralCodeSuccess: false, 
        generateReferralCodeFailure: true 
    }),
    [GET_REFERRALS]: state => ({
        ...state,
        getReferrals: true,
        getReferralsSuccess: false,
        getReferralsFailure: false
    }),
    [GET_REFERRALS_SUCCESS]: (state, action) => ({
        ...state,
        getReferrals: false,
        getReferralsSuccess: true,
        getReferralsFailure: false,
        referrals: action.payload.referrals
    }),
    [GET_REFERRALS_FAILURE]: state => ({
        ...state,
        getReferrals: false,
        getReferralsSuccess: false,
        getReferralsFailure: true
    })
}

const initialState = {
  // createReferral: false,
  // createReferralSuccess: false,
  // createReferralFailure: false,
  generateReferralCode: false,
  generateReferralCodeSuccess: false,
  generateReferralCodeFailure: false,
  getReferrals: false,
  getReferralsSuccess: false,
  getReferralsFailure: false,
  referrals: []
}

export default function comments (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}