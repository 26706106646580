import React, { Component, createRef } from 'react'
import { Link, NavLink } from 'react-router-dom'
import cx from 'classnames'
import LoggedInMenu from './LoggedInMenu'
import Notification from './Notification'
import TermsOfServiceModal from '../Header/TermsOfServiceModal'
import ConnectWalletModal from '../Header/ConnectWalletModal'
import ConfirmModal from '../Home/ConfirmModal'
import NotificationModal from './NotificationModal'
import RegisterModal from '../Home/RegisterModal'
import LoginModal from '../Home/LoginModal'
import ForgotPasswordModal from '../Home/ForgotPasswordModal'

class Header extends Component {
    constructor(props) {
        super(props)
            
        this.state = {
            isLogginIn: false,
            isRegistering: false,
            hasConnectedtoMetamask: false,
            hasConnectedtoWalletConnect: false,
            hasAcceptedTOS: false,
            publicAddress: null,
            connector: null,
            email: null,
            referralCode: null,
        }

        this.reconnectWallet = this.reconnectWallet.bind(this)

        this.notificationModalRef = createRef()
        this.connectModalRef = createRef()
        this.tosModalRef = createRef()
        this.confirmModalRef = createRef()
        this.registerModalRef = createRef()
        this.loginModalRef = createRef()
        this.forgotPasswordModalRef = createRef()
    }

    componentDidMount = async() => {
        const { match: { params: { referral_code: referralCode } } } = this.props
        this.setState({ referralCode });

        const { me, getNotifications } = this.props
        const auth = JSON.parse(localStorage.getItem('persist:root'))
        const token = auth && JSON.parse(auth.auth)

        if (token && token.token) {
            me()
            getNotifications()
        }
    }

    componentDidUpdate(prevProps) {
        const { me, auth, getNotifications } = this.props

        if (auth.token !== prevProps.auth.token) {
            me()
            getNotifications()
        }
    }

    componentWillUpdate(nextProps, nextState) {
        const { hasConnectedtoMetamask, hasConnectedtoWalletConnect, hasAcceptedTOS } = nextState

        if (hasConnectedtoMetamask || hasConnectedtoWalletConnect) {
            if (!hasAcceptedTOS) {
                this.tosModalRef.current.setClass('tos-modal')
                this.tosModalRef.current.open()
            }
        }
    }

    _openConnectModal = () => {
        this.connectModalRef.current.open()
    }

    _openRegisterModal = () => {
        this.registerModalRef.current.open()
        this.setState({
            isLogginIn: true,
            isRegistering: false,
        });
    }

    _openForgotPasswordModal = () => {
        this.forgotPasswordModalRef.current.open()
    }

    _openLoginModal = () => {
        this.loginModalRef.current.open()
        this.setState({
            isLogginIn: false,
            isRegistering: true,
        });
    }

    onLogout = async() => {
        const { logout } = this.props

        await this.connectModalRef.current.killSession()

        await logout()
    }

    _openConfirmModal = (email) => {
        this.setState({ email });
        this.confirmModalRef.current.setClass('confirm-modal')
        this.confirmModalRef.current.open()
    }

    _openNotificationModal = () => {
        this.notificationModalRef.current.setClass('notification-modal')
        this.notificationModalRef.current.open()
    }

    _onAccept = async() => {
        const { publicAddress } = this.state
        const { updateNonce } = this.props
        this.setState({ hasAcceptedTOS: true })

        await updateNonce(publicAddress)
    }

    initiateConnectionToMetaMask = (publicAddress) => {
        this.setState({ hasConnectedtoMetamask: true, publicAddress })
    }

    initiateConnectionToWalletConnect = (publicAddress) => {
        this.setState({ hasConnectedtoWalletConnect: true, publicAddress })
    }

    async reconnectWallet() {
        await this.connectModalRef.current.walletConnectInit()
    }

    render() {
        const {
            auth,
            path,
            myself,
            notifications,
            markAsRead,
            logout,
            getAccountAssets,
            accountAssets,
            login,
            register,
            showFlashMessage,
            connectWallet,
            me,
        } = this.props

        const { email, hasConnectedtoMetamask, referralCode } = this.state

        const connector = this.state.connector ? this.state.connector : JSON.parse(localStorage.getItem('walletConnect'))

        let displayReconnect = false //reconnect with WalletConnect which appears when you refresh the page but wasn't able to disconnect the session

        if (connector) {
            displayReconnect = !connector.connected
        }

        return (
            <nav className="navbar navbar-expand-md navbar-light">
                <div className="container">
                    <NavLink to="/" className="navbar-brand">
                        <img src="/octbot.png" width="150px" alt="logo" />
                    </NavLink>
                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                {/* <span>
                                    The OTC.bot is a safe and free peer to peer
                                    crypto marketplace.
                                </span> */}
                            </li>
                        </ul>
                        <Notification
                            auth={auth}
                            notifications={notifications}
                            markAsRead={markAsRead}
                            openNotificationModal={this._openNotificationModal}
                        />
                        {myself && (
                            <LoggedInMenu
                                myself={myself}
                                logout={this.onLogout}
                                displayReconnect={displayReconnect}
                                reconnectWallet={this.reconnectWallet}
                            /> 
                        )}
                        { path !== '/' && (
                            <NavLink
                                to="/search"
                                className="nav-link btn btn-blue text-center d-none d-md-block ml-4"
                            >
                                SEARCH
                            </NavLink>
                        )}
                        {myself ? (
                            <>
                                {!(myself.public_address && myself.is_legal) && (
                                    <NavLink
                                        to="#"
                                        className="nav-link btn btn-blue text-center d-none d-md-block ml-4"
                                        onClick={this._openConnectModal}
                                    >
                                        CONNECT WALLET
                                    </NavLink>
                                )}
                            </>
                        ) : (
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <NavLink
                                        to="/"
                                        className={cx(
                                            "nav-link",
                                            path === "/" && "active"
                                        )}
                                    >
                                        Home
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="#"
                                        className={cx(
                                            "nav-link",
                                            path === "/advertisements" &&
                                                "active"
                                        )}
                                    >
                                        About Us
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="#"
                                        className="nav-link"
                                        onClick={this._openLoginModal}
                                    >
                                        Login
                                    </NavLink>
                                </li>
                            </ul>
                        )}

                        {!myself && (
                            <NavLink
                                to="#"
                                className="nav-link btn btn-blue text-center d-none d-md-block"
                                onClick={this._openRegisterModal}
                            >
                                REGISTER
                            </NavLink>
                        )}
                        
                    </div>
                    <button
                        className="navbar-toggler d-md-none d-sm-block d-xs-block"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarToggleHeader"
                        aria-controls=""
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse"
                        id="navbarToggleHeader"
                    >
                        {myself ? (
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    <NavLink
                                        to="/me/dashboard"
                                        className="nav-link p-2"
                                    >
                                        Dashboard
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/me/edit"
                                        className="nav-link p-2"
                                    >
                                        Edit Profile
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/referrals"
                                        className="nav-link p-2"
                                    >
                                        Referrals
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="/me/settings"
                                        className="nav-link p-2"
                                    >
                                        Settings
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    {path !== '/' && (
                                        <NavLink
                                            to="/search"
                                            className="nav-link p-2 "
                                        >
                                            Search
                                        </NavLink>
                                    )}
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="#"
                                        onClick={this.onLogout}
                                        className="nav-link p-2"
                                    >
                                        Logout
                                    </NavLink>
                                </li>
                                
                            </ul>
                        ) : (
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    <NavLink
                                        to="/"
                                        className={cx(
                                            "nav-link",
                                            path === "/" && "active"
                                        )}
                                    >
                                        Home
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="#"
                                        className={cx(
                                            "nav-link",
                                            path === "/advertisements" &&
                                                "active"
                                        )}
                                    >
                                        About Us
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        to="#"
                                        className="nav-link"
                                        onClick={this._openLoginModal}
                                    >
                                        Login
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <button
                                        className="nav-link btn btn-blue text-center d-none d-md-block"
                                        onClick={this._openRegisterModal}
                                    >
                                        REGISTER
                                    </button>
                                </li>
                                <li>
                                    {path !== '/' && (
                                        <div className="w-25">
                                            <NavLink
                                                to="/search"
                                                className="nav-link btn btn-blue text-center d-none d-md-block ml-4"
                                            >
                                                SEARCH
                                            </NavLink>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        )}
                    </div>
                </div>
                <LoginModal ref={this.loginModalRef} login={login} auth={auth} openRegisterModal={this._openRegisterModal} openForgotPasswordModal={this._openForgotPasswordModal} />
                <RegisterModal ref={this.registerModalRef} register={register} auth={auth} openConfirmModal={this._openConfirmModal} referralCode={referralCode} />
                <ConfirmModal ref={this.confirmModalRef} email={email} />
                <ForgotPasswordModal
                    showFlashMessage={showFlashMessage}
                    ref={this.forgotPasswordModalRef}
                />
                <NotificationModal
                    ref={this.notificationModalRef}
                    notifications={notifications}
                    markAsRead={markAsRead}
                />
                <TermsOfServiceModal
                    ref={this.tosModalRef}
                    onAccept={this._onAccept}
                    me={me}
                />
                <ConnectWalletModal
                    ref={this.connectModalRef}
                    accountAssets={accountAssets}
                    connectWallet={connectWallet}
                    getAccountAssets={getAccountAssets}
                    hasConnectedtoMetamask={hasConnectedtoMetamask}
                    initiateConnectionToMetaMask={this.initiateConnectionToMetaMask}
                    initiateConnectionToWalletConnect={this.initiateConnectionToWalletConnect}
                    logout={logout}
                    me={me}
                    myself={myself}
                />
            </nav>
        )
    }
}

export default Header
