import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import ReactTable from 'react-table'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Assets extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selected: null,
            currentPage: 1
        }

        this._rowSelected = this._rowSelected.bind(this)
        this._handleSellPageClick = this._handleSellPageClick.bind(this)
        this._handleBuyPageClick = this._handleBuyPageClick.bind(this)
    }

    _currency = (val, currency) => {
        let decimalScale = 16;

        switch (currency) {
            case 'USDT':
                decimalScale = 6
                break
            case 'BTC':
                decimalScale = 8
                break
            case 'NEO':
                decimalScale = 0
                break
        }
        return <NumberFormat thousandSeparator="," decimalSeparator="." decimalScale={decimalScale} value={val} displayType="text"/>
    }

    _rowSelected(state, row) {
        if (row && row.row) {
            return {
                onClick: (e) => {
                    this.props.history.push(`/${row.original.user.full_name}/${row.original.type}/${row.original.name}/${row.original.uuid}`)
                },
            }
        }
        return {}
    }

    async _handleSellPageClick(data) {
        const { fetchSellAds } = this.props
        const selected = data.selected + 1
        
        await fetchSellAds(selected)
    }

    async _handleBuyPageClick(data) {
        const { fetchBuyAds } = this.props
        const selected = data.selected + 1
        
        await fetchBuyAds(selected)
    }

    render() {
        const { buyAds, sellAds } = this.props

        const columnsSell = [{
            id: 'name',
            Header: 'Asset',
            accessor: a => <NavLink to={`/${a.user.full_name}/${a.type}/${a.name}/${a.uuid}`} className="table-row table-normal text-primary font-weight-bold">{a.name}</NavLink>
        }, {
            id: 'supply',
            Header: 'Supply',
            accessor: a => <span className="table-row" style={{ color: '#E29000' }} options={{ numeral: true, numeralThousandsGroupStyle: 'thousand'}}>{a.supply.toLocaleString()}</span>,
        }, {
            id: 'price_per_token',
            Header: 'Selling Price Per Token',
            accessor: a => <span className="table-row table-normal">{this._currency(a.ico_price_token)} {a.currency}</span>
        }, {
            id: 'created_at',
            Header: 'Post Date',
            accessor: a => <span className="table-row table-normal">{moment(a.created_at).format("MMMM D, YYYY")}</span>
        }, {
            id: 'seller',
            Header: 'Seller',
            accessor: a => <span onClick={e => e.stopPropagation()}><NavLink to={`/profile/${a.user.uuid}`} className="table-row table-normal text-primary font-weight-bold">{a.user.first_name} {a.user.last_name}</NavLink></span>,
        }];

        const columnsBuy = [{
            id: 'name',
            Header: 'Asset',
            accessor: a => <NavLink to={`/${a.user.full_name}/${a.type}/${a.name}/${a.uuid}`} className="table-row table-normal text-primary font-weight-bold">{a.name}</NavLink>
        }, {
            id: 'supply',
            Header: 'Supply',
            accessor: a => <span className="table-row" style={{ color: '#E29000' }}>{a.supply.toLocaleString()}</span>,
        }, {
            id: 'buying_price_token',
            Header: 'Buying Price Per Token',
            accessor: a => <span className="table-row table-normal">{this._currency(a.buying_price_token || a.ico_price_token, a.currency)} {a.currency}</span>
        }, {
            id: 'created_at',
            Header: 'Post Date',
            accessor: a => <span className="table-row table-normal">{moment(a.created_at).format("MMMM D, YYYY")}</span>
        }, {
            id: 'buyer',
            Header: 'Buyer',
            accessor: a => <span onClick={e => e.stopPropagation()}><NavLink to={`/profile/${a.user.uuid}`} className="table-row table-normal text-primary font-weight-bold">{a.user.first_name} {a.user.last_name}</NavLink></span>,
        }];

        const previousLabel = <FontAwesomeIcon icon="chevron-left" color="#171C34" />,
            nextLabel = <FontAwesomeIcon icon="chevron-right" color="#171C34" />,
            containerClassName = 'pagination justify-content-center',
            pageClassName = 'page-item',
            pageLinkClassName = 'page-link',
            previousClassName = 'pr-2',
            nextClassName='pl-2',
            previousLinkClassName='page-link',
            nextLinkClassName='page-link'

        return(
            <div className="home-table">
                <h1 className="mb-3 text-center text-sm-left">Latest Buying Orders</h1>
                <ReactTable
                    className="text-center mb-3 -highlight"
                    getTrProps={this._rowSelected}
                    columns={columnsBuy}
                    data={buyAds.data}
                    pageSize={10}
                    minRows={3}
                    manual
                    showPageJump={false}
                    showPageSizeOptions={false}
                    showPagination={false}
                />
                <ReactPaginate
                    previousLabel={previousLabel}
                    nextLabel={nextLabel}
                    pageCount={buyAds.last_page}
                    onPageChange={this._handleBuyPageClick}
                    containerClassName={containerClassName}
                    pageClassName={pageClassName}
                    pageLinkClassName={pageLinkClassName}
                    previousClassName={previousClassName}
                    nextClassName={nextClassName}
                    previousLinkClassName={previousLinkClassName}
                    nextLinkClassName={nextLinkClassName}
                />
                <h1 className="mb-3 mt-5 text-center text-sm-left">Latest Selling Orders</h1>
                <ReactTable
                    className="text-center mb-3 -highlight"
                    getTrProps={this._rowSelected}
                    columns={columnsSell}
                    data={sellAds.data}
                    pageSize={10}
                    minRows={3}
                    manual
                    showPageJump={false}
                    showPageSizeOptions={false}
                    showPagination={false}
                />
                <ReactPaginate
                    previousLabel={previousLabel}
                    nextLabel={nextLabel}
                    pageCount={sellAds.last_page}
                    onPageChange={this._handleSellPageClick}
                    containerClassName={containerClassName}
                    pageClassName={pageClassName}
                    pageLinkClassName={pageLinkClassName}
                    previousClassName={previousClassName}
                    nextClassName={nextClassName}
                    previousLinkClassName={previousLinkClassName}
                    nextLinkClassName={nextLinkClassName}
                />
            </div>
        )
    }
}

Assets.propTypes = {
    buyAds: PropTypes.any.isRequired,
    sellAds: PropTypes.any.isRequired
}

export default withRouter(Assets)
