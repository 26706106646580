import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink, withRouter } from 'react-router-dom'
import NumberFormat from 'react-number-format'
import ReactTable from 'react-table'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class TransactionsList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selected: null,
            currentPage: 1
        }

        this._rowSelected = this._rowSelected.bind(this)
        this._handleSellPageClick = this._handleSellPageClick.bind(this)
        this._handleBuyPageClick = this._handleBuyPageClick.bind(this)
        this._handlePageClick = this._handlePageClick.bind(this)
        this.miniAppPath = '';
    }

    componentDidMount() {
        const { me, path } = this.props;
        const auth = JSON.parse(localStorage.getItem('persist:root'));
        const token = auth && JSON.parse(auth.auth);

        if (path.includes('mini-app')) {
            this.miniAppPath = '/mini-app'
        }
    
        if (token && token.token) {
          me();
        }
    }

    componentDidUpdate(prevProps) {
      const { me, auth } = this.props;

      if (auth.token !== prevProps.auth.token) {
        me();
      }
    }

    _currency = (val, currency) => {
        let decimalScale = 16;

        switch (currency) {
            case 'USDT':
                decimalScale = 6
                break
            case 'BTC':
                decimalScale = 8
                break
            case 'NEO':
                decimalScale = 0
                break
        }
        return <NumberFormat thousandSeparator="," decimalSeparator="." decimalScale={decimalScale} value={val} displayType="text"/>
    }

    _rowSelected(state, row) {
        if (row && row.row) {
            return {
                onClick: (e) => {
                    this.props.history.push(`${this.miniAppPath}/${row.original.user.full_name}/${row.original.type}/${row.original.name}/${row.original.uuid}`)
                },
            }
        }
        return {}
    }

    async _handleSellPageClick(data) {
        const { fetchSellAds } = this.props
        const selected = data.selected + 1
        
        await fetchSellAds(selected)
    }

    async _handleBuyPageClick(data) {
        const { fetchBuyAds } = this.props
        const selected = data.selected + 1
        
        await fetchBuyAds(selected)
    }

    async _handlePageClick(data) {
        const { fetchAds } = this.props
        const selected = data.selected + 1
        
        await fetchAds(selected)
    }

    checkAuth = (e) => {
        const { myself } = this.props;

        if (!myself) {
            e.preventDefault();
        }
    }

    render() {
        const { allAds } = this.props

        const columnsBuy = [{
            id: 'name',
            Header: 'Asset',
            accessor: a => <span className="table-row table-normal text-primary font-weight-bold">{a.name}</span>
        }, {
            id: 'type',
            Header: 'Type',
            accessor: a => <span className="table-row table-normal">{a.type}</span>
        }, {
            id: 'supply',
            Header: 'Supply',
            accessor: a => <span className="table-row" style={{ color: '#E29000' }}>{a.supply.toLocaleString()}</span>,
        }, {
            id: 'buying_price_token',
            Header: 'Price Per Token',
            accessor: a => <span className="table-row table-normal">{this._currency(a.buying_price_token || a.ico_price_token, a.currency)} {a.currency}</span>
        }, {
            id: 'created_at',
            Header: 'Post Date',
            accessor: a => <span className="table-row table-normal">{moment(a.created_at).format("MMMM D, YYYY")}</span>
        }, {
            id: 'owner',
            Header: 'Owner',
            accessor: a => {
                return (
                    <span onClick={e => e.stopPropagation()}><NavLink onClick={this.checkAuth} to={`${this.miniAppPath}/profile/${a.user.uuid}`} className="table-row table-normal text-primary font-weight-bold">{a.user.first_name} {a.user.last_name}</NavLink></span>
                );
            },
        }];

        const previousLabel = <FontAwesomeIcon icon="chevron-left" color="#171C34" />,
            nextLabel = <FontAwesomeIcon icon="chevron-right" color="#171C34" />,
            containerClassName = 'pagination justify-content-center',
            pageClassName = 'page-item',
            pageLinkClassName = 'page-link',
            previousClassName = 'pr-2',
            nextClassName='pl-2',
            previousLinkClassName='page-link',
            nextLinkClassName='page-link'

        return(
            <div className="home-table w-75">
                <h1 className="mb-3 text-center text-sm-left">Latest Orders</h1>
                <ReactTable
                    className="text-center mb-3 -highlight"
                    getTrProps={this._rowSelected}
                    columns={columnsBuy}
                    data={allAds.data}
                    pageSize={10}
                    minRows={3}
                    manual
                    showPageJump={false}
                    showPageSizeOptions={false}
                    showPagination={false}
                    sortable={false}
                />
                <ReactPaginate
                    previousLabel={previousLabel}
                    nextLabel={nextLabel}
                    pageCount={allAds.last_page}
                    onPageChange={this._handlePageClick}
                    containerClassName={containerClassName}
                    pageClassName={pageClassName}
                    pageLinkClassName={pageLinkClassName}
                    previousClassName={previousClassName}
                    nextClassName={nextClassName}
                    previousLinkClassName={previousLinkClassName}
                    nextLinkClassName={nextLinkClassName}
                />
            </div>
        )
    }
}

TransactionsList.propTypes = {
    // buyAds: PropTypes.any.isRequired,
    // sellAds: PropTypes.any.isRequired,
    allAds: PropTypes.any.isRequired
}

export default withRouter(TransactionsList)
