import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BaseModal from '../../common/BaseModal'

export default class DetailsModal extends BaseModal {

    _close = () => {
        this.setState({ message: '' })
        this.close()
    }

    async componentDidMount() {
        await this.setSettings({
            onClickBackdropClose: true,
            showModalClose: true,
        })
    }

    renderBody = () => {
        const { transaction } = this.props
        let maximumFractionDigits = 16

        switch(transaction.currency) {
            case 'USDT':
                maximumFractionDigits = 6
                break
            case 'BTC':
                maximumFractionDigits = 8
                break
            case 'NEO':
                maximumFractionDigits = 0
                break
        }

        return <div className="row pb-2">
            <div className="col-lg-8 col-md-12 col-sm-12">
                <h3 className="pitch-black bold">
                    Transaction Details
                </h3>
                <div className="info-session">
                    <p className="number">{transaction.supply}</p>
                    <p>Number of supplies to buy/sell</p>
                </div>
                <div className="info-session">
                    <p className="number">{parseFloat(transaction.price_per_token).toFixed(maximumFractionDigits)}</p>
                    <p>Price per token for this transaction</p>
                </div>
                <div className="info-session">
                    <p className="number">{parseFloat(transaction.fee).toFixed(6)}</p>
                    <p>Escrow fee for this transaction</p>
                </div>
                <div className="info-session notes">
                    <p className="title">Notes from transactee:</p>
                    <p className="body text-muted">
                        {transaction.notes}
                    </p>
                </div>
            </div>
        </div>
    }
}