import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTable from 'react-table';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading2 from '../common/Loading2';

class Asks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0
    }
  }

  componentDidMount() {
    const { fetchSellAds } = this.props;
    const { me } = this.props;
    const auth = JSON.parse(localStorage.getItem('persist:root'));
    const token = auth && JSON.parse(auth.auth);

    if (token && token.token) {
      me();
    }

    fetchSellAds();
  }

  componentDidUpdate(prevProps) {
    const { me, auth } = this.props;

    if (auth.token !== prevProps.auth.token) {
      me();
    }
  }

  _currency = (val, currency) => {
    let decimalScale = 16;

    switch (currency) {
      case 'USDT':
        decimalScale = 6;
        break;
      case 'BTC':
        decimalScale = 8;
        break;
      case 'NEO':
        decimalScale = 0;
        break;
    }
    return (
      <NumberFormat
        thousandSeparator=","
        decimalSeparator="."
        decimalScale={decimalScale}
        value={val}
        displayType="text"
      />
    );
  };

  _rowSelected = (state, row) => {
    if (row && row.row) {
      return {
        onClick: (e) => {
          this.props.history.push(`/mini-app/${row.original.user.full_name}/${row.original.type}/${row.original.name}/${row.original.uuid}`)
        },
      };
    }
    return {};
  }

  _handlePageClick = (data) => {
    const { fetchSellAds } = this.props;
    const selected = data.selected + 1;

    this.setState({ currentPage: data.selected })

    fetchSellAds(selected);
  }

  checkAuth = (e) => {
    const { myself } = this.props;

    if (!myself) {
        e.preventDefault();
    }
  }

  render() {
    const { isFetching, sellAds } = this.props;
    const { currentPage } = this.state;

    const columnsSell = [
      {
        id: 'name',
        Header: 'Asset',
        accessor: (a) => (
          <NavLink
            to="#"
            className="table-row table-normal text-primary font-weight-bold"
          >
            {a.name}
          </NavLink>
        ),
      },
      {
        id: 'supply',
        Header: 'Supply',
        accessor: (a) => (
          <span
            className="table-row"
            style={{ color: '#E29000' }}
            options={{ numeral: true, numeralThousandsGroupStyle: 'thousand' }}
          >
            {a.supply.toLocaleString()}
          </span>
        ),
      },
      {
        id: 'price_per_token',
        Header: 'Price/token',
        accessor: (a) => (
          <span className="table-row table-normal">
            {this._currency(a.ico_price_token)} {a.currency}
          </span>
        ),
      },
      {
        id: 'created_at',
        Header: 'Post Date',
        accessor: (a) => (
          <span className="table-row table-normal">
            {moment(a.created_at).format('MMM D, YYYY')}
          </span>
        ),
      },
      {
        id: 'seller',
        Header: 'Seller',
        accessor: (a) => (
          <span onClick={(e) => e.stopPropagation()}>
            <NavLink
              to={`/mini-app/profile/${a.user.uuid}`}
              onClick={this.checkAuth}
              className="table-row table-normal text-primary font-weight-bold"
            >
              {a.user.first_name} {a.user.last_name}
            </NavLink>
          </span>
        ),
      },
    ];

    const previousLabel = (
        <FontAwesomeIcon icon="chevron-left" color="#171C34" />
      ),
      nextLabel = <FontAwesomeIcon icon="chevron-right" color="#171C34" />,
      containerClassName = 'pagination justify-content-center',
      pageClassName = 'page-item',
      pageLinkClassName = 'page-link',
      previousClassName = 'pr-2',
      nextClassName = 'pl-2',
      previousLinkClassName = 'page-link',
      nextLinkClassName = 'page-link';

    return (
      <main className="main-content">
        <h1 className="main-content__heading">Sell orders</h1>
        {isFetching || !sellAds ? (
          <Loading2 />
        ) : (
          <div className="main-content__table">
            <ReactTable
              className="text-center mb-3 -highlight"
              getTrProps={this._rowSelected}
              columns={columnsSell}
              data={sellAds.data}
              pageSize={10}
              minRows={5}
              manual
              showPageJump={false}
              showPageSizeOptions={false}
              showPagination={false}
              sortable={false}
            />
            <ReactPaginate
              previousLabel={previousLabel}
              nextLabel={nextLabel}
              pageCount={sellAds.last_page}
              forcePage={currentPage}
              onPageChange={this._handlePageClick}
              containerClassName={containerClassName}
              pageClassName={pageClassName}
              pageLinkClassName={pageLinkClassName}
              previousClassName={previousClassName}
              nextClassName={nextClassName}
              previousLinkClassName={previousLinkClassName}
              nextLinkClassName={nextLinkClassName}
            />
          </div>
        )}
      </main>
    );
  }
}

export default Asks;
