import Asks from '../../components/MiniApp/Asks';
import { connect } from 'react-redux';
import { me } from '../../actions/auth';
import { fetchSellAds } from '../../actions/ads';
import { showFlashMessage } from '../../actions/app';

const mapActionCreators = {
  me,
  fetchSellAds,
  showFlashMessage,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  myself: state.auth.myself,
  sellAds: state.ads.sellAds,
  isFetching: state.ads.fetchingSellAds,
});

export default connect(mapStateToProps, mapActionCreators)(Asks);
