import React from 'react'
import BaseModal from '../../common/BaseModal'

export default class ListedTokenModal extends BaseModal {
    constructor(props) {
        super(props)
    }

    toggleOpen = async () => {
        await this.setSettings({
            className: 'frozen-user-modal',
            dialogClassName: 'modal-dialog-centered'
        })
        this.open()
    }

    renderBody = () => {
        const { logout } = this.props

        return (
            <div className="row justify-content-around align-items-end">
                <div className="col-12">
                    <img src="/frozen.png" alt="Frozen Account" className="w-100" />
                </div>
                <div className="col-12 text-center mb-4">
                    <h5 className="text-dark"><strong>Your account is Frozen!</strong></h5>
                </div>
                <div className="col-md-9 col-12 text-center">
                    <p className="text-secondary">
                        Your account is frozen please read our <a href="#">Terms of Service</a> carefully 
                        and contact the admin at <a href="mailto:support@otc.com">support@otc.com</a>.
                        You can <a href="#" onClick={logout}>disconnect</a> here.
                    </p>
                </div>
            </div>
        )
    }
}