import { RSAA } from 'redux-api-middleware'

// export const CREATE_COMMISSION = 'auth:create_commission'
// export const CREATE_COMMISSION_SUCCESS = 'auth:create_commission_success'
// export const CREATE_COMMISSION_FAILURE = 'auth:create_commission_failure'
export const GET_COMMISSIONS = 'auth:get_commissions'
export const GET_COMMISSIONS_SUCCESS = 'auth:get_commissions_success'
export const GET_COMMISSIONS_FAILURE = 'auth:get_commissions_failure'

// export function createCommission(data){
//     return (dispatch, getState) => {
//         const { auth: { token } } = getState()

//         return dispatch({
//             [RSAA]: {
//                 endpoint: `${process.env.REACT_APP_API_HOST}/commission`,
//                 method: 'POST',
//                 headers: {
//                     'Authorization': `Bearer ${token}`,
//                     'Content-Type': 'application/json; charset=UTF-8',
//                 },
//                 body: JSON.stringify(data),
//                 types: [CREATE_COMMISSION, CREATE_COMMISSION_SUCCESS, CREATE_COMMISSION_FAILURE]
//             }
//         })
//     }
// }

export function getCommissions() {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/commission`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                types: [GET_COMMISSIONS, GET_COMMISSIONS_SUCCESS, GET_COMMISSIONS_FAILURE]
            }
        })
    }
}

const ACTION_HANDLERS = {
    // [CREATE_COMMISSION]: state => ({ 
    //     ...state, 
    //     createCommission: true, 
    //     createCommissionSuccess: false, 
    //     createCommissionFailure: false 
    // }),
    // [CREATE_COMMISSION_SUCCESS]: state => ({ 
    //     ...state, 
    //     createCommission: false, 
    //     createCommissionSuccess: true, 
    //     createCommissionFailure: false 
    // }),
    // [CREATE_COMMISSION_FAILURE]: state => ({ 
    //     ...state, 
    //     createCommission: false, 
    //     createCommissionSuccess: false, 
    //     createCommissionFailure: true 
    // }),
    [GET_COMMISSIONS]: state => ({
        ...state,
        getCommissions: true,
        getCommissionsSuccess: false,
        getCommissionsFailure: false
    }),
    [GET_COMMISSIONS_SUCCESS]: (state, action) => ({
        ...state,
        getCommissions: false,
        getCommissionsSuccess: true,
        getCommissionsFailure: false,
        commissions: action.payload.commissions
    }),
    [GET_COMMISSIONS_FAILURE]: state => ({
        ...state,
        getCommissions: false,
        getCommissionsSuccess: false,
        getCommissionsFailure: true
    })
}

const initialState = {
  // createCommission: false,
  // createCommissionSuccess: false,
  // createCommissionFailure: false,
  getCommissions: false,
  getCommissionsSuccess: false,
  getCommissionsFailure: false,
  commissions: []
}

export default function comments (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}