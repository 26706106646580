import React, { Component } from 'react'
import PasswordStrengthBar from 'react-password-strength-bar'
import Input from '../common/Input'

class ResetPasswordForm extends Component {
    constructor(props) {
        super(props)

        this.defaultState = {
            current_password: '',
            new_password: '',
            confirm_password: '',
            isLoading: false,
        }

        this.state = this.defaultState
    }

    componentWillReceiveProps(nextProps) {
        const { prop } = nextProps

        if (prop.success) {
            this.setState({ ...this.defaultState })
        }

        this.setState({ isLoading: prop.processing })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    _handleReset = () => {
        this.setState({ ...this.defaultState })
    }

    _handleOnSubmit = () => {
        const { onSubmit } = this.props
        const {
            myself: { is_password_exist },
        } = this.props
        
        onSubmit({ ...this.state, is_password_exist })
    }

    render() {
        const {
            current_password,
            confirm_password,
            new_password,
            isLoading,
        } = this.state

        const {
            myself: { is_password_exist },
        } = this.props

        return (
            <div className="reset-password-form">
                <input
                    type="hidden"
                    name="is_password_exist"
                    value={is_password_exist}
                />
                {is_password_exist && (
                    <Input
                        type="password"
                        name="current_password"
                        value={current_password}
                        className="form-control"
                        placeholder="Current Password"
                        onChange={this.onChange}
                    />
                )}
                <Input
                    type="password"
                    name="new_password"
                    value={new_password}
                    className="form-control no-margin-bottom"
                    containerClassName="no-margin-bottom"
                    placeholder="New Password"
                    onChange={this.onChange}
                />
                <PasswordStrengthBar password={new_password} className="mb-5" />
                <Input
                    type="password"
                    name="confirm_password"
                    value={confirm_password}
                    className="form-control"
                    placeholder="Confirm Password"
                    onChange={this.onChange}
                />
                <div className="btn-container d-flex justify-content-end">
                    <button
                        className="btn btn-white mr-2"
                        onClick={this._handleReset}
                        disabled={isLoading}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-blue"
                        onClick={this._handleOnSubmit}
                        disabled={isLoading}
                    >
                        UPDATE
                    </button>
                </div>
            </div>
        )
    }
}

ResetPasswordForm.propTypes = {}

export default ResetPasswordForm
