import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { debounce } from 'lodash'
import ReactTable from 'react-table'
import NumberFormat from 'react-number-format'
import ReactPaginate from 'react-paginate'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading2 from '../../common/Loading2'
import ConfirmDialog from '../../common/ConfirmDialog'
import CreateOrder from '../User/CreateOrder'

export default class Orders extends Component {
    constructor(props) {
        super(props)

        this.state = {
            orders: [],
            isLoading: true,
            type: 'all',
            search: '',
            dialogMessage: '',
            isShowDialog: false,
            actionIsDisable: false,
            uuid: null // uuid to be disabled / enabled
        }

        this._searchOrders = this._searchOrders.bind(this)
        this._toggleEnableDisableDialog = this._toggleEnableDisableDialog.bind(this)
        this._disableOrEnableOrderConfirm = this._disableOrEnableOrderConfirm.bind(this)
        this._disableOrEnableOrderCancel = this._disableOrEnableOrderCancel.bind(this)
        this._handleNextPage = this._handleNextPage.bind(this)
    }

    async componentDidMount() {
        await this._fetchOrders()
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return
        }
    }

    _searchOrders = async(e) => {
        e.persist()

        if (!this.debouncedFn) {
            this.debouncedFn = debounce(async() => {
                const { value } = e.target

                this.setState({ isLoading: true, orders: [], search: value })
                if (value.length > 2)
                    await this._fetchOrders()
                else
                    await this._fetchOrders()

            }, 1000)
        }
        this.debouncedFn()
    }

    async _fetchOrders() {
        const { adminGetOrders } = this.props
        const { type, search } = this.state
        this.setState({ isLoading: true })
        await adminGetOrders(type, search)
        this.setState({ isLoading: false })
    }

    async _filterOrders(type) {
        await this.setState({ type })
        await this._fetchOrders()
    }

    _toggleEnableDisableDialog({ uuid, is_visible }) {
        let type = 'enable'

        if (is_visible) {
            // Disable this order
            type = 'disable'
        }
        let dialogMessage = `Are you sure you want to ${type} this order?`

        this.setState({ uuid, isShowDialog: true, dialogMessage, actionIsDisable: !is_visible })
    }

    async _disableOrEnableOrderConfirm() {
        const { updateAd } = this.props
        const { uuid, actionIsDisable, type } = this.state

        await updateAd(uuid, { is_visible: actionIsDisable })
        this._disableOrEnableOrderCancel()
        await this._fetchOrders(type)
    }

    _disableOrEnableOrderCancel() {
        this.setState({ uuid: null, isShowDialog: false, actionIsDisable: false })
    }

    async _handleNextPage(data) {
        const { adminGetOrders } = this.props
        const { type, search } = this.state
        const selected = data.selected + 1

        await adminGetOrders(type, search, selected)
    }

    render() {
        const { type, isLoading, isShowDialog, dialogMessage } = this.state
        const { searchAds, ads, orders } = this.props

        const columns = [
            {
                id: 'name',
                Header: 'ICO Name',
                accessor: a => <NavLink to={`/order/${a.uuid}`} className="table-row table-normal text-info">{a.name}</NavLink>
            },
            {
                id: 'type',
                Header: 'Type',
                accessor: a => <span className="text-uppercase font-weight-bold">{a.type}</span>
            },
            {
                id: 'supply',
                Header: 'Supply',
                accessor: a => <span className="table-row" style={{ color: "#E29000" }}>{a.supply}</span>,
            },
            {
                id: 'price_per_token',
                Header: 'Price Per Token',
                accessor: a => <span className="table-row table-normal">{`${a.ico_price_token} ${a.currency}`}</span>
            },
            {
                id: 'created_at',
                Header: 'Post Date',
                accessor: a => <span className="table-row table-normal">{moment(a.created_at).format("MMMM D, YYYY")}</span>
            }, 
            {
                id: 'action',
                Header: 'Action',
                accessor: order =>
                <div>
                    <p>
                        <button className={ order.is_visible ? "btn btn-sm btn-warning" : "btn btn-sm btn-info" }
                            onClick={() => this._toggleEnableDisableDialog(order)}>{order.is_visible ? 'Disable' : 'Enable'}</button>
                    </p>
                </div>
            }

        ]

        return (
            <div className="recent-orders session-block row">
                <ConfirmDialog
                    cancel={this._disableOrEnableOrderCancel} 
                    confirm={this._disableOrEnableOrderConfirm}
                    isShow={isShowDialog} 
                    message={dialogMessage}
                />
                <div className="col-12 p-0">
                    <div className="row">
                        <div className="col-md-6 col-12 p-md-0">
                            <div className="img float-left">
                                <img src="/orders.svg" alt=""/>
                            </div>
                            <h3>Recent Orders</h3>
                        </div>
                        <div className="col-md-1 offset-md-4 offset-lg-5 col-12 p-md-0 mt-5 mt-md-0">
                            <CreateOrder ads={ads} searchAds={searchAds} isAdmin={true} />
                        </div>
                    </div>
                </div>
                <div className="col-12 p-0 mt-5">
                    <ul className="app-nav nav">
                        <li className="nav-item" onClick={() => this._filterOrders('all')}>
                            <span className={`nav-link ${type === 'all' ? 'active' : ''}`}>All Orders</span>
                        </li>
                        <li className="nav-item" onClick={() => this._filterOrders('sell')}>
                            <span className={`nav-link ${type === 'sell' ? 'active' : ''}`}>Sell Orders</span>
                        </li>
                        <li className="nav-item" onClick={() => this._filterOrders('buy')}>
                            <span className={`nav-link ${type === 'buy' ? 'active' : ''}`}>Buy Orders</span>
                        </li>
                    </ul>
                </div>
                <div className="col-12 mt-4 p-0">
                    <div className="search">
                        <input type="text" name="search" className="form-control" placeholder="Search here..." onChange={this._searchOrders}/>
                    </div>
                </div>
                <div className="col-12 p-0">
                    {
                        isLoading
                        ? <Loading2 />
                        : <div>
                            <ReactTable
                                className="text-center"
                                columns={columns}
                                data={orders.data}
                                pageSize={10}
                                useRowSelect
                                minRows={3}
                                manual
                                showPageJump={false}
                                showPageSizeOptions={false}
                                showPaginationBottom={false}
                            />
                            <ReactPaginate
                                previousLabel={<FontAwesomeIcon icon="chevron-left" color="#171C34" />}
                                nextLabel={<FontAwesomeIcon icon="chevron-right" color="#171C34" />}
                                pageCount={orders.last_page}
                                onPageChange={this._handleNextPage}
                                containerClassName="pagination justify-content-center"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="pr-2"
                                nextClassName="pl-2"
                                previousLinkClassName="page-link"
                                nextLinkClassName="page-link"
                            />
                        </div>
                    }
                </div>

            </div>
        )
    }
}
