import Post from '../../components/MiniApp/Post';
import { connect } from 'react-redux';
import { 
    me,
    register,
    login,
} from '../../actions/auth'
import { showFlashMessage } from '../../actions/app'

const mapActionCreators = {
  me,
  register,
  login,
  showFlashMessage,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  myself: state.auth.myself,
  flashMessage: state.app.flashMessage,
});

export default connect(mapStateToProps, mapActionCreators)(Post);
