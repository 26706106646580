import { RSAA } from 'redux-api-middleware'

export const FETCH_BUY_ADS = 'auth:fetch_buy_ads'
export const FETCH_BUY_ADS_SUCCESS = 'auth:fetch_buy_ads_success'
export const FETCH_BUY_ADS_FAILURE = 'auth:fetch_buy_ads_failure'
export const FETCH_SELL_ADS = 'auth:fetch_sell_ads'
export const FETCH_SELL_ADS_SUCCESS = 'auth:fetch_sell_ads_success'
export const FETCH_SELL_ADS_FAILURE = 'auth:fetch_sell_ads_failure'
export const FETCH_ICO_DETAIL = 'auth:fetch_ico_detail'
export const FETCH_ICO_DETAIL_SUCCESS = 'auth:fetch_ico_detail_success'
export const FETCH_ICO_DETAIL_FAILURE = 'auth:fetch_ico_detail_failure'
export const CREATE_AD = 'auth:create_ad'
export const CREATE_AD_SUCCESS = 'auth:create_ad_success'
export const CREATE_AD_FAILURE = 'auth:create_ad_failure'
export const FETCH_COMMENTS = 'auth:fetch_comments'
export const FETCH_COMMENTS_SUCCESS = 'auth:fetch_comments_success'
export const FETCH_COMMENTS_FAILURE = 'auth:fetch_comments_failure'
export const WRITE_COMMENT = 'auth:write_comment'
export const WRITE_COMMENT_SUCCESS = 'auth:write_comment_success'
export const WRITE_COMMENT_FAILURE = 'auth:write_comment_failure'
export const MY_ORDERS = 'auth:my_orders'
export const MY_ORDERS_SUCCESS = 'auth:my_orders_success'
export const MY_ORDERS_FAILURE = 'auth:my_orders_failure'
export const TOGGLE_ORDER_HIDE_SHOW = 'auth:toggle_order_hide_show'
export const TOGGLE_ORDER_HIDE_SHOW_SUCCESS = 'auth:toggle_order_hide_show_success'
export const TOGGLE_ORDER_HIDE_SHOW_FAILURE = 'auth:toggle_order_hide_show_failure'
export const UPDATE_AD = 'auth:update_ad'
export const UPDATE_AD_SUCCESS = 'auth:update_ad_success'
export const UPDATE_AD_FAILURE = 'auth:update_ad_failure'
export const FETCH_ICO_HISTORY = 'auth:fetch_ico_history'
export const FETCH_ICO_HISTORY_SUCCESS = 'auth:fetch_ico_history_success'
export const FETCH_ICO_HISTORY_FAILURE = 'auth:fetch_ico_history_failure'
export const GET_ORDER_COMMENTS = 'auth:get_order_comments'
export const GET_ORDER_COMMENTS_SUCCESS = 'auth:get_order_comments_success'
export const GET_ORDER_COMMENTS_FAILURE = 'auth:get_order_comments_failure'
export const CHECK_IS_OWNER = 'auth:check_is_owner'
export const CHECK_IS_OWNER_SUCCESS = 'auth:check_is_owner_success'
export const CHECK_IS_OWNER_FAILURE = 'auth:check_is_owner_failure'
export const SEARCH_ADS = 'auth:search_ad'
export const SEARCH_ADS_SUCCESS = 'auth:search_ad_success'
export const SEARCH_ADS_FAILURE = 'auth:search_ad_failure'
export const FETCH_ALL_ADS = 'auth:fetch_all_ads'
export const FETCH_ALL_ADS_SUCCESS = 'auth:fetch_all_ads_success'
export const FETCH_ALL_ADS_FAILURE = 'auth:fetch_all_ads_failure'

export function fetchBuyAds(page, filter = '', asset = 'icos') {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        let endpoint = `${process.env.REACT_APP_API_HOST}/${asset}?page=${page}&type=buy`

        if (filter) {
            endpoint += `&filter=${filter}`
        }

        return dispatch({
            [RSAA]: {
                endpoint,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [FETCH_BUY_ADS, FETCH_BUY_ADS_SUCCESS, FETCH_BUY_ADS_FAILURE]
            }
        })
    }
}

export function fetchSellAds(page, filter = '', asset = 'icos') {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        let endpoint = `${process.env.REACT_APP_API_HOST}/${asset}?page=${page}&type=sell`

        if (filter) {
            endpoint += `&filter=${filter}`
        }

        return dispatch({
            [RSAA]: {
                endpoint,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [FETCH_SELL_ADS, FETCH_SELL_ADS_SUCCESS, FETCH_SELL_ADS_FAILURE]
            }
        })
    }
}

export function createAd(data, adType = 'ico') {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/${adType}`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(data),
                types: [CREATE_AD, CREATE_AD_SUCCESS, CREATE_AD_FAILURE]
            }
        })
    }
}

export function fetchIcoDetails(id) {

    return dispatch => {
        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/order/${id}`,
                method: 'GET',
                headers: {
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [FETCH_ICO_DETAIL, FETCH_ICO_DETAIL_SUCCESS, FETCH_ICO_DETAIL_FAILURE]
            }
        })
    }
}

export function fetchComments(id, userId) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/icos/${id}/comments/${userId}`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [FETCH_COMMENTS, FETCH_COMMENTS_SUCCESS, FETCH_COMMENTS_FAILURE]
            }
        })
    }
}

export function writeComments(id, message) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/icos/${id}/comment`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(message),
                types: [WRITE_COMMENT, WRITE_COMMENT_SUCCESS, WRITE_COMMENT_FAILURE]
            }
        })
    }
}

export function myOrders(activeTab) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/me/orders?tab=${activeTab}`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [MY_ORDERS, MY_ORDERS_SUCCESS, MY_ORDERS_FAILURE]
            }
        })
    }
}

export function toggleIcoHideShow(id) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/icos/${id}/toggle`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                types: [TOGGLE_ORDER_HIDE_SHOW, TOGGLE_ORDER_HIDE_SHOW_SUCCESS, TOGGLE_ORDER_HIDE_SHOW_FAILURE]
            }
        })
    }
}

export function updateAd(uuid, data, adType = 'ico') {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/${adType}/${uuid}`,
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(data),
                types: [UPDATE_AD, UPDATE_AD_SUCCESS, UPDATE_AD_FAILURE]
            }
        })
    }
}

export function fetchIcoHistory(id) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/history/${id}`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [FETCH_ICO_HISTORY, FETCH_ICO_HISTORY_SUCCESS, FETCH_ICO_HISTORY_FAILURE]
            }
        })
    }
}

export function getOrderComments(id) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/order/${id}/comments`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [GET_ORDER_COMMENTS, GET_ORDER_COMMENTS_SUCCESS, GET_ORDER_COMMENTS_FAILURE]
            }
        })
    }
}

export function checkIsOwner(id) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/order/${id}/owner`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [CHECK_IS_OWNER, CHECK_IS_OWNER_SUCCESS, CHECK_IS_OWNER_FAILURE]
            }
        })
    }
}

export function searchAds(payload, adType = 'ico') {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()
        
        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/${adType}?type=${payload.type}${payload.page ? `&page=${payload.page}` : ''}${payload.currency ? `&currency=${payload.currency}` : ''}&name=${payload.value}`,
                method: "GET",
                headers: {
                Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                },
                types: [SEARCH_ADS, SEARCH_ADS_SUCCESS, SEARCH_ADS_FAILURE],
            }
        })
    }
}

export function fetchAds(page, filter = '', advanceSearch, asset = 'icos') {
    return (dispatch, getState) => {
        const { auth: { token } } = getState();

        let endpoint = `${process.env.REACT_APP_API_HOST}/${asset}-advance-search?page=${page}`
        console.log()
        if (filter) {
            endpoint += `&filter=${filter}`
        }

        if(advanceSearch) {
            if (advanceSearch.max) {
                endpoint += `&max=${advanceSearch.max}`;
            }

            if (advanceSearch.min) {
                endpoint += `&min=${advanceSearch.min}`;
            }

            if (advanceSearch.trustScore) {
                endpoint += `&trustScore=${advanceSearch.trustScore}`;
            }

            if (advanceSearch.sortOrder) {
                endpoint += `&sortOrder=${advanceSearch.sortOrder}`;
            }
            
        }

        return dispatch({
            [RSAA]: {
                endpoint,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json; charset=UTF-8',
                },
                types: [FETCH_ALL_ADS, FETCH_ALL_ADS_SUCCESS, FETCH_ALL_ADS_FAILURE]
            }
        })
    }
}

const ACTION_HANDLERS = {
    [FETCH_BUY_ADS]: state => ({
        ...state,
        fetchingBuyAds: true,
        fetchingBuyAdsSuccess: false,
        fetchingBuyAdsFailure: false
    }),
    [FETCH_BUY_ADS_SUCCESS]: (state, action) => ({
        ...state,
        fetchingBuyAds: false,
        fetchingBuyAdsSuccess: true,
        fetchingBuyAdsFailure: false,
        buyAds: action.payload.ads
    }),
    [FETCH_BUY_ADS_FAILURE]: state => ({
        ...state,
        fetchingBuyAds: false,
        fetchingBuyAdsSuccess: false,
        fetchingBuyAdsFailure: true
    }),
    [FETCH_SELL_ADS]: state => ({
        ...state,
        fetchingSellAds: true,
        fetchingSellAdsSuccess: false,
        fetchingSellAdsFailure: false
    }),
    [FETCH_SELL_ADS_SUCCESS]: (state, action) => ({
        ...state,
        fetchingSellAds: false,
        fetchingSellAdsSuccess: true,
        fetchingSellAdsFailure: false,
        sellAds: action.payload.ads
    }),
    [FETCH_SELL_ADS_FAILURE]: state => ({
        ...state,
        fetchingSellAds: false,
        fetchingSellAdsSuccess: false,
        fetchingSellAdsFailure: true
    }),
    [FETCH_ICO_DETAIL]: state => ({...state, icoDetails: { processing: true, success: false, error: false } }),
    [FETCH_ICO_DETAIL_SUCCESS]: (state, action) => ({
        ...state,
        icoDetails: {
            processing: false,
            success: true,
            error: false,
            data: action.payload.ico,
            feedbacks: action.payload.feedbacks
        } 
    }),
    [FETCH_ICO_DETAIL_FAILURE]: state => ({
        ...state,
        icoDetails: { processing: false, success: false, error: true }
    }),
    [CREATE_AD]: state => ({
        ...state,
        creatingAd: true,
        creatingAdSuccess: false,
        creatingAdFailure: false
    }),
    [CREATE_AD_SUCCESS]: state => ({
        ...state,
        creatingAd: false,
        creatingAdSuccess: true,
        creatingAdFailure: false
    }),
    [CREATE_AD_FAILURE]: state => ({
        ...state,
        creatingAd: false,
        creatingAdSuccess: false,
        creatingAdFailure: true
    }),
    [FETCH_COMMENTS]: state => ({
        ...state,
        comments: {
            processing: true,
            success: false,
            error: false
        },
        comment: {
            processing: false
        }
    }),
    [FETCH_COMMENTS_SUCCESS]: (state, action) => ({
        ...state,
        comments: {
            processing: false,
            success: true,
            error: false,
            comments: action.payload.comments
        }
    }),
    [FETCH_COMMENTS_FAILURE]: state => ({
        ...state,
        comments: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [WRITE_COMMENT]: state => ({
        ...state,
        comment: {
            processing: true,
            success: false,
            error: false
        },
        comments: {
            processing: false
        }
    }),
    [WRITE_COMMENT_SUCCESS]: state => ({
        ...state,
        comment: {
            processing: false,
            success: true,
            error: false
        }
    }),
    [WRITE_COMMENT_FAILURE]: state => ({
        ...state,
        comment: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [MY_ORDERS]: state => ({
        ...state,
        orders: {
            processing: true,
            success: false,error: false
        }
    }),
    [MY_ORDERS_SUCCESS]: (state, action) => ({
        ...state,
        orders: {
            processing: false,
            success: true,
            error: false,
            icos: action.payload.icos
        }
    }),
    [MY_ORDERS_FAILURE]: state => ({
        ...state,
        orders: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [TOGGLE_ORDER_HIDE_SHOW]: state => ({
        ...state,
        togglingIcoSuccess: false,
        togglingIcoError: false
    }),
    [TOGGLE_ORDER_HIDE_SHOW_SUCCESS]: (state, action) => ({
        ...state,
        togglingIcoSuccess: true,
        togglingIcoError: false,
        orders: {
            processing: false,
            success: true,
            error: false,
            orders: action.payload.my_orders
        }
    }),
    [TOGGLE_ORDER_HIDE_SHOW_FAILURE]: state => ({
        ...state,
        togglingIcoSuccess: false,
        togglingIcoError: true
    }),
    [UPDATE_AD]: state => ({
        ...state,
        updateAd: {
            processing: true,
            success: false,
            error: false
        }
    }),
    [UPDATE_AD_SUCCESS]: state => ({
        ...state, 
        updateAd: {
            processing: false, 
            success: true, 
            error: false
        }
    }),
    [UPDATE_AD_FAILURE]: state => ({
        ...state,
        updateAd: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [FETCH_ICO_HISTORY]: state => ({
        ...state,
        fetchIcoHistory: {
            processing: true,
            success: false,
            error: false
        }
    }),
    [FETCH_ICO_HISTORY_SUCCESS]: (state, action) => ({
        ...state,
        fetchIcoHistory: {
            processing: false,
            success: true,
            error: false
        },
        icoHistory: action.payload.ico_history
    }),
    [FETCH_ICO_HISTORY_FAILURE]: state => ({
        ...state,
        fetchIcoHistory: {
            processing: false,
            success: false,
            error: true
        }}),
    [GET_ORDER_COMMENTS]: state => ({
        ...state,
        getOrderComments: {
            processing: true,
            success: false,
            error: false 
        }
    }),
    [GET_ORDER_COMMENTS_SUCCESS]: (state, action) => ({
        ...state,
        getOrderComments: { 
            processing: false, 
            success: true, 
            error: false 
        },
        orderComments: action.payload.comments 
    }),
    [GET_ORDER_COMMENTS_FAILURE]: state => ({ 
        ...state,
        getOrderComments: {
            processing: false,
            success: false,
            error: true
        }
    }),
    [CHECK_IS_OWNER]: state => ({
        ...state,
        checkIsOwner: { 
            processing: true, 
            success: false, 
            error: false
        }
    }),
    [CHECK_IS_OWNER_SUCCESS]: (state, action) => ({
        ...state,
        checkIsOwner: {
            processing: false,
            success: true,
            error: false
        },
        isOwner: action.payload.isOwner
    }),
    [CHECK_IS_OWNER_FAILURE]: state => ({
        ...state,
        checkIsOwner: {
            processing: false,
            success: false,
            error: true 
        }
    }),
    [SEARCH_ADS]: state => ({
        ...state,
        searchAds: true,
        searchAdsSuccess: false,
        searchAdsFailure: false
    }),
    [SEARCH_ADS_SUCCESS]: (state, action) => ({
        ...state,
        searchAds: false,
        searchAdsSuccess: true,
        searchAdsFailure: false,
        ads: action.payload.ads
    }),
    [SEARCH_ADS_FAILURE]: state => ({
        ...state,
        searchAds: false,
        searchAdsSuccess: false,
        searchAdsFailure: true
    }),
    [FETCH_ALL_ADS]: state => ({
        ...state,
        fetchingAllAds: true,
        fetchingAllAdsSuccess: false,
        fetchingAllAdsFailure: false
    }),
    [FETCH_ALL_ADS_SUCCESS]: (state, action) => ({
        ...state,
        fetchingAllAds: false,
        fetchingAllAdsSuccess: true,
        fetchingAllAdsFailure: false,
        allAds: action.payload.ads
    }),
    [FETCH_ALL_ADS_FAILURE]: state => ({
        ...state,
        fetchingAllAds: false,
        fetchingAllAdsSuccess: false,
        fetchingAllAdsFailure: true
    }),
}

const initialState = {
    fetchingBuyAds: false,
    fetchingBuyAdsSuccess: false,
    fetchingBuyAdsFailure: false,

    fetchingSellAds: false,
    fetchingSellAdsSuccess: false,
    fetchingSellAdsFailure: false,

    buyAds: null,
    sellAds: null,
    ads: null,

    creatingAd: false,
    creatingAdSuccess: false,
    creatingAdFailure: false,

    icoHistory: null,
    orderComments: null,
    isOwner: false,

    icoDetails: {
        processing: false,
        success: false,
        error: false,
        reservations: null
    },

    comments: {
        processing: false,
        success: false,
        error: false,
        comments: null
    },

    comment: {
        processing: false,
        success: false,
        error: false
    },

    orders: {
        processing: false,
        success: false,
        error: false,
        orders: null
    },

    updateAd: {
        processing: false,
        success: false,
        error: false
    },

    fetchIcoHistory: {
        processing: false,
        success: false,
        error: false
    },

    getOrderComments: {
        processing: false,
        success: false,
        error: false
    },

    checkIsOwner: {
        processing: false,
        success: false,
        error: false
    },

    // Toggle ICO hide/show
    togglingIcoError: false,
    togglingIcoSuccess: false,

    searchAds: false,
    searchAdsSuccess: false,
    searchAdsFailure: false,

    fetchingAllAds: false,
    fetchingAllAdsSuccess: false,
    fetchingAllAdsFailure: false,
};

export default function ads(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}
