import Header from '../../components/MiniApp/Header';
import { connect } from 'react-redux';
import { me, logout } from '../../actions/auth';

const mapActionCreators = {
  me,
  logout,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  myself: state.auth.myself,
});

export default connect(mapStateToProps, mapActionCreators)(Header);
