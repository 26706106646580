import { routerReducer as router } from 'react-router-redux'
import app from './app'
import auth from './auth'
import users from './users'
import ads from './ads'
import reservations from './reservations'
import requests from './requests'
import bankAccount from './bankAccount'
import notification from './notification'
import messages from './messages'
import transactions from './transactions'
import reviews from './reviews'
import comments from './comments'
import admin from './admin'
import commissions from './commissions'
import referrals from './referrals'

const reducers = {
    router,
    app,
    auth,
    users,
    ads,
    reservations,
    requests,
    bankAccount,
    messages,
    notification,
    transactions,
    reviews,
    comments,
    admin,
    commissions,
    referrals
}

export default reducers
