import React from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import BaseModal from '../common/BaseModal';

export default class ChangePasswordModal extends BaseModal {
    
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            confirm: '',
            validConfirm: true
        }

        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onConfirmChange = this.onConfirmChange.bind(this);
        this._onSave = this._onSave.bind(this);
    }

    toggleOpen = async () => {
        await this.setSettings({
            className: 'change-password-modal',
            dialogClassName: 'modal-dialog-centered',
            onClickBackdropClose: true,
        })

        this.open()
    }

    onPasswordChange = (e) => {
        this.setState({ password: e.target.value });
    }

    onConfirmChange = (e) => {
        const confirm = e.target.value;
        const { password } = this.state;
        this.setState({ confirm });

        if (confirm !== password) {
            this.setState({ validConfirm: false });
        } else {
            this.setState({ validConfirm: true });
        }
    }

    _onSave = () => {
        const { onPasswordChange } = this.props;
        const { password, confirm } = this.state;

        if (password === confirm) {
            onPasswordChange(password);
            this.close();
        } else {
            window.alert.error(`Confirm password doesn't match new password!`);
        }
        
    }

    renderHeader = () => {
        return <h2 className="mb-4">Change Password</h2>
    }

    renderBody = () => {        
        return <div>
            <div className="form-group">
                <label htmlFor="ChangePassInput">New Password</label>
                <input type="password" className="form-control" id="ChangePassInput" placeholder="Input Strong Password Here" value={this.state.password} onChange={this.onPasswordChange}/>
                <PasswordStrengthBar password={this.state.password}/>
            </div>
            <div className="form-group">
                <label htmlFor="ChangeConfirmInput">Confirm New Password</label>
                <input type="password" className="form-control" id="ChangeConfirmInput" placeholder="Should match new password" value={this.state.confirm} onChange={this.onConfirmChange}/>
                <div className={`invalid-feedback ${!this.state.validConfirm ? 'd-block' : '' }`}>Confirm Password does not match</div>
            </div>
        </div>
    }

    renderFooter = () => {
        return (
            <div className="row">
                <div className="col-12 text-right buttons p-0 mb-2">
                    <button className="btn btn-white" onClick={this.close}>CLOSE</button>
                    <button className="btn btn-blue" onClick={this._onSave}>SAVE</button>
                </div>
            </div>
        );
    }
}
