import { RSAA } from 'redux-api-middleware'

export const ADMIN_UPDATE_USER = 'auth:admin_update_user'
export const ADMIN_UPDATE_USER_SUCCESS = 'auth:admin_update_user_success'
export const ADMIN_UPDATE_USER_FAILURE = 'auth:admin_update_user_failure'
export const ADMIN_GET_USER = 'auth:admin_get_user'
export const ADMIN_GET_USER_SUCCESS = 'auth:admin_get_user_success'
export const ADMIN_GET_USER_FAILURE = 'auth:admin_get_user_failure'
export const ADMIN_GET_USERS = 'auth:admin_get_users'
export const ADMIN_GET_USERS_SUCCESS = 'auth:admin_get_users_success'
export const ADMIN_GET_USERS_FAILURE = 'auth:admin_get_users_failure'
export const ADMIN_GET_USERS_SUMMARY = 'auth:admin_get_users_summary'
export const ADMIN_GET_USERS_SUMMARY_SUCCESS = 'auth:admin_get_users_summary_success'
export const ADMIN_GET_USERS_SUMMARY_FAILURE = 'auth:admin_get_users_summary_failure'
export const ADMIN_GET_WEEKLY_USERS = 'auth:admin_get_weekly_users'
export const ADMIN_GET_WEEKLY_USERS_SUCCESS = 'auth:admin_get_weekly_users_success'
export const ADMIN_GET_WEEKLY_USERS_FAILURE = 'auth:admin_get_weekly_users_failure'
export const ADMIN_GET_STATISTICS = 'auth:admin_get_statistics'
export const ADMIN_GET_STATISTICS_SUCCESS = 'auth:admin_get_statistics_success'
export const ADMIN_GET_STATISTICS_FAILURE = 'auth:admin_get_statistics_failure'
export const ADMIN_GET_ORDERS = 'auth:admin_get_orders'
export const ADMIN_GET_ORDERS_SUCCESS = 'auth:admin_get_orders_success'
export const ADMIN_GET_ORDERS_FAILURE = 'auth:admin_get_orders_failure'

export function adminUpdateUser(uuid, data) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/admin/user/${uuid}`,
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: JSON.stringify(data),
                types: [ADMIN_UPDATE_USER, ADMIN_UPDATE_USER_SUCCESS, ADMIN_UPDATE_USER_FAILURE]
            }
        })
    }
}

export function adminGetUser(uuid) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/admin/user/${uuid}`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                types: [ADMIN_GET_USER, ADMIN_GET_USER_SUCCESS, ADMIN_GET_USER_FAILURE]
            }
        })
    }
}

export function adminGetUsers(filter, search) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()
        let endpoint = `${process.env.REACT_APP_API_HOST}/admin/dashboard/users?filter=${filter}`

        if (search) {
            endpoint += `&search=${search}`
        }

        return dispatch({
            [RSAA]: {
                endpoint,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                types: [ADMIN_GET_USERS, ADMIN_GET_USERS_SUCCESS, ADMIN_GET_USERS_FAILURE]
            }
        })
    }
}

export function adminGetMembersSummary() {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/admin/dashboard/users/summary`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                types: [ADMIN_GET_USERS_SUMMARY, ADMIN_GET_USERS_SUMMARY_SUCCESS, ADMIN_GET_USERS_SUMMARY_FAILURE]
            }
        })
    }
}

export function adminGetWeeklyUsers(from, to) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/admin/dashboard/users/weekly?from=${from}&to=${to}`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                types: [ADMIN_GET_WEEKLY_USERS, ADMIN_GET_WEEKLY_USERS_SUCCESS, ADMIN_GET_WEEKLY_USERS_FAILURE]
            }
        })
    }
}

export function adminGetStatistics() {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        return dispatch({
            [RSAA]: {
                endpoint: `${process.env.REACT_APP_API_HOST}/admin/dashboard/statistics`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                types: [ADMIN_GET_STATISTICS, ADMIN_GET_STATISTICS_SUCCESS, ADMIN_GET_STATISTICS_FAILURE]
            }
        })
    }
}

export function adminGetOrders(type, search, page = 1) {
    return (dispatch, getState) => {
        const { auth: { token } } = getState()

        let endpoint = `${process.env.REACT_APP_API_HOST}/admin/dashboard/orders?page=${page}&type=${type}`

        if (search) {
            endpoint += `&search=${search}`
        }

        return dispatch({
            [RSAA]: {
                endpoint,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                types: [ADMIN_GET_ORDERS, ADMIN_GET_ORDERS_SUCCESS, ADMIN_GET_ORDERS_FAILURE]
            }
        })
    }
}

const ACTION_HANDLERS = {
    [ADMIN_UPDATE_USER]: state => ({
        ...state,
        adminUpdateUser: true,
        adminUpdateUserSuccess: false,
        adminUpdateUserFailure: false
    }),
    [ADMIN_UPDATE_USER_SUCCESS]: state => ({
        ...state,
        adminUpdateUser: false,
        adminUpdateUserSuccess: true,
        adminUpdateUserFailure: false
    }),
    [ADMIN_UPDATE_USER_FAILURE]: state => ({
        ...state,
        adminUpdateUser: false,
        adminUpdateUserSuccess: false,
        adminUpdateUserFailure: true
    }),
    [ADMIN_GET_USER]: state => ({
        ...state,
        adminGetUser: true,
        adminGetUserSuccess: false,
        adminGetUserFailure: false
    }),
    [ADMIN_GET_USER_SUCCESS]: (state, action) => ({
        ...state,
        adminGetUser: false,
        adminGetUserSuccess: true,
        adminGetUserFailure: false,
        user: action.payload.user
    }),
    [ADMIN_GET_USER_FAILURE]: state => ({
        ...state,
        adminGetUser: false,
        adminGetUserSuccess: false,
        adminGetUserFailure: true
    }),
    [ADMIN_GET_USERS]: state => ({
        ...state,
        adminGetUsers: true,
        adminGetUsersSuccess: false,
        adminGetUsersFailure: false
    }),
    [ADMIN_GET_USERS_SUCCESS]: (state, action) => ({
        ...state,
        adminGetUsers: false,
        adminGetUsersSuccess: true,
        adminGetUsersFailure: false,
        users: action.payload.users
    }),
    [ADMIN_GET_USERS_FAILURE]: state => ({
        ...state,
        adminGetUsers: false,
        adminGetUsersSuccess: false,
        adminGetUsersFailure: true
    }),
    [ADMIN_GET_USERS_SUMMARY]: state => ({
        ...state,
        adminGetUserSummary: true,
        adminGetUserSummarySuccess: false,
        adminGetUserSummaryFailure: false
    }),
    [ADMIN_GET_USERS_SUMMARY_SUCCESS]: (state, action) => ({
        ...state,
        adminGetUserSummary: false,
        adminGetUserSummarySuccess: true,
        adminGetUserSummaryFailure: false,
        summary: {
            all: action.payload.all,
            banned: action.payload.banned,
            recent: action.payload.recent
        }
    }),
    [ADMIN_GET_USERS_SUMMARY_FAILURE]: state => ({
        ...state,
        adminGetUserSummary: false,
        adminGetUserSummarySuccess: false,
        adminGetUserSummaryFailure: true
    }),
    [ADMIN_GET_WEEKLY_USERS]: state => ({
        ...state,
        adminGetWeeklyUsers: true,
        adminGetWeeklyUsersSuccess: false,
        adminGetWeeklyUsersFailure: false
    }),
    [ADMIN_GET_WEEKLY_USERS_SUCCESS]: (state, action) => ({
        ...state,
        adminGetWeeklyUsers: false,
        adminGetWeeklyUsersSuccess: true,
        adminGetWeeklyUsersFailure: false,
        weeklyUsers: action.payload.data
    }),
    [ADMIN_GET_WEEKLY_USERS_FAILURE]: state => ({
        ...state,
        adminGetWeeklyUsers: false,
        adminGetWeeklyUsersSuccess: false,
        adminGetWeeklyUsersFailure: true
    }),
    [ADMIN_GET_STATISTICS]: state => ({
        ...state,
        adminGetStatistics: true,
        adminGetStatisticsSuccess: false,
        adminGetStatisticsFailure: false
    }),
    [ADMIN_GET_STATISTICS_SUCCESS]: (state, action) => ({
        ...state,
        adminGetStatistics: false,
        adminGetStatisticsSuccess: true,
        adminGetStatisticsFailure: false,
        statistics: action.payload.data
    }),
    [ADMIN_GET_STATISTICS_FAILURE]: state => ({
        ...state,
        adminGetStatistics: false,
        adminGetStatisticsSuccess: false,
        adminGetStatisticsFailure: true
    }),
    [ADMIN_GET_ORDERS]: state => ({
        ...state,
        adminGetOrders: true,
        adminGetOrdersSuccess: false,
        adminGetOrdersFailure: false 
    }),
    [ADMIN_GET_ORDERS_SUCCESS]: (state, action) => ({
        ...state,
        adminGetOrders: false,
        adminGetOrdersSucces: true,
        adminGetOrdersFailure: false,
        orders: action.payload.orders
    }),
    [ADMIN_GET_ORDERS_FAILURE]: state => ({
        ...state,
        adminGetOrders: false,
        adminGetOrdersSuccess: false,
        adminGetOrdersFailure: true
    })
}

const initialState = {
    adminUpdateUser: false,
    adminUpdateUserSuccess: false,
    adminUpdateUserFailure: false,

    adminGetUser: false,
    adminGetUserSuccess: false,
    adminGetUserFailure: false,

    adminGetUsers: false,
    adminGetUsersSuccess: false,
    adminGetUsersFailure: false,

    adminGetUserSummary: false,
    adminGetUserSummarySuccess: false,
    adminGetUserSummaryFailure: false,

    adminGetWeeklyUsers: false,
    adminGetWeeklyUsersSuccess: false,
    adminGetWeeklyUsersFailure: false,

    adminGetOrders: false,
    adminGetOrdersSucces: false,
    adminGetordersFailure: false,

    user: null,
    summary: {
        all: null,
        banned: null,
        recent: null
    },
    users: null,
    weeklyUsers: null,
    statistics: null
}

export default function admin(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}