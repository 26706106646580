import React, { Component } from 'react'

export default class Form extends Component {
    constructor(props) {
        super(props)

        this.state = {
            type: '',
            asset: '',
            currency: '',
            page: 1
        }
    }

    componentWillMount() {}

    componentWillReceiveProps(nextProps) {}

    _onSearch = () => {
        const { type, asset: value, currency, page } = this.state
        this.props.search({type, value, currency, page})
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    render() {
        const { type, asset, currency } = this.state

        return (
            <div className="search-order-container">
                <div className="row">
                    <div className="col-12">
                        <div className="text-center">
                            <h1 className="mb-5">Search Orders</h1>
                        </div>
                    </div>
                </div>
                <div className="row align-items-end">
                    <div className="col-12 col-lg">
                        <div className="form-group">
                            <label>
                                <strong>I am</strong>
                            </label>
                            <select
                                className="custom-select form-control"
                                onChange={this.onChange}
                                name="type"
                                value={type}
                            >
                                <option value="">Choose...</option>
                                <option value="buy">Buying</option>
                                <option value="sell">Selling</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-12 col-lg">
                        <div className="form-group">
                            <label>
                                <strong>Cryptocurrency</strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                onChange={this.onChange}
                                placeholder="All"
                                value={asset}
                                name="asset"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg">
                        <div className="form-group">
                            <label>
                                <strong>Payment Method</strong>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                onChange={this.onChange}
                                placeholder="All"
                                value={currency}
                                name="currency"
                            />
                        </div>
                    </div>

                    <div className="col-12 col-lg-2">
                        <div className="form-group">
                            <button
                                disabled={!asset}
                                type="button"
                                className="btn btn-primary btn-search"
                                onClick={this._onSearch}
                            >
                                SEARCH
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
