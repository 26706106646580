/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BaseModal from '../common/BaseModal'

const withRouterInnerRef = (WrappedComponent) => {
    class InnerComponentWithRef extends React.Component {    
        render() {
            const { forwardRef, ...rest } = this.props
            return <WrappedComponent {...rest} ref={forwardRef} />
        }
    }

    const ComponentWithRef = withRouter(InnerComponentWithRef, { withRef: true })

    return React.forwardRef((props, ref) => {
        return <ComponentWithRef {...props} forwardRef={ref} />
    })
}

class NotificationModal extends BaseModal {
    
    constructor(props) {
        super(props)

        this._markRead = this._markRead.bind(this)
        this.__onClickHandler = this._onClickHandler.bind(this)
    }

    _close = () => {
        this.setState({ message: "" })
        this.close()
    }

    _markRead = (id) => {
        const { markAsRead } = this.props

        markAsRead(id)
        this.close()
    }

    _renderItem = (notification, index) => {
        const {
            category,
            id,
            ico,
            read_at,
            data,
            transaction_id
        } = notification

        let icon, message = ico.type === 'buy' ? `Buying ${ico.name}: ` : `Selling ${ico.name}: `

        if (category === 1) {
            icon = 'comment'
            message += 'You have new message.'
        } else if (category === 2) {
            icon = 'comments'
            message += 'You have one reply.'
        } else if (category === 3) {
            icon = 'thumbtack'
            message += data.message
        } else if (category === 4) {
            icon = 'thumbtack'
            message += data.message
        } else if (category === 5) {
            icon = 'check'
            message += data.message
        } else if (category === 6) {
            icon = 'handshake'
            message += data.message
        } else if (category === 7) {
            icon = 'times-circle'
            message += data.message
        } else if (category === 8) {
            icon = 'edit'
            message += data.message
        }

        if (index <= 4) {
            return <li
                className="list-group-item"
                key={index}
                style={{background: !read_at ? 'grey': 'none', cursor: 'pointer'}}
            >
                <div className="row">
                    <div className="col-12 message">
                        <a
                            className="dropdown-item text-size-2"
                            key={index}
                            onClick={() => this._onClickHandler(
                                read_at,
                                id,
                                ico,
                                category,
                                transaction_id
                            )}
                            style={{background: !read_at ? 'grey': 'none'}}
                        >
                            <FontAwesomeIcon icon={icon} className="text-primary mr-2" />
                            {message}
                        </a>
                    </div>
                </div>
            </li>
        } else {
            return ''
        }
    }

    _onClickHandler = (readAt, id, ico, category, transactionId) => {
        const { markAsRead } = this.props
        const { user: { full_name }, type, name, uuid } = ico
        if (!readAt) {
            markAsRead(id)
        }

        if (category === 8) {
            this.props.history.push(`/${full_name}/${type}/${name}/${uuid}`, { openDetails: true })
        } else if (category === 1 || category === 2) {
            this.props.history.push(`/${full_name}/${type}/${name}/${uuid}`, { transactionId })
        } else {
            this.props.history.push(`/${full_name}/${type}/${name}/${uuid}`)
        }

        this.close()
    }

    renderHeader = () => {
        return <h3 className="mb-0">All Notifications</h3>
    }

    renderBody = () => {
        const { notifications: { all } } = this.props
        
        return <div className="chat">
            <div className="window">
                <div className="box-list-data">
                    <ul className="list-group">
                        {all.length === 0 
                            ? 'Nothing here'
                            : all.map(this._renderItem)
                        }
                    </ul>
                </div>
            </div>
        </div>
    }

    renderFooter = () => {
        return (
            <div className="chat col-12 text-right buttons p-0">
                <div className="w-100 mt-4">
                    <button className="btn btn-blue" onClick={() => this._close()}>CLOSE</button>
                </div>
            </div>
        )
    }
}

export default withRouterInnerRef(NotificationModal)