import React, { Component } from 'react'
import Web3 from 'web3'
import Input from '../common/Input'
import Textarea from '../common/Textarea'

let web3

class BankAccountForm extends Component {
    constructor(props) {
        super(props)

        this.defaultState = {
            address: '',
            privateKey: '',
            isLoading: false,
        }

        this.state = this.defaultState
    }

    componentDidMount() {
        web3 = new Web3(window.ethereum)

        web3.eth.personal.getAccounts().then(console.log)

        this.setState({ ...this.defaultState })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    _handleOnSubmit = () => {
        const { address, privateKey } = this.state

        this.setState({ isLoading: true })
        try {
            // web3.eth.accounts.wallet.add({ privateKey, address})
            web3.eth.accounts.wallet.create()
            window.alert.success('Successfully added crypto wallet.')
            this.setState({
                address: '',
                privateKey: '',
                isLoading: false
            })
        } catch (e) {
            window.alert.error(e)
            this.setState({
                isLoading: false
            })
        }
    }

    render() {
        const {
            address,
            privateKey,
            isLoading,
        } = this.state

        return (
            <div className="bank-account-form">
                <Input
                    label="Address"
                    type="text"
                    name="address"
                    value={address}
                    className="form-control"
                    placeholder="0x2c7536E3605D9C16a7a3D7b1898e529396a65c23"
                    onChange={this.onChange}
                />
                <Textarea
                    label="Private Key" 
                    name="privateKey"
                    value={privateKey}
                    onChange={this.onChange}
                    placeholder="0x4c0883a69102937d6231471b5dbb6204fe5129617082792ae468d01a3f362318"
                />
                <div className="btn-container d-flex justify-content-end">
                    <button className="btn btn-white" disabled={isLoading}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-blue"
                        onClick={this._handleOnSubmit}
                        disabled={isLoading}
                    >
                        CREATE
                    </button>
                </div>
            </div>
        )
    }
}

export default BankAccountForm
