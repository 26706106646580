import { connect } from 'react-redux'
import Settings from '../../components/Settings'
import { createBankAccount } from "../../actions/bankAccount"
import { changePassword } from "../../actions/users"
import { me } from '../../actions/auth'
import { showFlashMessage } from '../../actions/app'

const mapActionCreators = {
  createBankAccount,
  changePassword,
  me,
  showFlashMessage
}

const mapStateToProps = state => ({
  myself: state.auth.myself,
  bankAccount: state.bankAccount.bank,
  password: state.users.password
})

export default connect(mapStateToProps, mapActionCreators)(Settings)