import React, { Component } from 'react';
import Loading2 from './Loading2';

class Callback extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = async () => {
    const {
      linkTelegram,
      location: { search },
      me,
    } = this.props;

    await linkTelegram(search);

    await me();
  };

  componentWillUpdate(nextProps, nextState) {
    const { history } = this.props;

    if (!nextProps.isLoading && !nextProps.isError) {
      setTimeout(() => history.push('/me/edit'), 3000);
    }
  }

  render() {
    const { isLoading, isError, errorMessage } = this.props;

    return isLoading ? (
      <Loading2 />
    ) : (
      <div className="mt-5">
        {isError ? (
          <p>{errorMessage}</p>
        ) : (
          <p>
            Telegram account successfully linked, you will be redirected shortly.
          </p>
        )}
      </div>
    );
  }
}

export default Callback;
