import React, { Component } from 'react'

class Loading2 extends Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    render() {
        return (
            <div id="loading2">
                <div className="spinner">
                    <div className="dot1"></div>
                    <div className="dot2"></div>
                </div>
            </div>
        )
    }
}

export default Loading2
