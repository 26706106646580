import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import ConfirmDialog from '../../common/ConfirmDialog'
import EditOrder from './EditOrder'
import {FacebookShareButton} from 'react-share'

class Manager extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isShowConfirmDialog: false,
        }

        this._hideArchiveRestoreModal = this._hideArchiveRestoreModal.bind(this)
        this._confirmArchiveOrder = this._confirmArchiveOrder.bind(this)
        this._confirmRestoreOrder = this._confirmRestoreOrder.bind(this)
        this._showConfirmDialog = this._showConfirmDialog.bind(this)
        this._handleEditOrder = this._handleEditOrder.bind(this)
        this._redirectToEdit = this._redirectToEdit.bind(this)
    }

    _hideArchiveRestoreModal() {
        this.setState({ isShowConfirmDialog: false })
    }

    _confirmArchiveOrder() {
        const { ico } = this.props
        window.http.post(`order/${ico.uuid}/archive`)
            .then(({ data: {message} }) => {
                window.alert.success(message);
                this.setState({ isShowConfirmDialog: false })
                setTimeout(() => {
                    window.location.reload(false);
                }, 1000)
            }).catch(({ data }) => {
                window.alert.error(data.error);
                this.setState({ isShowConfirmDialog: false })
            })
    }

    _confirmRestoreOrder() {
        const { ico } = this.props
        window.http.post(`order/${ico.uuid}/restore`)
            .then(({ data: {message} }) => {
                window.alert.success(message);
                this.setState({ isShowConfirmDialog: false })
                setTimeout(() => {
                    window.location.reload(false);
                }, 1000)
            }).catch(({ data }) => {
                window.alert.error(data.error);
                this.setState({ isShowConfirmDialog: false })
            });
    }

    _renderRestoreButton() {
        const { isShowConfirmDialog } = this.state

        return (
            <span>
                <ConfirmDialog cancel={this._hideArchiveRestoreModal} confirm={this._confirmRestoreOrder}
                    isShow={isShowConfirmDialog} message={'Are you sure you want to restore this order?'} />
                <button className="btn mb-3 w-100 btn-block btn-info"
                    onClick={this._showConfirmDialog}>
                    RESTORE
                </button>
            </span>
        )
    }

    _redirectToEdit() {
        const { ico } = this.props
        console.log(ico.uuid);
        this.props.history.push(`/order/edit/${ico.uuid}`)
    }

    _showConfirmDialog() {
        this.setState({ isShowConfirmDialog: true })
    }
    _

    async _handleEditOrder(uuid, data) {
        const { updateAd, fetchIcoDetails, ico } = this.props

        await updateAd(uuid, data)
        await fetchIcoDetails(ico.uuid)
    }

    _renderArchiveButton() {
        const { isShowConfirmDialog } = this.state
        const currentUrl = window.location.href

        return (
            <div>
                <span>
                    <FacebookShareButton 
                        icon={32} 
                        url={currentUrl} 
                        round={"false"}
                        resetButtonStyle = {false}
                        children ='Share on Facebook'
                        className="mb-3 btn-share btn-share--fb"
                        />
                </span>
                <span>
                    <button
                        className="btn mb-3 w-100 btn-block"
                        style={{backgroundColor: '#F5A623'}}
                        onClick={this._redirectToEdit}
                    >
                        EDIT
                    </button>
                </span>
                <span>
                    <button
                        className="btn mb-3 w-100 btn-block"
                        style={{backgroundColor: '#9B9B9B'}}
                        onClick={this._showConfirmDialog}
                    >
                        DISABLE
                    </button>
                </span>
                <ConfirmDialog
                    cancel={this._hideArchiveRestoreModal}
                    confirm={this._confirmArchiveOrder}
                    isShow={isShowConfirmDialog}
                    message={'Are you sure you want to archive this order?'}
                />
                <EditOrder ref="editOrder" handleEditOrder={this._handleEditOrder}/>
            </div>
            
        )
    }

    render() {

        const { orderIsDisabled } = this.props

        return (
            <div className="seller-info text-center col-lg-5">

                <div className="session-block">
                    <div className="row">
                        <div className="col-lg-12">

                        </div>
                        <div className="col-lg-12">

                        </div>
                        <div className="col-lg-12">
                            {orderIsDisabled ? this._renderRestoreButton() : this._renderArchiveButton()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Manager)