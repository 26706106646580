import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class MiniApp extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <main className="main-content main-content--centered">
        <nav className="main-content__nav-links">
          <NavLink className="nav-link btn btn-blue" to="/mini-app/search">
            Search
          </NavLink>
          <NavLink className="nav-link btn btn-blue" to="/mini-app/asks">
            Asks
          </NavLink>
          <NavLink className="nav-link btn btn-blue" to="/mini-app/bids">
            Bids
          </NavLink>
          <NavLink className="nav-link btn btn-blue mt-3" to="/mini-app/post">
            Create a buy/sell order
          </NavLink>
        </nav>
      </main>
    );
  }
}

export default MiniApp;
