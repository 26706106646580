/**
 * Admin Dashboard
 */
 import { connect } from 'react-redux'
import Dashboard from '../../components/Dashboard/Admin/Dashboard'
import { searchAds, updateAd } from '../../actions/ads'
import { getRecentUsers, searchRecentUsers } from '../../actions/users'
import { 
    adminUpdateUser, 
    adminGetUser, 
    adminGetMembersSummary, 
    adminGetUsers,
    adminGetWeeklyUsers,
    adminGetStatistics,
    adminGetOrders
} from '../../actions/admin'

const mapActionCreators = {
    getRecentUsers,
    searchRecentUsers,
    adminUpdateUser,
    adminGetUser,
    adminGetMembersSummary,
    adminGetUsers,
    searchAds,
    adminGetWeeklyUsers,
    adminGetStatistics,
    adminGetOrders,
    updateAd
}

const mapStateToProps = state => ({
    orders: state.admin.orders,
    deals: state.reservations.deals,
    users: state.admin.users,
    total_users: state.reservations.total_users,
    recent_users: state.users.recent_users,
    user: state.admin.user,
    summary: state.admin.summary,
    ads: state.ads.ads,
    weeklyUsers: state.admin.weeklyUsers,
    statistics: state.admin.statistics
})

export default connect(mapStateToProps, mapActionCreators)(Dashboard)
