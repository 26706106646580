import { connect } from 'react-redux';
import Search from '../../components/Search';
import { me } from '../../actions/auth';
import { fetchBuyAds, fetchSellAds, fetchAds } from '../../actions/ads';
import { getTransactionsCount } from '../../actions/transactions';

const mapActionCreators = {
  me,
  getTransactionsCount,
  fetchBuyAds,
  fetchSellAds,
  fetchAds,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  myself: state.auth.myself,
  buyAds: state.ads.buyAds,
  sellAds: state.ads.sellAds,
  allAds: state.ads.allAds,
});

export default connect(mapStateToProps, mapActionCreators)(Search);
