import React from 'react'
import StarRatings from 'react-star-ratings'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BaseModal from '../../../common/BaseModal'
import Textarea from '../../../common/Textarea'

export default class ReviewFormModal extends BaseModal {
    constructor(props) {
        super(props)

        this.state = {
            score: 0,
            message: '',
            isLoading: false,
        }

        this.onSubmit = this.onSubmit.bind(this)
        this.changeRating = this.changeRating.bind(this)
    }

    changeRating(newScore) {
        this.setState({
            score: newScore,
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    async onSubmit() {
        const { ico, transaction, createReview, refreshTransactions, fetchIcoDetails } = this.props
        const { score, message } = this.state

        try {
            const data = {
                score,
                message,
                transaction_id: transaction.id
            }
            
            await createReview(ico.uuid, data)
            await refreshTransactions()
            
            if (fetchIcoDetails) {
                await fetchIcoDetails(ico.uuid)
            }

            this.close()
        } catch (e) {
            console.log(e)
        }
    }

    renderHeader = () => {
        return (
            <div className="row">
                <div className="d-flex flex-col">
                    <FontAwesomeIcon
                        icon="pen"
                        className=""
                        size="lg"
                        color="#e29000"
                    />
                    <h3 className="ml-3">Write a review</h3>
                </div>
            </div>
        )
    }

    renderBody = () => {
        const { message, score } = this.state

        return (
            <div className="col-12 m-auto p-0">
                    <label>Rating</label>
                <div className="mb-4 text-center">
                    <StarRatings
                        rating={score}
                        changeRating={this.changeRating}
                        numberOfStars={5}
                        name="score"
                        starRatedColor="#e29000"
                        starHoverColor="#ffc766"
                    />
                </div>
                <div>
                    <Textarea
                        label="Message"
                        name="message"
                        cols={40}
                        value={message}
                        full
                        onChange={this.onChange}
                        placeholder="Write down your experience on this user"
                    />
                </div>
            </div>
        )
    }

    renderFooter = () => {
        const { isLoading } = this.state
        let isLoadingState = isLoading ? (
            <FontAwesomeIcon icon="spinner" spin className="mr-2" />
        ) : null

        return (
            <div className="row">
                <div className="col-12 text-right buttons">
                    <button
                        className="btn btn-white"
                        onClick={() => this.close()}
                        disabled={isLoading}
                    >
                        CANCEL
                    </button>
                    <button
                        className="btn btn-blue"
                        onClick={this.onSubmit}
                        disabled={isLoading}
                    >
                        {isLoadingState}
                        Submit
                    </button>
                </div>
            </div>
        )
    }
}

ReviewFormModal.propTypes = {
    ico: PropTypes.object,
}