/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class User extends Component {

    constructor(props) {
        super(props)

        this._showDetails = this._showDetails.bind(this)
    }

    _showDetails() {
        const { user: { uuid }, showUserDetails } = this.props
        showUserDetails(uuid)
    }

    render() {
        const {
            user,
            disableOrEnableAccount,
            assignAsEscrower,
            assignAsAppraiser
        } = this.props

        let enableOrDisable,
            assignEscrower,
            showUserBanned = '',
            showUserEscrower = '',
            assignAppraiser = '',
            showUserAppraiser = ''

        if (user.is_banned) {
            enableOrDisable = <a className="dropdown-item" onClick={() => disableOrEnableAccount(user, true)}>Enable</a>
            showUserBanned = <strong className="pb-0 mb-0 text-muted">DISABLED</strong>
        } else {
            enableOrDisable = <a className="dropdown-item" onClick={() => disableOrEnableAccount(user)}>Disable</a>
        }

        if (user.is_escrower) {
            assignEscrower = <a className="dropdown-item" onClick={() => assignAsEscrower(user)}>Remove as escrower</a>
            showUserEscrower = <strong className="pb-0 mb-0 text-primary">ESCROWER</strong>
        } else {
            assignEscrower = <a className="dropdown-item" onClick={() => assignAsEscrower(user, true)}>Assign as escrower</a>
        }

        if (user.is_appraiser) {
            assignAppraiser = <a className="dropdown-item" onClick={() => assignAsAppraiser(user)}>Remove as appraiser</a>
            showUserAppraiser = <strong className="pb-0 mb-0 text-info">APPRAISER</strong>
        } else {
            assignAppraiser = <a className="dropdown-item" onClick={() => assignAsAppraiser(user, true)}>Assign as appraiser</a>
        }

        return (
                <div className="col-xl-3 col-lg-4 col-md-6 col-12 mb-3">
                    <div className={`recent-members-user ${user.is_banned ? 'is-banned' : ''}`}>
                        <div className="row">
                            <div className="col-4 pr-1">
                                <div className="image">
                                    <img
                                        src={(user && user.profile_picture) || "/no-user-image.jpg"}
                                        alt={user && user.first_name}
                                        />
                                </div>
                            </div>
                            <div className="col-7 pl-1">
                                <div className="info">
                                    <h5 className="text-capitalize">
                                        {user && user.full_name}
                                    </h5>
                                    <p className="pb-0 mb-0">
                                        Trust Level: <span>0</span>
                                    </p>
                                    {showUserBanned}<br />
                                    {showUserEscrower}<br />
                                    {showUserAppraiser}
                                </div>
                            </div>
                            <div className="col-1 p-0">
                                <div className="hamburger dropdown">
                                    <FontAwesomeIcon className="dropdown-toggle" data-toggle="dropdown" icon="ellipsis-v" />
                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a className="dropdown-item" onClick={() => this._showDetails()}>Show details</a>
                                        {enableOrDisable}
                                        {assignEscrower}
                                        {assignAppraiser}
                                    </div>
                            </div>
                            </div>
                        </div>

                    </div>
                </div>
        )
    }
}

User.propTypes = {
    user: PropTypes.object.isRequired,
    showUserDetails: PropTypes.func,
}
